import { ColumnsFilters } from '@features';

import { ColumnsOkpd2sHandbookTable } from '../model';

export const OKPD2S_TABLE_COLUMNS_WITH_FILTER: Record<
  ColumnsOkpd2sHandbookTable[number],
  ColumnsFilters
> = {
  Значение: 'search',
};
