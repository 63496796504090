import { ReactNode } from 'react';

import { ContractForm, ContractFormProps } from '@features';

interface ContractLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: ContractFormProps;
}

export function ContractLayout({ renderForm, formProps }: ContractLayoutProps) {
  return <>{renderForm(<ContractForm {...formProps} />)}</>;
}
