/* eslint-disable @typescript-eslint/no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';

import { $api, $mock, bodyRequestType, fetchClient, mockClient } from '@shared/api';

const useGetRelatedCoordinationQuery = (id: string, enabled: boolean) =>
  $api.useQuery(
    'get',
    '/contract/related/{id}',
    { params: { path: { id: id } } },
    { enabled, refetchOnWindowFocus: false }
  );

const useGetCoordinationQuery = (id: string, enabled: boolean) =>
  $api.useQuery(
    'get',
    '/coordination/{id}',
    { params: { path: { id: id } } },
    { enabled, refetchOnWindowFocus: false }
  );

const updateCoordinationMutation = () => $api.useMutation('put', '/coordination/{id}');

const createCoordinationMutation = () => $api.useMutation('post', '/coordination/');

function useGetAllCoordinationsInfiniteQuery(
  body: bodyRequestType<'post', '/coordination/all'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/coordination/all', body],
    queryFn: async ({ pageParam }) => {
      const { data } = await fetchClient.POST('/coordination/all', {
        // const { data } = await mockClient.POST('/coordination/all', {
        // TODO:
        body: { ...body, offset: pageParam },
      });

      return data?.Coordinations || [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + (body.limit || 1);
    },
    enabled,
  });
}

export const $agreementHooks = {
  getAll: useGetAllCoordinationsInfiniteQuery,
  getById: useGetCoordinationQuery,
  update: updateCoordinationMutation,
  create: createCoordinationMutation,
  related: useGetRelatedCoordinationQuery,
};
