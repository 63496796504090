import { Xmark } from '@gravity-ui/icons';
import { Drawer, DrawerItem } from '@gravity-ui/navigation';
import { Button, Icon, Text, TextProps } from '@gravity-ui/uikit';
import { FC, ReactNode } from 'react';

import { cn } from '@shared/lib';

interface RightSidebarHeaderProps {
  title?: string;
  titleProps?: TextProps;
  className?: string;
  onClose: () => void;
}

function RightSidebarHeader({ title, titleProps, className, onClose }: RightSidebarHeaderProps) {
  return (
    <div
      className={cn('flex items-start justify-between gap-4 p-8 z-50', className)}
      style={{ backgroundColor: 'inherit' }}
    >
      <Text
        variant="header-1"
        color="primary"
        {...titleProps}
      >
        {title}
      </Text>
      <Button
        size="l"
        view="flat"
        onClick={onClose}
      >
        <Icon
          data={Xmark}
          className="w-6 h-6 text-text-misc"
        />
      </Button>
    </div>
  );
}

interface RightSidebarBottomPanelProps {
  className?: string;
  withoutShadow?: boolean;
  bottomLock?: boolean;
  children?: ReactNode;
}

function RightSidebarBottomPanel({
  withoutShadow,
  bottomLock,
  className,
  children,
}: RightSidebarBottomPanelProps) {
  return (
    <div
      className={cn('bg-base-background flex gap-2 border-t border-line-generic p-2', className, {
        'shadow-select': !withoutShadow,
        'mt-auto': bottomLock,
      })}
    >
      {children}
    </div>
  );
}

interface RightSidebarProps {
  open: boolean;
  id?: string;
  className?: string;
  onClose: () => void;
  children?: ReactNode;
}

type RightSidebarCompound = FC<RightSidebarProps> & {
  Header: typeof RightSidebarHeader;
  BottomPanel: typeof RightSidebarBottomPanel;
};

export const RightSidebar = (({ id, open, className, onClose, children }) => {
  return (
    <Drawer
      onEscape={onClose}
      onVeilClick={onClose}
    >
      <DrawerItem
        id={id ?? 'right-sidebar'}
        visible={open}
        direction="right"
        className={cn('w-[612px] shadow-modal flex flex-col !z-[100]', className)}
      >
        {children}
      </DrawerItem>
    </Drawer>
  );
}) as RightSidebarCompound;

RightSidebar.Header = RightSidebarHeader;
RightSidebar.BottomPanel = RightSidebarBottomPanel;
