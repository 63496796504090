import './index.sass';

import { DelayedTextInput } from '@gravity-ui/components';
import { DatePicker, DatePickerProps } from '@gravity-ui/date-components';
import { dateTime } from '@gravity-ui/date-utils';
import { ArrowDownToSquare, CircleCheckFill, Plus, TriangleExclamation } from '@gravity-ui/icons';
import {
  Box,
  Button,
  ButtonProps,
  Icon,
  Label,
  LabelProps,
  Loader,
  Overlay,
  Popover,
  Popup,
  Progress,
  ProgressProps,
  RadioButton,
  RadioButtonProps,
  RadioGroupOption,
  Select,
  SelectOption,
  SelectProps,
  Slider,
  SliderProps,
  Text,
  TextArea,
  TextAreaProps,
  TextInput,
  TextInputProps,
  Tooltip,
  useToaster,
} from '@gravity-ui/uikit';
import { nanoid } from 'nanoid';
import {
  FC,
  ForwardedRef,
  forwardRef,
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Control, Controller, ControllerFieldState, FieldValues, Path } from 'react-hook-form';
import {
  NumberFormatBaseProps,
  NumericFormat,
  PatternFormat,
  PatternFormatProps,
} from 'react-number-format';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { downloadFileFromServer, uploadFileToS3 } from '@shared/api';
import { DATE_FORMATS } from '@shared/consts';
import { cn, formatBytes, InnerPath, objectKeysSafeToArray, pluralize } from '@shared/lib';

import { CustomSelectOption, CustomSelectPopup } from '../renders';

interface FieldWithPreviewWrapperProps {
  show?: boolean;
  name: string;
  required?: boolean;
  textAlign?: 'start' | 'center' | 'end';
  direction?: 'row' | 'column';
  className?: string;
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  children?: ReactNode;
}

const FieldWithPreviewWrapper = forwardRef<HTMLDivElement, FieldWithPreviewWrapperProps>(
  (
    {
      show = true,
      textAlign = 'center',
      direction = 'row',
      name,
      required = false,
      className,
      onClick,
      onFocus,
      onBlur,
      children,
    },
    ref
  ) => {
    if (!show) return null;

    return (
      <div
        className={cn('flex gap-2', {
          'flex-col': direction === 'column',
          'flex-row': direction === 'row',
        })}
      >
        <Text
          variant="body-1"
          color="secondary"
          title={name}
          className={cn('break-words shrink-0', {
            'w-[156px]': direction === 'row',
            'self-start': textAlign === 'start',
            'self-center': textAlign === 'center',
            'self-end': textAlign === 'end',
          })}
        >
          {name}
          {required && (
            <Text
              variant="caption-2"
              color="danger"
              className="ml-0.5"
            >
              *
            </Text>
          )}
        </Text>
        <div
          ref={ref}
          className={cn('grow max-w-[320px]', className)}
          role="presentation"
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
        >
          {children}
        </div>
      </div>
    );
  }
);

const AddButton = forwardRef<HTMLElement, ButtonProps>(({ onClick, ...otherProps }, ref) => (
  <Button
    ref={ref}
    size="m"
    view="normal"
    onClick={e => {
      if (onClick) onClick(e);
    }}
    {...otherProps}
  >
    <Icon data={Plus} />
    Добавить
  </Button>
));

const PreviewLabel = forwardRef<HTMLDivElement, LabelProps>(({ className, ...props }, ref) => (
  <Label
    ref={ref}
    size="m"
    theme="clear"
    type="close"
    className={cn('create-process-label overflow-hidden', className)}
    {...props}
  />
));

interface FieldWithPreviewDateProps<TFieldValues extends FieldValues = FieldValues> {
  edit: ((fieldState: ControllerFieldState) => boolean) | boolean;
  show?: boolean;
  required?: boolean;
  name: string;
  path: Path<TFieldValues>;
  control: Control<TFieldValues>;
  onFieldSelect?: () => void;
  format?: string;
  formatPreview?: string;
  datePickerProps?: Pick<
    DatePickerProps,
    'placeholder' | 'onUpdate' | 'disabled' | 'minValue' | 'maxValue'
  >;
}

const FieldWithPreviewDate = forwardRef(
  <TFieldValues extends FieldValues = FieldValues>(
    {
      edit,
      show = true,
      format,
      formatPreview = DATE_FORMATS.fullDate,
      onFieldSelect,
      path,
      name,
      control,
      required,
      datePickerProps: { onUpdate, ...datePickerProps } = {},
    }: FieldWithPreviewDateProps<TFieldValues>,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    const [open, setOpen] = useState(false);

    return (
      <Controller
        name={path}
        control={control}
        render={({
          field: { ref, value, onChange, ...field },
          fieldState: { invalid, error, isTouched, isValidating },
        }) => {
          const isEditable =
            typeof edit === 'function'
              ? edit({ invalid, error, isDirty: !!value, isTouched, isValidating })
              : edit;

          const dateValue = typeof value === 'string' ? dateTime({ input: value }) : null;

          return (
            <>
              {show ? (
                <FieldWithPreviewWrapper
                  ref={forwardedRef}
                  name={name}
                  required={required}
                  onClick={onFieldSelect}
                  onFocus={onFieldSelect}
                >
                  {!isEditable ? (
                    <div className="flex gap-1 items-center">
                      <Text
                        variant="body-2"
                        color="primary"
                      >
                        {dateValue?.format?.(formatPreview) || '—'}
                      </Text>
                      <Text
                        variant="body-1"
                        color="secondary"
                      >
                        {dateValue?.fromNow()}
                      </Text>
                    </div>
                  ) : (
                    <div ref={ref}>
                      <DatePicker
                        {...datePickerProps}
                        {...field}
                        format={format}
                        size="l"
                        view="normal"
                        className={cn('w-full', {
                          'date-picker-with-preview': open,
                        })}
                        open={open}
                        value={dateValue}
                        // timeZone="GMT"
                        onUpdate={value => {
                          onChange(value?.toISOString() ?? null);
                          onUpdate?.(value);
                        }}
                        onOpenChange={setOpen}
                        hasClear
                        disablePortal
                        errorPlacement="inside"
                        errorMessage={error?.message}
                        validationState={invalid ? 'invalid' : undefined}
                      />
                    </div>
                  )}
                </FieldWithPreviewWrapper>
              ) : null}
            </>
          );
        }}
      />
    );
  }
);

interface FieldWithPreviewMultiDateProps<TFieldValues extends FieldValues = FieldValues> {
  edit: ((fieldState: ControllerFieldState) => boolean) | boolean;
  show?: boolean;
  required?: boolean;
  name: string;
  path: Path<TFieldValues>;
  control: Control<TFieldValues>;
  onFieldSelect?: () => void;
  fields: Record<
    InnerPath<Path<TFieldValues>>,
    Pick<FieldWithPreviewDateProps<TFieldValues>, 'format' | 'formatPreview' | 'datePickerProps'>
  >;
  wrapperProps?: Pick<FieldWithPreviewWrapperProps, 'textAlign'>;
}

const FieldWithPreviewMultiDate = forwardRef(
  <TFieldValues extends FieldValues = FieldValues>(
    {
      control,
      edit,
      show = true,
      name,
      path,
      required,
      fields,
      onFieldSelect,
      wrapperProps,
    }: FieldWithPreviewMultiDateProps<TFieldValues>,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    const fieldState = control.getFieldState(path);

    const [hasValueFromController, setHasValueFromController] = useState(false);

    const isEditable =
      typeof edit === 'function' ? edit({ ...fieldState, isDirty: hasValueFromController }) : edit;

    const defaultOpenFields = useMemo(
      () =>
        objectKeysSafeToArray(fields).reduce<Record<InnerPath<Path<TFieldValues>>, boolean>>(
          (obj, fieldPath) => {
            obj[fieldPath] = false;
            return obj;
          },
          {} as Record<InnerPath<Path<TFieldValues>>, boolean>
        ),
      []
    );

    const [openFields, setOpenFields] = useState(defaultOpenFields);

    useEffect(() => {
      if (!isEditable) {
        setOpenFields(defaultOpenFields);
      }
    }, [isEditable]);

    return (
      <div className="flex flex-col gap-2">
        <Controller
          name={path}
          control={control}
          render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
            <>
              {show ? (
                <FieldWithPreviewWrapper
                  {...wrapperProps}
                  name={name}
                  ref={forwardedRef}
                  required={required}
                  onClick={onFieldSelect}
                  onFocus={onFieldSelect}
                >
                  {objectKeysSafeToArray(fields).map(fieldPath => {
                    const fieldItem = fields[fieldPath];

                    const fieldValue =
                      typeof value[fieldPath] === 'string'
                        ? dateTime({ input: value[fieldPath] })
                        : null;

                    useEffect(() => {
                      if (fieldValue === null) setHasValueFromController(false);
                      else setHasValueFromController(true);
                    }, [fieldValue]);

                    return (
                      <Fragment key={fieldPath}>
                        {!isEditable ? (
                          <div className="flex gap-1 items-center">
                            <Text
                              variant="body-2"
                              color="primary"
                            >
                              {fieldValue?.format(fieldItem.formatPreview)}
                            </Text>
                            <Text
                              variant="body-1"
                              color="secondary"
                            >
                              {fieldValue?.fromNow()}
                            </Text>
                          </div>
                        ) : (
                          <DatePicker
                            {...fieldItem.datePickerProps}
                            size="l"
                            view="normal"
                            timeZone="GMT"
                            format={fieldItem.format}
                            className={cn('w-full', {
                              'date-picker-with-preview': openFields[fieldPath],
                            })}
                            open={openFields[fieldPath] ?? false}
                            value={fieldValue}
                            onUpdate={dateValue => {
                              onChange({ ...value, [fieldPath]: dateValue?.toISOString() ?? null });
                              fieldItem.datePickerProps?.onUpdate?.(dateValue);
                            }}
                            onOpenChange={open =>
                              setOpenFields({ ...openFields, [fieldPath]: open })
                            }
                            hasClear
                            disablePortal
                            errorPlacement="inside"
                            errorMessage={error?.message}
                            validationState={invalid ? 'invalid' : undefined}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </FieldWithPreviewWrapper>
              ) : null}
            </>
          )}
        />
      </div>
    );
  }
);

interface FieldWithPreviewTextProps<TFieldValues extends FieldValues = FieldValues> {
  control: Control<TFieldValues>;
  show?: boolean;
  path: Path<TFieldValues>;
  edit: ((fieldState: ControllerFieldState) => boolean) | boolean;
  required?: boolean;
  onFieldSelect?: () => void;
  name: string;
  textInputProps?: Pick<TextInputProps, 'placeholder' | 'disabled' | 'type' | 'endContent'>;
  delayTextInput?: number;
}

const FieldWithPreviewText = forwardRef(
  <TFieldValues extends FieldValues = FieldValues>(
    {
      edit,
      show = true,
      control,
      name,
      path,
      required,
      onFieldSelect,
      textInputProps,
      delayTextInput,
    }: FieldWithPreviewTextProps<TFieldValues>,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => (
    <Controller
      name={path}
      control={control}
      render={({
        field: { ref, value, onChange, ...field },
        fieldState: { error, invalid, isTouched, isValidating },
      }) => {
        const isEditable =
          typeof edit === 'function'
            ? edit({ error, invalid, isDirty: !!value, isTouched, isValidating })
            : edit;

        return (
          <>
            {show ? (
              <FieldWithPreviewWrapper
                ref={forwardedRef}
                name={name}
                required={required}
                onClick={onFieldSelect}
                onFocus={onFieldSelect}
              >
                {!isEditable ? (
                  <Text
                    variant="body-2"
                    color="primary"
                    title={value}
                    className="w-full inline-block h-full truncate"
                  >
                    {value || '—'}
                  </Text>
                ) : (
                  <DelayedTextInput
                    {...textInputProps}
                    placeholder={textInputProps?.placeholder || 'Ввести'}
                    {...field}
                    delay={delayTextInput || 0}
                    ref={ref}
                    size="l"
                    view="normal"
                    value={value ?? ''}
                    onUpdate={onChange}
                    className="create-process-text-input"
                    errorPlacement="inside"
                    errorMessage={error?.message}
                    validationState={invalid ? 'invalid' : undefined}
                  />
                )}
              </FieldWithPreviewWrapper>
            ) : null}
          </>
        );
      }}
    />
  )
);

interface FieldWithPreviewTextAreaProps<TFieldValues extends FieldValues = FieldValues> {
  edit: ((fieldState: ControllerFieldState) => boolean) | boolean;
  show?: boolean;
  required?: boolean;
  name: string;
  path: Path<TFieldValues>;
  control: Control<TFieldValues>;
  onFieldSelect?: () => void;
  textAreaProps?: Pick<TextAreaProps, 'disabled' | 'placeholder'>;
  wrapperProps?: Pick<FieldWithPreviewWrapperProps, 'textAlign'>;
}

const FieldWithPreviewTextArea = forwardRef(
  <TFieldValues extends FieldValues = FieldValues>(
    {
      control,
      show = true,
      edit,
      name,
      path,
      required,
      onFieldSelect,
      textAreaProps,
      wrapperProps,
    }: FieldWithPreviewTextAreaProps<TFieldValues>,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Controller
        name={path}
        control={control}
        render={({
          field: { value, ...field },
          fieldState: { invalid, error, isTouched, isValidating },
        }) => {
          const isEditable =
            typeof edit === 'function'
              ? edit({ error, invalid, isDirty: !!value, isTouched, isValidating })
              : edit;

          return (
            <>
              {show ? (
                <FieldWithPreviewWrapper
                  {...wrapperProps}
                  ref={forwardedRef}
                  name={name}
                  required={required}
                  onClick={onFieldSelect}
                  onFocus={onFieldSelect}
                >
                  {!isEditable ? (
                    <Text
                      variant="body-2"
                      color="primary"
                      title={value}
                      className="w-full inline-block h-full break-words whitespace-pre-line"
                    >
                      {value || '—'}
                    </Text>
                  ) : (
                    <TextArea
                      {...textAreaProps}
                      placeholder={textAreaProps?.placeholder || 'Ввести'}
                      {...field}
                      size="l"
                      view="normal"
                      className="h-[124px]"
                      value={value ?? ''}
                      errorPlacement="inside"
                      validationState={invalid ? 'invalid' : undefined}
                      errorMessage={error?.message}
                    />
                  )}
                </FieldWithPreviewWrapper>
              ) : null}
            </>
          );
        }}
      />
    );
  }
);

interface FieldWithPreviewCurrencyProps<TFieldValues extends FieldValues = FieldValues> {
  edit: ((fieldState: ControllerFieldState) => boolean) | boolean;
  show?: boolean;
  required?: boolean;
  name: string;
  path: Path<TFieldValues>;
  control: Control<TFieldValues>;
  onFieldSelect?: () => void;
  numberFormatProps?: Pick<NumberFormatBaseProps, 'disabled' | 'onValueChange' | 'max' | 'min'>;
}

const FieldWithPreviewCurrency = forwardRef(
  <TFieldValues extends FieldValues = FieldValues>(
    {
      control,
      edit,
      show = true,
      name,
      path,
      required,
      onFieldSelect,
      numberFormatProps: { onValueChange, min, max, ...numberFormatProps } = {},
    }: FieldWithPreviewCurrencyProps<TFieldValues>,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => (
    <Controller
      name={path}
      control={control}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { invalid, error, isDirty, isTouched, isValidating },
      }) => {
        const isEditable =
          typeof edit === 'function'
            ? edit({
                error,
                invalid,
                isDirty: typeof value === 'number' || isDirty,
                isTouched,
                isValidating,
              })
            : edit;

        return (
          <>
            {show ? (
              <FieldWithPreviewWrapper
                ref={forwardedRef}
                name={name}
                required={required}
                onClick={onFieldSelect}
                onFocus={onFieldSelect}
              >
                {!isEditable ? (
                  <NumericFormat
                    {...numberFormatProps}
                    value={value || ''}
                    min={min ?? Number.MIN_SAFE_INTEGER}
                    max={max ?? Number.MAX_SAFE_INTEGER}
                    decimalScale={2}
                    name={path}
                    displayType="text"
                    thousandSeparator=" "
                    suffix=" ₽"
                    renderText={formattedValue => (
                      <Text
                        variant="body-2"
                        color="primary"
                        title={formattedValue}
                        className="w-full inline-block h-full break-words"
                      >
                        {formattedValue || '—'}
                      </Text>
                    )}
                  />
                ) : (
                  <NumericFormat
                    {...numberFormatProps}
                    min={min ?? Number.MIN_SAFE_INTEGER}
                    max={max ?? Number.MAX_SAFE_INTEGER}
                    placeholder={'Ввести'}
                    name={path}
                    onBlur={onBlur}
                    customInput={TextInput}
                    decimalScale={2}
                    value={value || ''}
                    thousandSeparator=" "
                    size="l"
                    view="normal"
                    className="w-[151px]"
                    errorPlacement="inside"
                    errorMessage={error?.message}
                    validationState={invalid ? 'invalid' : undefined}
                    onValueChange={(values, sourceInfo) => {
                      onChange(values.floatValue);
                      onValueChange?.(values, sourceInfo);
                    }}
                    endContent={
                      <Text
                        variant="caption-2"
                        color="secondary"
                        className="px-2"
                      >
                        ₽
                      </Text>
                    }
                  />
                )}
              </FieldWithPreviewWrapper>
            ) : null}
          </>
        );
      }}
    />
  )
);

interface FieldWithPreviewPhoneProps<TFieldValues extends FieldValues = FieldValues> {
  edit: ((fieldState: ControllerFieldState) => boolean) | boolean;
  show?: boolean;
  required?: boolean;
  name: string;
  path: Path<TFieldValues>;
  control: Control<TFieldValues>;
  onFieldSelect?: () => void;
  patternFormatProps?: Pick<PatternFormatProps, 'disabled' | 'onValueChange'>;
}

const FieldWithPreviewPhone = forwardRef(
  <TFieldValues extends FieldValues = FieldValues>(
    {
      control,
      edit,
      show = true,
      name,
      path,
      required,
      onFieldSelect,
      patternFormatProps: { onValueChange, ...patternFormatProps } = {},
    }: FieldWithPreviewPhoneProps<TFieldValues>,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => (
    <Controller
      name={path}
      control={control}
      render={({
        field: { value, onChange, onBlur, disabled, name: fieldName },
        fieldState: { invalid, error, isTouched, isValidating },
      }) => {
        const isEditable =
          typeof edit === 'function'
            ? edit({ error, invalid, isDirty: !!value, isTouched, isValidating })
            : edit;

        return (
          <>
            {show ? (
              <FieldWithPreviewWrapper
                ref={forwardedRef}
                name={name}
                required={required}
                onClick={onFieldSelect}
                onFocus={onFieldSelect}
              >
                {!isEditable ? (
                  <PatternFormat
                    value={value}
                    format="# (###) ###-##-##"
                    displayType="text"
                    renderText={formattedValue => {
                      return (
                        <Text
                          variant="body-2"
                          color="primary"
                          title={formattedValue}
                          className="w-full inline-block h-full break-words"
                        >
                          {value ? formattedValue : '—'}
                        </Text>
                      );
                    }}
                  />
                ) : (
                  <PatternFormat
                    mask="_"
                    allowEmptyFormatting
                    {...patternFormatProps}
                    onBlur={onBlur}
                    disabled={disabled}
                    name={fieldName}
                    onValueChange={(values, sourceInfo) => {
                      onChange(values.value);

                      onValueChange?.(values, sourceInfo);
                    }}
                    customInput={TextInput}
                    format="# (###) ###-##-##"
                    value={value}
                    size="l"
                    view="normal"
                    className="w-[151px] text-input-with-preview"
                    errorPlacement="inside"
                    validationState={invalid ? 'invalid' : undefined}
                    errorMessage={error?.message}
                  />
                )}
              </FieldWithPreviewWrapper>
            ) : null}
          </>
        );
      }}
    />
  )
);

interface FieldWithPreviewPercentageProps<TFieldValues extends FieldValues = FieldValues> {
  edit: ((fieldState: ControllerFieldState) => boolean) | boolean;
  show?: boolean;
  required?: boolean;
  name: string;
  path: Path<TFieldValues>;
  control: Control<TFieldValues>;
  onFieldSelect?: () => void;
  numberFormatProps?: Pick<NumberFormatBaseProps, 'disabled' | 'onValueChange' | 'max' | 'min'>;
}

const FieldWithPreviewPercentage = forwardRef(
  <TFieldValues extends FieldValues = FieldValues>(
    {
      control,
      show = true,
      edit,
      name,
      path,
      required,
      onFieldSelect,
      numberFormatProps: { onValueChange, min, max, ...numberFormatProps } = {},
    }: FieldWithPreviewPercentageProps<TFieldValues>,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Controller
        name={path}
        control={control}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { invalid, error, isDirty, isTouched, isValidating },
        }) => {
          const isEditable =
            typeof edit === 'function'
              ? edit({
                  error,
                  invalid,
                  isDirty: typeof value === 'number' || isDirty,
                  isTouched,
                  isValidating,
                })
              : edit;

          return (
            <>
              {show ? (
                <FieldWithPreviewWrapper
                  ref={forwardedRef}
                  name={name}
                  required={required}
                  onClick={onFieldSelect}
                  onFocus={onFieldSelect}
                >
                  {!isEditable ? (
                    <NumericFormat
                      {...numberFormatProps}
                      value={typeof value === 'number' ? value : ''}
                      displayType="text"
                      decimalScale={2}
                      min={min ?? Number.MIN_SAFE_INTEGER}
                      max={max ?? Number.MAX_SAFE_INTEGER}
                      suffix="%"
                      renderText={formattedValue => (
                        <Text
                          variant="body-2"
                          color="primary"
                          title={formattedValue}
                          className="w-full inline-block h-full break-words"
                        >
                          {formattedValue || '—'}
                        </Text>
                      )}
                    />
                  ) : (
                    <NumericFormat
                      {...numberFormatProps}
                      min={min ?? Number.MIN_SAFE_INTEGER}
                      max={max ?? Number.MAX_SAFE_INTEGER}
                      placeholder="Ввести"
                      name={path}
                      onBlur={onBlur}
                      customInput={TextInput}
                      decimalScale={2}
                      value={value || ''}
                      size="l"
                      view="normal"
                      className="w-[151px]"
                      // isAllowed={({ formattedValue, floatValue }) =>
                      //   formattedValue === '' ||
                      //   (!!floatValue && floatValue > 0 && floatValue < 100)
                      // }
                      errorPlacement="inside"
                      errorMessage={error?.message}
                      validationState={invalid ? 'invalid' : undefined}
                      onValueChange={(values, sourceInfo) => {
                        onChange(values.floatValue);
                        onValueChange?.(values, sourceInfo);
                      }}
                      endContent={
                        <Text
                          variant="caption-2"
                          color="secondary"
                          className="px-2"
                        >
                          %
                        </Text>
                      }
                    />
                  )}
                </FieldWithPreviewWrapper>
              ) : null}
            </>
          );
        }}
      />
    );
  }
);

interface FieldWithPreviewFileProps<TFieldValues extends FieldValues = FieldValues> {
  edit: ((fieldState: ControllerFieldState) => boolean) | boolean;
  show?: boolean;
  required?: boolean;
  name: string;
  path: Path<TFieldValues>;
  control: Control<TFieldValues>;
  onFieldSelect?: () => void;
  notAllowedExtension?: string[];
  acceptExtensions?: string;
  maxSize?: number;
  disabled?: boolean;
  onLoadingChange?: (loading: boolean) => void;
}

const FieldWithPreviewFile = forwardRef(
  <TFieldValues extends FieldValues = FieldValues>(
    {
      control,
      edit,
      show = true,
      name,
      path,
      required,
      onFieldSelect,
      maxSize,
      notAllowedExtension,
      acceptExtensions,
      disabled = false,
      onLoadingChange,
    }: FieldWithPreviewFileProps<TFieldValues>,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [fileName, setFileName] = useState<string | undefined>(undefined);

    const [isLoading, setIsLoading] = useState(false);
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const dropZoneRef = useRef<HTMLDivElement>(null);

    const { add } = useToaster();

    const handleChangeLoading = (loading: boolean) => {
      onLoadingChange?.(loading);
      setIsLoading(loading);
    };

    const handleFileAdd = (files: File[]) => {
      const firstAllowedFile = files.find(file => {
        const naming = file.name.split('.');
        if (naming.length < 2) {
          return false;
        }
        const extension = naming.pop();
        const allowed = !extension || !notAllowedExtension?.includes(extension);
        return allowed;
      });

      if (!firstAllowedFile) {
        add({
          name: 'not-allowed-files',
          title: 'Файл не был добавлен, т.к. имеет недопустимое расширение',
          theme: 'danger',
        });
        return null;
      }

      if (typeof maxSize === 'number' && firstAllowedFile.size > maxSize) {
        add({
          name: 'max-file-size',
          title: `Не более ${formatBytes(maxSize)} для всех файлов`,
          theme: 'danger',
          // className: 'w-[500px]',
          // autoHiding: false,
        });
        return null;
      }

      return firstAllowedFile;
    };

    return (
      <Controller
        name={path}
        control={control}
        render={({ field, fieldState: { invalid, error, isTouched, isValidating } }) => {
          const value = field.value as string | undefined;

          const onChange = field.onChange;
          const isEditable =
            typeof edit === 'function'
              ? edit({ error, invalid, isDirty: !!value, isTouched, isValidating })
              : edit;
          const [fileLink, setFileLink] = useState(value);
          async function uploadFile(files: File[]) {
            handleChangeLoading(true);
            const newFile = handleFileAdd(files);
            if (!newFile) return;
            setFileName(newFile.name);
            // onChange(newFile);
            const uploadingFile = await uploadFileToS3(newFile);
            uploadingFile.fetch
              .then(() => {
                onChange(uploadingFile.key);
              })
              .catch(() => {
                add({
                  content: 'Ошибка загрузки файла',
                  name: 'upload to s3 error',
                  theme: 'danger',
                });
                onChange(undefined);
                setFileName(undefined);
                if (fileInputRef.current && fileInputRef.current.files)
                  fileInputRef.current.files = null;
              })
              .finally(() => handleChangeLoading(false));
          }

          function valueToKey() {
            if (value) {
              try {
                const key = value.split('https://stage.api.b2gcrm.ru/').pop();
                const fileName = key?.split('/').pop();
                if (!fileName || !key) {
                  setFileName(value);
                  return;
                }
                setFileLink('https://stage.api.b2gcrm.ru/' + key);
                setFileName(fileName);
                onChange(key);
                return key;
              } catch {
                return;
              }
            }
            setFileLink(undefined);
            setFileName(undefined);
            onChange(undefined);
            return;
          }
          useEffect(() => {
            valueToKey();
          }, [value]);

          return (
            <>
              {show ? (
                <FieldWithPreviewWrapper
                  ref={forwardedRef}
                  name={name}
                  required={required}
                >
                  {!isEditable ? (
                    <div className="flex flex-col gap-2 items-start h-full">
                      {value ? (
                        <>
                          <Box position="relative">
                            <Tooltip
                              content="Скачать"
                              openDelay={200}
                            >
                              <button
                                type="button"
                                // href={fileLink}
                                className="text-text-brand flex items-center gap-2"
                                // download={(fileName && decodeURI(fileName)) || value}
                                onClick={() =>
                                  fileLink && fileName
                                    ? downloadFileFromServer(fileLink, fileName).catch(() =>
                                        add({
                                          title: 'Ошибка скачивания файла',
                                          name: 'file-download-catch-error',
                                          theme: 'danger',
                                        })
                                      )
                                    : add({
                                        title: 'Невозможно скачать файл',
                                        name: 'file-download-button-error',
                                        theme: 'danger',
                                      })
                                }
                              >
                                {fileLink && (
                                  <Icon
                                    data={ArrowDownToSquare}
                                    className="shrink-0"
                                  />
                                )}
                                <Text variant="body-2">
                                  {(fileName && decodeURI(fileName)) || value || ''}
                                </Text>
                              </button>
                            </Tooltip>
                            <Overlay visible={isLoading}>
                              <Loader />
                            </Overlay>
                          </Box>
                          <Button
                            size="m"
                            onClick={onFieldSelect}
                            disabled={disabled}
                          >
                            Изменить
                          </Button>
                        </>
                      ) : (
                        <Text
                          variant="body-2"
                          color="primary"
                          onClick={onFieldSelect}
                        >
                          —
                        </Text>
                      )}
                    </div>
                  ) : (
                    <Box
                      ref={dropZoneRef}
                      position="relative"
                      onDragEnter={event => {
                        event.preventDefault();
                        if (disabled) return;
                        setIsDraggingOver(true);
                      }}
                      onDragLeave={event => {
                        event.preventDefault();
                        if (!dropZoneRef.current?.contains(event.relatedTarget as Node)) {
                          setIsDraggingOver(false);
                        }
                      }}
                      onDragOver={event => event.preventDefault()}
                      onDrop={event => {
                        setIsDraggingOver(false);
                        event.preventDefault();
                        if (disabled) return;
                        const files = [...(event.dataTransfer.files ?? [])];
                        uploadFile(files);
                      }}
                    >
                      <div className="flex flex-col gap-4 items-start">
                        {value && (
                          <div className="flex flex-wrap gap-2 w-full">
                            <Box position="relative">
                              <PreviewLabel
                                onCloseClick={() => {
                                  onChange(undefined);
                                  setFileName(undefined);
                                }}
                              >
                                {(fileName && decodeURI(fileName)) || value}
                              </PreviewLabel>
                              <Overlay visible={isLoading}>
                                <Loader />
                              </Overlay>
                            </Box>
                          </div>
                        )}
                        {invalid && (
                          <Text
                            variant="body-1"
                            color="danger"
                            className="break-words w-full"
                          >
                            {error?.message}
                          </Text>
                        )}
                        {!value && (
                          <>
                            <input
                              ref={fileInputRef}
                              type="file"
                              className="hidden"
                              accept={acceptExtensions || '*'}
                              onChange={event => {
                                const files = [...(event.target.files ?? [])];
                                uploadFile(files);
                              }}
                            />
                            <AddButton
                              disabled={disabled}
                              onClick={() => fileInputRef.current?.click()}
                            />
                          </>
                        )}
                      </div>
                      <Overlay
                        visible={isDraggingOver}
                        background="float"
                      >
                        Перетяните файл
                      </Overlay>
                    </Box>
                  )}
                </FieldWithPreviewWrapper>
              ) : null}
            </>
          );
        }}
      />
    );
  }
);

export interface FieldWithPreviewMultiFileItem {
  id: string;
  file: File;
  loading: boolean;
  key?: string;
}

interface FieldWithPreviewMultiFileProps<TFieldValues extends FieldValues = FieldValues> {
  edit: ((fieldState: ControllerFieldState) => boolean) | boolean;
  show?: boolean;
  required?: boolean;
  name: string;
  path: Path<TFieldValues>;
  control: Control<TFieldValues>;
  onFieldSelect?: () => void;
  maxSize?: number;
  notAllowedExtension?: string[];
  acceptExtensions?: string;
  onLoadingChange?: (loading: boolean) => void;
  disabled?: boolean;
}

const FieldWithPreviewMultiFile = forwardRef(
  <TFieldValues extends FieldValues = FieldValues>(
    {
      control,
      edit,
      show = true,
      name,
      path,
      required,
      onFieldSelect,
      maxSize,
      notAllowedExtension,
      acceptExtensions,
      disabled = false,
      onLoadingChange,
    }: FieldWithPreviewMultiFileProps<TFieldValues>,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    const [fileItems, setFileItems] = useState<FieldWithPreviewMultiFileItem[]>([]);
    const isFileItemsLoading = fileItems.some(fileItem => fileItem.loading);

    const { add } = useToaster();

    const fileInputRef = useRef<HTMLInputElement>(null);

    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const dropZoneRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      onLoadingChange?.(isFileItemsLoading);
    }, [isFileItemsLoading]);

    const handleAddFiles = (files: FieldWithPreviewMultiFileItem[]) => {
      let notAllowedCounter = 0;
      let totalSizeOfNewFiles = 0;

      const allowedFiles = files.filter(file => {
        const naming = file.file.name.split('.');
        if (naming.length < 2) {
          notAllowedCounter++;
          return false;
        }
        const extension = naming.pop();
        const allowed = !extension || !notAllowedExtension?.includes(extension);
        if (!allowed) notAllowedCounter++;

        totalSizeOfNewFiles += file.file.size;

        return allowed;
      });

      const totalSize =
        fileItems.reduce((totalSize, fileItem) => totalSize + fileItem.file.size, 0) +
        totalSizeOfNewFiles;

      if (maxSize && totalSize > maxSize) {
        add({
          name: 'max-files-size',
          theme: 'danger',
          title: `Файлы не были добавлены, т.к. их общий вес превышает ${formatBytes(maxSize)}`,
        });

        return [];
      }

      if (notAllowedCounter) {
        add({
          name: 'not-allowed-files',
          title: `${notAllowedCounter} ${pluralize(notAllowedCounter, 'файл не был добавлен, т.к. имеет недопустимое расширение', 'файла не было добавлено, т.к. имеют недопустимые расширения', 'файлов не было добавлено, т.к. имеют недопустимые расширения')}`,
          theme: 'danger',
        });
      }
      return allowedFiles;
    };

    return (
      <Controller
        name={path}
        control={control}
        render={({ field, fieldState: { invalid, error, isTouched, isValidating } }) => {
          const value = field.value as string[] | undefined;
          const onChange = field.onChange;
          const isEditable =
            typeof edit === 'function'
              ? edit({ error, invalid, isDirty: !!value?.length, isTouched, isValidating })
              : edit;

          function valueToFileItems(): void {
            if (value && typeof value === 'object') {
              try {
                const newValue: FieldWithPreviewMultiFileItem[] = [];
                for (const i in value) {
                  let fileKey: string | undefined = undefined;
                  let fileName: string | undefined = undefined;

                  if (value[i]?.includes('https://zakupki.gov.ru/')) {
                    fileName = value[i].split('/').pop()?.split('?uid')[0];
                  } else {
                    fileKey = value[i]?.split('https://stage.api.b2gcrm.ru/').pop();
                    fileName = fileKey?.split('/')?.pop();
                  }

                  if (!fileKey || fileKey === value[i]) {
                    newValue.push({
                      key: value[i]!,
                      id: nanoid(10),
                      file: new File(['0'], fileName ? fileName : value[i]!),
                      loading: false,
                    });
                    continue;
                  }
                  const url = 'https://stage.api.b2gcrm.ru/' + fileKey;
                  newValue.push({
                    key: fileKey,
                    id: nanoid(10),
                    file: new File(['0'], fileName ? fileName : url),
                    loading: false,
                  });
                }
                setFileItems(() => newValue);
                onChange(newValue.map(f => f.key!));
                return;
              } catch {
                return;
              }
            }
            setFileItems([]);
            onChange(undefined);
            return;
          }
          useDeepCompareEffect(() => {
            valueToFileItems();
          }, [typeof value === 'string' ? [] : value || []]);

          function uploadMultiFile(files: FieldWithPreviewMultiFileItem[]) {
            const newFileItems = handleAddFiles(files);
            const cashFileItems = [...fileItems, ...newFileItems];
            setFileItems(cashFileItems);
            newFileItems.map(async file => {
              if (!file.file) return;
              const uploadingFile = await uploadFileToS3(file.file);
              file.key = uploadingFile.key;
              try {
                await uploadingFile.fetch;
                const checkFileItems = cashFileItems.map((prevFile, i) => {
                  if (prevFile.id === file.id) {
                    cashFileItems[i] = {
                      ...file,
                      loading: false,
                      key: file.key,
                    };
                    return {
                      ...file,
                      loading: false,
                      key: file.key,
                    };
                  }
                  return prevFile;
                });
                setFileItems(() => checkFileItems);
                onChange(checkFileItems.map(f => f.key!));
              } catch {
                add({
                  content: 'Ошибка загрузки файла',
                  name: 'upload to s3 error',
                  theme: 'danger',
                });
                const newFileItems = fileItems.filter(prevFile => prevFile.id !== file.id);
                setFileItems(() => newFileItems);
                onChange(newFileItems.map(f => f.key!));
                if (fileInputRef.current && fileInputRef.current.files)
                  fileInputRef.current.files = null;
              }
            });
          }

          return (
            <>
              {show ? (
                <FieldWithPreviewWrapper
                  ref={forwardedRef}
                  name={name}
                  required={required}
                  textAlign="start"
                >
                  {!isEditable ? (
                    <div className="flex flex-col gap-2 items-start h-full">
                      {value?.length ? (
                        <>
                          {fileItems.map(fileItem => (
                            <Box
                              position="relative"
                              key={fileItem.id}
                            >
                              <Tooltip
                                content="Скачать"
                                openDelay={200}
                              >
                                {fileItem.key &&
                                fileItem.key?.includes('https://zakupki.gov.ru') ? (
                                  <>
                                    <a
                                      href={fileItem.key}
                                      download
                                      className="text-text-brand flex items-center gap-2"
                                    >
                                      {
                                        <Icon
                                          data={ArrowDownToSquare}
                                          className="shrink-0"
                                        />
                                      }
                                      Неизвестный файл
                                    </a>
                                  </>
                                ) : (
                                  <button
                                    type="button"
                                    className="text-text-brand flex items-center gap-2"
                                    onClick={() =>
                                      fileItem.key && fileItem.file.name
                                        ? downloadFileFromServer(
                                            fileItem.key.includes('https://zakupki.gov.ru')
                                              ? fileItem.key
                                              : 'https://stage.api.b2gcrm.ru/' + fileItem.key,
                                            fileItem.file.name
                                          ).catch(() =>
                                            add({
                                              title: 'Ошибка скачивания файла',
                                              name: 'file-download-catch-error',
                                              theme: 'danger',
                                            })
                                          )
                                        : add({
                                            title: 'Невозможно скачать файл',
                                            name: 'file-download-button-error',
                                            theme: 'danger',
                                          })
                                    }
                                  >
                                    <Icon
                                      data={ArrowDownToSquare}
                                      className="shrink-0"
                                    />
                                    <Text variant="body-2">{fileItem.file.name}</Text>
                                  </button>
                                )}
                              </Tooltip>
                              <Overlay visible={fileItem.loading}>
                                <Loader />
                              </Overlay>
                            </Box>
                          ))}
                          <Button
                            size="m"
                            disabled={disabled}
                            onClick={onFieldSelect}
                          >
                            Изменить
                          </Button>
                        </>
                      ) : (
                        <Text
                          variant="body-2"
                          color="primary"
                          onClick={onFieldSelect}
                        >
                          —
                        </Text>
                      )}
                    </div>
                  ) : (
                    <Box
                      ref={dropZoneRef}
                      position="relative"
                      onDragEnter={event => {
                        event.preventDefault();
                        if (disabled) return;
                        setIsDraggingOver(true);
                      }}
                      onDragLeave={event => {
                        event.preventDefault();
                        if (!dropZoneRef.current?.contains(event.relatedTarget as Node)) {
                          setIsDraggingOver(false);
                        }
                      }}
                      onDragOver={event => event.preventDefault()}
                      onDrop={event => {
                        setIsDraggingOver(false);
                        event.preventDefault();
                        if (disabled) return;
                        const files: FieldWithPreviewMultiFileItem[] = [
                          ...(event.dataTransfer.files ?? []),
                        ].map(file => ({
                          id: nanoid(8),
                          file,
                          loading: true,
                          url: undefined,
                        }));

                        uploadMultiFile(files);
                      }}
                    >
                      <div className="flex flex-col gap-4 items-start">
                        {fileItems.map(({ file, id, loading }) => (
                          <Box
                            className="flex flex-wrap gap-2 w-full"
                            position="relative"
                            key={id}
                          >
                            <PreviewLabel
                              key={id}
                              onCloseClick={() => {
                                const newFileItems = fileItems.filter(
                                  fileItem => fileItem.id !== id
                                );
                                setFileItems(newFileItems);
                                onChange(newFileItems.map(f => f.key!));
                              }}
                            >
                              {file.name}
                            </PreviewLabel>
                            <Overlay visible={loading}>
                              <Loader />
                            </Overlay>
                          </Box>
                        ))}
                        {invalid && (
                          <Text
                            variant="body-1"
                            color="danger"
                            className="break-words w-full"
                          >
                            {error?.message}
                          </Text>
                        )}
                        <input
                          ref={fileInputRef}
                          type="file"
                          multiple
                          className="hidden"
                          accept={acceptExtensions || '*'}
                          onChange={event => {
                            const files: FieldWithPreviewMultiFileItem[] = [
                              ...(event.target.files ?? []),
                            ].map(file => ({
                              id: nanoid(10),
                              file,
                              loading: true,
                            }));
                            uploadMultiFile(files);
                          }}
                        />
                        <AddButton
                          disabled={disabled}
                          onClick={() => fileInputRef.current?.click()}
                        />
                      </div>
                      <Overlay
                        visible={isDraggingOver}
                        background="float"
                      >
                        Перетяните файлы
                      </Overlay>
                    </Box>
                  )}
                </FieldWithPreviewWrapper>
              ) : null}
            </>
          );
        }}
      />
    );
  }
);

interface FieldWithPreviewSelectProps<TFieldValues extends FieldValues = FieldValues> {
  edit: ((fieldState: ControllerFieldState) => boolean) | boolean;
  show?: boolean;
  required?: boolean;
  name: string;
  path: Path<TFieldValues>;
  control: Control<TFieldValues>;
  options: (SelectOption & { preview?: LabelProps['theme'] })[];
  defaultOptions?: (SelectOption & { preview?: LabelProps['theme'] })[];
  selectedFieldPath: Path<TFieldValues> | null;
  onFieldSelect?: () => void;
  resetSelectedField: () => void;
  selectProps?: Pick<
    SelectProps,
    'placeholder' | 'onUpdate' | 'disabled' | 'onLoadMore' | 'loading'
  >;
  onFilterChange?: (filter: string) => void;
  filter?: string;
  delayFilter?: number;
  onPlusClick?: () => void;
}

const FieldWithPreviewSelect = forwardRef(
  <TFieldValues extends FieldValues = FieldValues>(
    {
      control,
      show = true,
      edit,
      name,
      options,
      defaultOptions,
      path,
      required,
      onFieldSelect,
      selectedFieldPath,
      resetSelectedField,
      selectProps: { onUpdate, ...selectProps } = {},
      onFilterChange,
      filter,
      delayFilter,
      onPlusClick,
    }: FieldWithPreviewSelectProps<TFieldValues>,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Controller
        name={path}
        control={control}
        render={({
          field: { onChange, ...field },
          fieldState: { invalid, error, isTouched, isValidating },
        }) => {
          const value = field.value as string | undefined;
          const isEditable =
            typeof edit === 'function'
              ? edit({ error, invalid, isDirty: !!value, isTouched, isValidating })
              : edit;

          const selectedOptions = options.filter(option => option.value === value).length
            ? options.filter(option => option.value === value)
            : defaultOptions?.length
              ? value === undefined
                ? defaultOptions
                : defaultOptions.filter(o => o.value === value)
              : [];

          useEffect(() => {
            if (!isEditable) onFilterChange?.('');
          }, [isEditable]);

          return (
            <>
              {show ? (
                <FieldWithPreviewWrapper
                  ref={forwardedRef}
                  name={name}
                  required={required}
                  onClick={() => selectedFieldPath !== path && onFieldSelect?.()}
                  onFocus={() => selectedFieldPath !== path && onFieldSelect?.()}
                >
                  {!isEditable ? (
                    <div className="flex flex-wrap gap-2 h-full">
                      {selectedOptions.length ? (
                        selectedOptions?.map(({ content, value, preview = 'clear' }) => (
                          <Label
                            className="max-w-full"
                            key={value}
                            size="m"
                            theme={preview}
                          >
                            {content}
                          </Label>
                        ))
                      ) : (
                        <Text
                          variant="body-2"
                          color="primary"
                        >
                          —
                        </Text>
                      )}
                    </div>
                  ) : (
                    <Select
                      {...selectProps}
                      {...field}
                      placeholder={selectProps.placeholder || 'Выбрать'}
                      size="l"
                      width="max"
                      popupWidth={'fit'}
                      filterable
                      value={[
                        selectedOptions[0]?.content ? selectedOptions[0].content.toString() : '',
                      ]}
                      options={options.length ? options : defaultOptions}
                      // options={options.filter(option => option.content !== value)}
                      disablePortal
                      errorPlacement="inside"
                      hasClear
                      virtualizationThreshold={20}
                      popupPlacement={'bottom'}
                      validationState={invalid ? 'invalid' : undefined}
                      errorMessage={error?.message}
                      getOptionHeight={() => 28}
                      onUpdate={options => {
                        resetSelectedField();
                        onChange(options[0] ? options[0] : '');
                        onUpdate?.(options);
                      }}
                      onBlur={() => {
                        onFilterChange?.('');
                        resetSelectedField();
                      }}
                      renderOption={option => <CustomSelectOption option={option} />}
                      popupClassName="max-h-[384px]"
                      // renderPopup={renderPopupProps => (
                      //   <CustomSelectPopup
                      //     {...renderPopupProps}
                      //     primaryButtonText="Выбрать"
                      //     secondaryButtonText="Сбросить"
                      //     onPrimaryButtonClick={() => {
                      //       onFilterChange?.('');
                      //       resetSelectedField();
                      //     }}
                      //     onSecondaryButtonClick={() => {
                      //       onFilterChange?.('');
                      //       onChange([]);
                      //     }}
                      //   />
                      // )}
                      renderFilter={() => (
                        <div className="flex flex-row gap-2 items-center p-2">
                          <DelayedTextInput
                            onUpdate={value => {
                              if (onFilterChange) onFilterChange(value);
                            }}
                            value={filter || ''}
                            delay={delayFilter ?? 300}
                            placeholder="Найти"
                          />
                          {onPlusClick && (
                            <Button
                              view="outlined"
                              onClick={() => {
                                onPlusClick();
                              }}
                            >
                              <Icon data={Plus} />
                            </Button>
                          )}
                        </div>
                      )}
                    />
                  )}
                </FieldWithPreviewWrapper>
              ) : null}
            </>
          );
        }}
      />
    );
  }
);

interface FieldWithPreviewMultiSelectProps<TFieldValues extends FieldValues = FieldValues> {
  edit: ((fieldState: ControllerFieldState) => boolean) | boolean;
  show?: boolean;
  required?: boolean;
  name: string;
  path: Path<TFieldValues>;
  control: Control<TFieldValues>;
  onFieldSelect?: () => void;
  options: (SelectOption & { preview?: LabelProps['theme'] })[];
  defaultOptions?: (SelectOption & { preview?: LabelProps['theme'] })[];
  selectedFieldPath: Path<TFieldValues> | null;
  resetSelectedField: () => void;
  selectProps?: Pick<
    SelectProps,
    'placeholder' | 'onUpdate' | 'disabled' | 'onLoadMore' | 'loading'
  >;
  onFilterChange?: (filter: string) => void;
  filter?: string;
  delayFilter?: number;
  onPlusClick?: () => void;
  hasCheckMarkOnFirstOption?: boolean;
}

const FieldWithPreviewMultiSelect = forwardRef(
  <TFieldValues extends FieldValues = FieldValues>(
    {
      control,
      show = true,
      edit,
      name,
      options,
      defaultOptions,
      path,
      required,
      onFieldSelect,
      selectedFieldPath,
      resetSelectedField,
      selectProps: { onUpdate, ...selectProps } = {},
      onFilterChange,
      filter,
      delayFilter,
      onPlusClick,
      hasCheckMarkOnFirstOption,
    }: FieldWithPreviewMultiSelectProps<TFieldValues>,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Controller
        name={path}
        control={control}
        render={({
          field: { onChange, ...field },
          fieldState: { invalid, error, isTouched, isValidating },
        }) => {
          const value = field.value as string[] | undefined;
          const isEditable =
            typeof edit === 'function'
              ? edit({ error, invalid, isDirty: !!value?.length, isTouched, isValidating })
              : edit;

          const selectedOptions = options.filter(option => value?.includes(option.value));

          useEffect(() => {
            if (!isEditable) onFilterChange?.('');
          }, [isEditable]);

          return (
            <>
              {show ? (
                <FieldWithPreviewWrapper
                  ref={forwardedRef}
                  name={name}
                  required={required}
                  onClick={() => selectedFieldPath !== path && onFieldSelect?.()}
                  onFocus={() => selectedFieldPath !== path && onFieldSelect?.()}
                >
                  {!isEditable ? (
                    <div className="flex flex-wrap gap-2 h-full">
                      {selectedOptions.length ? (
                        selectedOptions?.map(({ content, value, preview = 'clear' }, index) => (
                          <Label
                            className="max-w-full"
                            key={value}
                            size="m"
                            theme={preview}
                            icon={
                              hasCheckMarkOnFirstOption &&
                              index === 0 &&
                              selectedOptions.length > 1 ? (
                                <Icon
                                  size={16}
                                  data={CircleCheckFill}
                                  // fill="var(--g-color-text-warning)"
                                />
                              ) : undefined
                            }
                          >
                            {content}
                          </Label>
                        ))
                      ) : defaultOptions?.length ? (
                        defaultOptions.map(def => (
                          <Label
                            key={def.value}
                            size="m"
                            theme={def?.preview || 'clear'}
                          >
                            {def?.content || def.value}
                          </Label>
                        ))
                      ) : (
                        <Text
                          variant="body-2"
                          color="primary"
                        >
                          —
                        </Text>
                      )}
                    </div>
                  ) : (
                    <Select
                      {...selectProps}
                      {...field}
                      placeholder={selectProps.placeholder || 'Выбрать'}
                      size="l"
                      width="max"
                      popupWidth={'fit'}
                      multiple
                      filterable
                      value={value}
                      onUpdate={options => {
                        // resetSelectedField();
                        onChange(options);
                        onUpdate?.(options);
                      }}
                      onBlur={() => {
                        onFilterChange?.('');
                        resetSelectedField();
                      }}
                      hasClear
                      virtualizationThreshold={20}
                      popupPlacement={'bottom'}
                      hasCounter={!!value?.length}
                      options={options}
                      disablePortal
                      errorPlacement="inside"
                      errorMessage={error?.message}
                      validationState={invalid ? 'invalid' : undefined}
                      getOptionHeight={() => 28}
                      renderOption={option => <CustomSelectOption option={option} />}
                      renderPopup={renderPopupProps => (
                        <CustomSelectPopup
                          {...renderPopupProps}
                          primaryButtonText="Выбрать"
                          secondaryButtonText="Сбросить"
                          onPrimaryButtonClick={() => {
                            onFilterChange?.('');
                            resetSelectedField();
                          }}
                          onSecondaryButtonClick={() => {
                            onFilterChange?.('');
                            onChange([]);
                          }}
                        />
                      )}
                      renderFilter={() => (
                        <div className="flex flex-row gap-2 items-center p-2">
                          <DelayedTextInput
                            onUpdate={value => {
                              if (onFilterChange) onFilterChange(value);
                            }}
                            value={filter || ''}
                            delay={delayFilter ?? 300}
                            placeholder="Найти"
                          />
                          {onPlusClick && (
                            <Button
                              view="outlined"
                              onClick={() => {
                                onPlusClick();
                              }}
                            >
                              <Icon data={Plus} />
                            </Button>
                          )}
                        </div>
                      )}
                    />
                  )}
                </FieldWithPreviewWrapper>
              ) : null}
            </>
          );
        }}
      />
    );
  }
);

interface FieldWithPreviewRadioProps<TFieldValues extends FieldValues = FieldValues> {
  edit: ((fieldState: ControllerFieldState) => boolean) | boolean;
  show?: boolean;
  required?: boolean;
  name: string;
  path: Path<TFieldValues>;
  control: Control<TFieldValues>;
  onFieldSelect?: () => void;
  radioButtonProps?: Pick<RadioButtonProps, 'disabled'>;
  onUpdate?: (value: string | undefined) => void;
  resetSelectedField: () => void;
  options: (RadioGroupOption & { preview?: LabelProps['theme'] })[];
  defaultOption?: RadioGroupOption & { preview?: LabelProps['theme'] };
}

const FieldWithPreviewRadio = forwardRef(
  <TFieldValues extends FieldValues = FieldValues>(
    {
      control,
      show = true,
      edit,
      name,
      options,
      path,
      required,
      onFieldSelect,
      radioButtonProps,
      resetSelectedField,
      defaultOption,
      onUpdate,
    }: FieldWithPreviewRadioProps<TFieldValues>,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    return (
      <Controller
        name={path}
        control={control}
        render={({
          field: { onChange, ...field },
          fieldState: { error, invalid, isTouched, isValidating },
        }) => {
          const value = field.value as string | undefined;
          const isEditable =
            typeof edit === 'function'
              ? edit({ error, invalid, isDirty: !!value, isTouched, isValidating })
              : edit;
          const selectedOption =
            options.find(option => option.value === value) ||
            (defaultOption?.value === value ? defaultOption : undefined);

          console.log(path, value);

          return (
            <>
              {show ? (
                <FieldWithPreviewWrapper
                  ref={forwardedRef}
                  name={name}
                  required={required}
                  onClick={onFieldSelect}
                  onFocus={onFieldSelect}
                >
                  {!isEditable ? (
                    <>
                      {selectedOption ? (
                        <Label
                          size="m"
                          theme={selectedOption?.preview}
                        >
                          {selectedOption?.content}
                        </Label>
                      ) : (
                        <Text
                          variant="body-2"
                          color="primary"
                        >
                          —
                        </Text>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        ref={containerRef}
                        className="flex items-center"
                      >
                        <RadioButton
                          {...radioButtonProps}
                          {...field}
                          size="l"
                          value={value || null}
                          defaultValue={defaultOption?.value}
                          onUpdate={newValue => {
                            onChange(newValue);
                            // resetSelectedField();
                            onUpdate?.(newValue);
                          }}
                          options={options}
                          className={cn({
                            'radio-button-error': !!error,
                          })}
                        />
                        {error && (
                          <Popover
                            content={error.message}
                            className="ml-2"
                          >
                            <span data-qa="control-error-icon-qa">
                              <Icon
                                data={TriangleExclamation}
                                className="g-select-control__error-icon"
                                size={16}
                              />
                            </span>
                          </Popover>
                        )}
                      </div>
                      <Popup
                        open={!!value}
                        anchorRef={containerRef}
                        placement={'left'}
                        disablePortal
                        className="z-0"
                      >
                        <Button
                          onClick={() => {
                            onChange(undefined);
                            resetSelectedField();
                            onUpdate?.(undefined);
                          }}
                        >
                          Сбросить
                        </Button>
                      </Popup>
                    </>
                  )}
                </FieldWithPreviewWrapper>
              ) : null}
            </>
          );
        }}
      />
    );
  }
);

interface FieldWithPreviewProgressProps<TFieldValues extends FieldValues = FieldValues> {
  control: Control<TFieldValues>;
  show?: boolean;
  path: Path<TFieldValues>;
  edit: ((fieldState: ControllerFieldState) => boolean) | boolean;
  required?: boolean;
  onFieldSelect?: () => void;
  name: string;
  sliderProps?: SliderProps;
  progressProps?: ProgressProps;
}

const FieldWithPreviewProgress = forwardRef(
  <TFieldValues extends FieldValues = FieldValues>(
    {
      edit,
      show = true,
      control,
      name,
      path,
      required,
      onFieldSelect,
      sliderProps,
      progressProps,
    }: FieldWithPreviewProgressProps<TFieldValues>,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => (
    <Controller
      name={path}
      control={control}
      render={({
        field: { ref, onChange, ...field },
        fieldState: { error, invalid, isDirty, isTouched, isValidating },
      }) => {
        const value = field.value as number | undefined;

        const [progressValue, setProgressValue] = useState(value);
        const isEditable =
          typeof edit === 'function'
            ? edit({ error, invalid, isDirty, isTouched, isValidating })
            : edit;

        return (
          <>
            {show ? (
              <FieldWithPreviewWrapper
                ref={forwardedRef}
                name={name}
                required={required}
                onClick={onFieldSelect}
                onFocus={onFieldSelect}
              >
                {!isEditable ? (
                  typeof value === 'number' ? (
                    <Progress
                      theme="info"
                      {...progressProps}
                      value={value}
                    />
                  ) : (
                    <Text
                      variant="body-2"
                      color="primary"
                    >
                      —
                    </Text>
                  )
                ) : (
                  <Slider
                    {...sliderProps}
                    {...field}
                    hasTooltip
                    ref={ref}
                    size="xl"
                    value={progressValue}
                    onUpdate={v => setProgressValue(v as number)}
                    onUpdateComplete={onChange}
                    errorMessage={error?.message}
                    validationState={invalid ? 'invalid' : undefined}
                  />
                )}
              </FieldWithPreviewWrapper>
            ) : null}
          </>
        );
      }}
    />
  )
);

interface FieldWithPreviewCardWithSelectProps<TFieldValues extends FieldValues = FieldValues> {
  edit: ((fieldState: ControllerFieldState) => boolean) | boolean;
  show?: boolean;
  required?: boolean;
  name: string;
  path: Path<TFieldValues>;
  control: Control<TFieldValues>;
  options: (SelectOption & { preview?: LabelProps['theme'] })[];
  defaultOptions?: (SelectOption & { preview?: LabelProps['theme'] })[];
  selectedFieldPath: Path<TFieldValues> | null;
  onFieldSelect?: () => void;
  resetSelectedField: () => void;
  selectProps?: Pick<
    SelectProps,
    'placeholder' | 'onUpdate' | 'disabled' | 'onLoadMore' | 'loading'
  >;
  onFilterChange?: (filter: string) => void;
  filter?: string;
  delayFilter?: number;
  onPlusClick?: () => void;
  fieldComponents: Record<string, ReactNode>;
}

const FieldWithPreviewCardWithSelect = forwardRef(
  <TFieldValues extends FieldValues = FieldValues>(
    {
      control,
      show = true,
      edit,
      name,
      options,
      defaultOptions,
      path,
      required,
      onFieldSelect,
      selectedFieldPath,
      resetSelectedField,
      selectProps: { onUpdate, ...selectProps } = {},
      onFilterChange,
      filter,
      delayFilter,
      onPlusClick,
      fieldComponents,
    }: FieldWithPreviewCardWithSelectProps<TFieldValues>,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Controller
        name={path}
        control={control}
        render={({
          field: { onChange, ...field },
          fieldState: { invalid, error, isTouched, isValidating },
        }) => {
          const value = field.value as string | undefined;
          const isEditable =
            typeof edit === 'function'
              ? edit({ error, invalid, isDirty: !!value, isTouched, isValidating })
              : edit;

          const selectedOptions = options.length
            ? options.filter(option => option.value === value)
            : defaultOptions?.length
              ? value === undefined
                ? defaultOptions
                : defaultOptions.filter(o => o.value === value)
              : [];

          useEffect(() => {
            if (!isEditable) onFilterChange?.('');
          }, [isEditable]);

          return (
            <>
              {show ? (
                <FieldWithPreviewWrapper
                  ref={forwardedRef}
                  name={name}
                  required={required}
                  onClick={() => selectedFieldPath !== path && onFieldSelect?.()}
                  onFocus={() => selectedFieldPath !== path && onFieldSelect?.()}
                >
                  {!isEditable ? (
                    <div className="flex flex-wrap gap-2 h-full">
                      {selectedOptions.length ? (
                        <div className="border border-1 border-custom-text-warning max-w-[320px]">
                          {objectKeysSafeToArray(fieldComponents).map(key => fieldComponents[key])}
                        </div>
                      ) : (
                        <Text
                          variant="body-2"
                          color="primary"
                        >
                          —
                        </Text>
                      )}
                    </div>
                  ) : (
                    <Select
                      {...selectProps}
                      {...field}
                      size="l"
                      width="max"
                      popupWidth={'fit'}
                      filterable
                      value={[
                        selectedOptions[0]?.content ? selectedOptions[0].content.toString() : '',
                      ]}
                      options={options.length ? options : defaultOptions}
                      // options={options.filter(option => option.content !== value)}
                      disablePortal
                      errorPlacement="inside"
                      hasClear
                      virtualizationThreshold={20}
                      popupPlacement={'bottom'}
                      validationState={invalid ? 'invalid' : undefined}
                      errorMessage={error?.message}
                      getOptionHeight={() => 28}
                      onUpdate={options => {
                        resetSelectedField();
                        onChange(options[0] ? options[0] : '');
                        onUpdate?.(options);
                      }}
                      onBlur={() => {
                        onFilterChange?.('');
                        resetSelectedField();
                      }}
                      renderOption={option => <CustomSelectOption option={option} />}
                      popupClassName="max-h-[384px]"
                      // renderPopup={renderPopupProps => (
                      //   <CustomSelectPopup
                      //     {...renderPopupProps}
                      //     primaryButtonText="Выбрать"
                      //     secondaryButtonText="Сбросить"
                      //     onPrimaryButtonClick={() => {
                      //       onFilterChange?.('');
                      //       resetSelectedField();
                      //     }}
                      //     onSecondaryButtonClick={() => {
                      //       onFilterChange?.('');
                      //       onChange([]);
                      //     }}
                      //   />
                      // )}
                      renderFilter={() => (
                        <div className="flex flex-row gap-2 items-center p-2">
                          <DelayedTextInput
                            onUpdate={value => {
                              if (onFilterChange) onFilterChange(value);
                            }}
                            value={filter || ''}
                            delay={delayFilter ?? 300}
                            placeholder="Найти"
                          />
                          {onPlusClick && (
                            <Button
                              view="outlined"
                              onClick={() => {
                                onPlusClick();
                              }}
                            >
                              <Icon data={Plus} />
                            </Button>
                          )}
                        </div>
                      )}
                    />
                  )}
                </FieldWithPreviewWrapper>
              ) : null}
            </>
          );
        }}
      />
    );
  }
);

interface FieldWithPreviewCompoundProps {
  children?: ReactNode;
}

type FieldWithPreviewCompound = FC<FieldWithPreviewCompoundProps> & {
  Text: typeof FieldWithPreviewText;
  TextArea: typeof FieldWithPreviewTextArea;
  Currency: typeof FieldWithPreviewCurrency;
  Percentage: typeof FieldWithPreviewPercentage;
  Phone: typeof FieldWithPreviewPhone;
  Radio: typeof FieldWithPreviewRadio;
  Date: typeof FieldWithPreviewDate;
  MultiDate: typeof FieldWithPreviewMultiDate;
  File: typeof FieldWithPreviewFile;
  MultiFile: typeof FieldWithPreviewMultiFile;
  Select: typeof FieldWithPreviewSelect;
  MultiSelect: typeof FieldWithPreviewMultiSelect;
  Wrapper: typeof FieldWithPreviewWrapper;
  Progress: typeof FieldWithPreviewProgress;
  CardWithSelect: typeof FieldWithPreviewCardWithSelect;
};

export type FieldWithPreviewProps = {
  Text: FieldWithPreviewTextProps;
  TextArea: FieldWithPreviewTextAreaProps;
  Currency: FieldWithPreviewCurrencyProps;
  Percentage: FieldWithPreviewPercentageProps;
  Phone: FieldWithPreviewPhoneProps;
  Radio: FieldWithPreviewRadioProps;
  Date: FieldWithPreviewDateProps;
  MultiDate: FieldWithPreviewMultiDateProps;
  File: FieldWithPreviewFileProps;
  MultiFile: FieldWithPreviewMultiFileProps;
  Select: FieldWithPreviewSelectProps;
  MultiSelect: FieldWithPreviewMultiSelectProps;
  Wrapper: FieldWithPreviewWrapperProps;
  Progress: FieldWithPreviewProgressProps;
  CardWithSelect: FieldWithPreviewCardWithSelectProps;
};

export const FieldWithPreview = (({ children }) => {
  return (
    <div className="flex flex-col gap-6 overflow-y-auto hide-scrollbar px-8 pb-8 grow max-w-full">
      {children}
    </div>
  );
}) as FieldWithPreviewCompound;

FieldWithPreview.Text = FieldWithPreviewText;
FieldWithPreview.TextArea = FieldWithPreviewTextArea;
FieldWithPreview.Currency = FieldWithPreviewCurrency;
FieldWithPreview.Percentage = FieldWithPreviewPercentage;
FieldWithPreview.Radio = FieldWithPreviewRadio;
FieldWithPreview.Date = FieldWithPreviewDate;
FieldWithPreview.MultiDate = FieldWithPreviewMultiDate;
FieldWithPreview.File = FieldWithPreviewFile;
FieldWithPreview.MultiFile = FieldWithPreviewMultiFile;
FieldWithPreview.Select = FieldWithPreviewSelect;
FieldWithPreview.MultiSelect = FieldWithPreviewMultiSelect;
FieldWithPreview.Wrapper = FieldWithPreviewWrapper;
FieldWithPreview.Phone = FieldWithPreviewPhone;
FieldWithPreview.Progress = FieldWithPreviewProgress;
FieldWithPreview.CardWithSelect = FieldWithPreviewCardWithSelect;
