import { useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDebounce } from 'use-debounce';

import { $contactsHooks } from '@entities';
import {
  ContactsHandbookForm,
  ContactsHandbookSchema,
  contactsHandbookSchema,
  CRMTable,
  CRMTableColumns,
  CRMTableData,
  useTableColumnSort,
} from '@features';
import { $api, bodyRequestType, components } from '@shared/api';
import { formatPhone } from '@shared/lib';

import { CONTACTS_TABLE_COLUMNS_WITH_FILTER } from '../consts';
import { ColumnsContactsHandbookTable, CustomHandbookTableDefaultProps } from '../model';
import { CustomerHandbookDrawerLayout } from './CustomHandbookDrawerLayout';
import { CustomHandbookTableLayout } from './CustomHandbookTableLayout';
import { HandbookFormFooter } from './HandbookFormFooter';

type ContactsHandbookTableProps = CustomHandbookTableDefaultProps;

export function ContactsHandbookTable({
  creationOpen,
  onCloseCreation,
}: ContactsHandbookTableProps) {
  const { add } = useToaster();

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const getHandbookContactsInfiniteQuery = $contactsHooks.useGetAll(
    {
      query: debouncedSearchValue,
      limit: 30,
    },
    true
  );

  const flattedPages = getHandbookContactsInfiniteQuery.data?.pages.flat();

  const data: CRMTableData<ColumnsContactsHandbookTable> = useMemo(
    () =>
      flattedPages?.map(contact => ({
        'Статус контактного лица': contact?.status || '',
        'Тип контактного лица': contact?.type || '',
        'Эл. адрес': contact?.email || '',
        Должность: contact?.position || '',
        Комментарий: contact?.comment || '',
        Организация: contact?.organization_id.toString() || '',
        Телефон: contact?.phone || '',
        ФИО: contact?.full_name || '',
      })) ?? [],
    [getHandbookContactsInfiniteQuery.data]
  );

  const { columnsSort, changeColumnSort, updateColumnsSortValuesBySettings } =
    useTableColumnSort(data);

  const columnWithHover = (name: ColumnsContactsHandbookTable[number]) => {
    const sortValue = columnsSort[name];
    if (typeof sortValue === 'undefined') {
      return name;
    }

    return (
      <CRMTable.HoverColumnWrapper
        sort={sortValue}
        iconPosition="end"
        content={name}
        columnName={name}
        onSortTypeChange={(newSort, colName) => changeColumnSort(newSort, colName)}
      />
    );
  };

  const columns: CRMTableColumns<ColumnsContactsHandbookTable> = [
    {
      id: 'Организация',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      name: () => columnWithHover('Организация'),
    },
    {
      id: 'Статус контактного лица',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      name: () => columnWithHover('Статус контактного лица'),
    },
    {
      id: 'Телефон',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      name: () => columnWithHover('Телефон'),
      template: item => <>{item['Телефон'] ? formatPhone(item['Телефон']) : ''}</>,
    },
    {
      id: 'Тип контактного лица',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      name: () => columnWithHover('Тип контактного лица'),
    },
    {
      id: 'ФИО',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      name: () => columnWithHover('ФИО'),
    },
    {
      id: 'Эл. адрес',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      name: () => columnWithHover('Эл. адрес'),
    },
    {
      id: 'Должность',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      name: () => columnWithHover('Должность'),
    },
    {
      id: 'Комментарий',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      className: 'max-w-[250px] truncate',
      name: () => columnWithHover('Комментарий'),
      template: item => <span title={item['Комментарий']}>{item['Комментарий']}</span>,
    },
  ];

  const [editableHandbook, setEditableHandbook] = useState<
    components['schemas']['handbook.Contact'] | null
  >(null);

  const postHandbookContactsMutation = $api.useMutation('post', '/handbook/contacts');

  const createContactsHandbookForm = useForm<ContactsHandbookSchema>({
    resolver: yupResolver(contactsHandbookSchema),
    mode: 'all',
    defaultValues: { phone: '' },
  });

  const onValidCreateHandbook = async (values: ContactsHandbookSchema) => {
    try {
      await postHandbookContactsMutation.mutateAsync({
        body: values as bodyRequestType<'post', '/handbook/contacts'>, // TODO: убрать as, когда пофиксят схему
      });
      getHandbookContactsInfiniteQuery.refetch();
      add({
        name: 'contacts-create-success',
        theme: 'success',
        title: `Справочник успешно создан`,
      });
      onCloseCreation();
    } catch {
      add({
        name: 'contacts-create-failed',
        theme: 'danger',
        title: `Не удалось создать справочник`,
      });
    }
  };

  const putHandbookContactsIdMutation = $api.useMutation('put', '/handbook/contacts/{id}');

  const editContactsHandbookForm = useForm<ContactsHandbookSchema>({
    resolver: yupResolver(contactsHandbookSchema),
    values: {
      ...editableHandbook,
      full_name: editableHandbook?.full_name ?? '',
      organization_id: editableHandbook?.id ?? 0,
      organization_type: editableHandbook?.organization_type ?? '',
      phone: editableHandbook?.phone ?? '',
    },
    mode: 'all',
    defaultValues: {
      comment: '',
      email: '',
      full_name: '',
      phone: '',
      position: '',
    },
  });

  const isEditContactsHandbookFormDirty = editContactsHandbookForm.formState.isDirty;

  const handleEditHandbook = () => {
    editContactsHandbookForm.handleSubmit(async values => {
      if (editableHandbook && isEditContactsHandbookFormDirty) {
        try {
          await putHandbookContactsIdMutation.mutateAsync({
            body: values as bodyRequestType<'put', '/handbook/contacts'>, // TODO: убрать as, когда пофиксят схему
            params: { path: { id: editableHandbook.id! } },
          });
          add({
            name: 'contacts-create-success',
            theme: 'success',
            title: `Справочник успешно изменен`,
          });
          getHandbookContactsInfiniteQuery.refetch();
        } catch {
          add({
            name: 'contacts-create-failed',
            theme: 'danger',
            title: `Не удалось изменить справочник`,
          });
        }
      }
    })();
    setEditableHandbook(null);
  };

  const deleteHandbookContactsIdMutation = $api.useMutation('delete', '/handbook/contacts/{id}');

  return (
    <>
      <CustomHandbookTableLayout
        columns={columns}
        data={data}
        tableColumnsWithFilter={CONTACTS_TABLE_COLUMNS_WITH_FILTER}
        searchValue={searchValue}
        onSearchValueUpdate={setSearchValue}
        updateColumnsSortValuesBySettings={updateColumnsSortValuesBySettings}
        onRowClick={(_, index) => setEditableHandbook(flattedPages?.[index] ?? null)}
        onIntersecting={(() => {
          let retry = false;

          return () => {
            if (
              getHandbookContactsInfiniteQuery.isLoading ||
              getHandbookContactsInfiniteQuery.isFetchingNextPage ||
              getHandbookContactsInfiniteQuery.fetchStatus === 'fetching' ||
              !getHandbookContactsInfiniteQuery.hasNextPage ||
              retry
            )
              return;

            getHandbookContactsInfiniteQuery.fetchNextPage();
            retry = true;
          };
        })()}
        onClickDelete={async index => {
          const item = flattedPages?.[index];

          if (item) {
            try {
              await deleteHandbookContactsIdMutation.mutateAsync({
                params: { path: { id: item.id! } },
              });

              getHandbookContactsInfiniteQuery.refetch();

              add({
                name: 'contacts-delete-success',
                theme: 'success',
                title: 'Атрибут успешно удален',
              });
            } catch {
              add({
                name: 'contacts-delete-failure',
                theme: 'danger',
                title: 'Не удалось удалить атрибут',
              });
            }
          }
        }}
      />
      <CustomerHandbookDrawerLayout
        open={creationOpen}
        onClose={onCloseCreation}
        title="Создать справочник «Контактные лица»"
      >
        <ContactsHandbookForm
          form={createContactsHandbookForm}
          onSubmit={createContactsHandbookForm.handleSubmit(onValidCreateHandbook)}
          disabled={postHandbookContactsMutation.isPending}
          footer={
            <HandbookFormFooter
              loading={postHandbookContactsMutation.isPending}
              onClose={onCloseCreation}
            />
          }
        />
      </CustomerHandbookDrawerLayout>

      <CustomerHandbookDrawerLayout
        open={!!editableHandbook}
        onClose={handleEditHandbook}
        title={editableHandbook?.full_name}
      >
        <ContactsHandbookForm
          form={editContactsHandbookForm}
          disabled={putHandbookContactsIdMutation.isPending}
          initialEditable={false}
        />
      </CustomerHandbookDrawerLayout>
    </>
  );
}
