/* eslint-disable @typescript-eslint/no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';

import {
  $api,
  $mock,
  bodyRequestType,
  fetchClient,
  mockClient,
  ParametersPathType,
  ParametersQueryType,
} from '@shared/api';

function useGetHandbookOkpd2sInfiniteQuery(
  query: ParametersQueryType<'get', '/handbook/okpd2s'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/handbook/okpd2s', query],
    queryFn: async ({ pageParam }) => {
      const { data } = await fetchClient.GET('/handbook/okpd2s', {
        // const { data } = await mockClient.GET('/handbook/okpd2s', {
        params: { query: { ...query, offset: pageParam } },
      });

      return data?.list || [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + query.limit;
    },
    enabled,
  });
}

const createHandbookOkpd2sMutation = () => $api.useMutation('post', '/handbook/okpd2s');
// const createHandbookOkpd2s = (body: bodyRequestType<'post', '/handbook/okpd2s'>) =>
//   createHandbookOkpd2sMutation.mutateAsync({ body: body });

const deleteByIdHandbookOkpd2s = (
  value: ParametersPathType<'delete', '/handbook/okpd2s/{value}'>['value']
) =>
  $api
    .useMutation('delete', '/handbook/okpd2s/{value}')
    .mutateAsync({ params: { path: { value: value } } });

const useGetByValueHandbookOkpd2s = (
  value: ParametersPathType<'get', '/handbook/okpd2s/{value}'>['value']
) => $api.useQuery('get', '/handbook/okpd2s/{value}', { params: { path: { value: value } } });

const updateHandbookOkpd2sMutation = () => $api.useMutation('put', '/handbook/okpd2s/{value}');
// const updateHandbookOkpd2s = (
//   value: ParametersPathType<'put', '/handbook/okpd2s/{value}'>['value'],
//   body: bodyRequestType<'put', '/handbook/okpd2s/{value}'>
// ) => updateHandbookOkpd2sMutation.mutateAsync({ body: body, params: { path: { value: value } } });

export const $okpd2sHooks = {
  useGetAll: useGetHandbookOkpd2sInfiniteQuery,
  useGetById: useGetByValueHandbookOkpd2s,
  createMutation: createHandbookOkpd2sMutation,
  updateByIdMutation: updateHandbookOkpd2sMutation,
  deleteById: deleteByIdHandbookOkpd2s,
};
