import { Comments } from '@gravity-ui/icons';
import { Button, Icon, RadioButton, Spin, Text, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { $contractHooks } from '@entities';
import { ContractSchema } from '@features';
import { contractSchema } from '@features/contract-form/model';
import { URLS } from '@shared/consts';
import { fromBooleanToYesNo, fromCustomBooleanSchemaToBoolean, useNavigateTo } from '@shared/lib';
import { RightSidebar } from '@shared/ui';

import {
  checkIsNotBlankRelations,
  convertCustomerPriorityToNumber,
  convertCustomerPriorityToString,
  countRelations,
  getAllowedStages,
  percentAndCurrencyValuesFromBackend,
  percentAndCurrencyValuesToBackend,
} from '../lib';
import { ContractLayout } from './ContractLayout';
import { ContractRelations } from './ContractRelations';

interface EditContractProps {
  id: string | undefined;
  onClose: () => void;
}

export function EditContract({ id, onClose }: EditContractProps) {
  const [isOpen, setIsOpen] = useState(false);

  const queryClient = useQueryClient();

  const { navigateTo } = useNavigateTo();
  const { add } = useToaster();

  const [activeTab, setActiveTab] = useState<'project' | 'relations'>('project');

  const contractForm = useForm<ContractSchema>({
    resolver: yupResolver(contractSchema),
    mode: 'all',
    resetOptions: {
      keepDefaultValues: false,
    },
  });

  const contractValues = contractForm.watch();

  // Queries
  const getContractQuery = $contractHooks.getById(id || '', !!id);

  const updateContractMutation = $contractHooks.update();
  const getContractRelatedQuery = $contractHooks.related(id || '', !!id);
  //

  useEffect(() => {
    if (getContractQuery.error) {
      navigateTo(URLS.processes.contracts);
      add({
        theme: 'danger',
        name: 'update-offer-error',
        title: 'Произошла ошибка при обновлении карточки Контрактов',
      });
    }
  }, [getContractQuery.isFetching]);

  useEffect(() => {
    // Уведомления об обновлении карточки КП
    if (updateContractMutation.isSuccess) {
      add({
        theme: 'success',
        name: 'update-contract-success',
        title: 'Карточка Контракта обновлена успешно',
      });
    }
    if (updateContractMutation.isError) {
      add({
        theme: 'danger',
        name: 'update-contract-error',
        title: 'Ошибка при обновлении карточки Контракта',
      });
    }
  }, [updateContractMutation.isPending]);

  useEffect(() => {
    if (id) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [id]);

  return (
    <ContractLayout
      formProps={{
        response: getContractQuery.data
          ? {
              ...getContractQuery.data,
              // TODO: требует уникальный номер
              ...percentAndCurrencyValuesFromBackend({
                ...getContractQuery.data,
                customer_priority: convertCustomerPriorityToString(
                  getContractQuery.data.customer_priority
                ),
              }),
              has_contract_security: fromBooleanToYesNo(
                getContractQuery.data.has_contract_security
              ),
              has_smp_restriction: fromBooleanToYesNo(getContractQuery.data.has_smp_restriction),
              has_warranty_security: fromBooleanToYesNo(
                getContractQuery.data.has_warranty_security
              ),
              customer_priority: convertCustomerPriorityToString(
                getContractQuery.data.customer_priority
              ),
            }
          : undefined,
        hide: activeTab !== 'project',
        // values: contractValues,
        // defaultValues: contractValues,
        submitButtonText: 'Применить',
        disabled:
          getContractQuery.data?.stage === 'Исполнен' ||
          getContractQuery.data?.stage === 'Рассчитан' ||
          getContractQuery.data?.stage === 'Расторгнут',
        allowedStages: getContractQuery.data?.stage
          ? getAllowedStages(getContractQuery.data.stage)
          : ['Проект'],
        initialEditable: false,
        // onValuesChange: values => {
        //   // if (values['status'] === 'Выигран' || values['status'] === 'Проигран') {
        //   //   contractForm?.setValue('purchase_status', 'Состоялась');
        //   // }
        //   contractForm.reset(values);
        // },
        onClickCancelButton: onClose,
        onValid: async values => {
          if (!id) return;
          await updateContractMutation
            .mutateAsync({
              body: {
                ...values,
                ...percentAndCurrencyValuesToBackend(values),
                has_contract_security: fromCustomBooleanSchemaToBoolean(
                  values.has_contract_security
                ),
                has_smp_restriction: fromCustomBooleanSchemaToBoolean(values.has_smp_restriction),
                has_warranty_security: fromCustomBooleanSchemaToBoolean(
                  values.has_warranty_security
                ),
                customer_priority: convertCustomerPriorityToNumber(values.customer_priority),
              },
              params: {
                path: {
                  id,
                },
              },
            })
            .then(() => {
              queryClient.invalidateQueries({ queryKey: ['/contract/all'] });
              onClose();
            });
        },
        onInvalid: errors => console.log('@errors', errors),
      }}
      renderForm={formComponent => (
        <RightSidebar
          id="sidebar-commercial-offer"
          onClose={() => {
            setIsOpen(false);
            onClose();
          }}
          open={isOpen}
        >
          <RightSidebar.Header
            title={
              getContractQuery.data?.name || contractValues.name || 'Редактирование контрактов'
            }
            onClose={() => {
              setIsOpen(false);
              onClose();
            }}
          />
          <div className="px-8 pb-8 flex justify-between">
            <RadioButton
              size="l"
              onUpdate={setActiveTab}
              options={[
                { value: 'project', content: 'О проекте' },
                {
                  value: 'relations',
                  content: (
                    <span>
                      Связи
                      <Text
                        variant="body-1"
                        color="hint"
                        className="ml-1"
                      >
                        {countRelations(getContractRelatedQuery.data)}
                      </Text>
                    </span>
                  ),
                },
              ]}
            />
            <Button
              size="l"
              onClick={() =>
                navigateTo(URLS.messenger + '/' + getContractQuery.data?.chat_id || '')
              }
            >
              <Icon data={Comments} />
              Чат
            </Button>
          </div>
          {formComponent}
          {activeTab === 'relations' && (
            <>
              {(getContractRelatedQuery.isLoading || getContractQuery.isLoading) && (
                <Spin
                  size="l"
                  className="mx-8"
                />
              )}
              {(getContractRelatedQuery.isError || getContractQuery.isError) && (
                <Text
                  className="mx-8"
                  color="danger-heavy"
                  variant="body-2"
                >
                  Ошибка загрузки связей
                </Text>
              )}
              {getContractRelatedQuery.data &&
              checkIsNotBlankRelations(getContractRelatedQuery.data) &&
              getContractQuery.data ? (
                <ContractRelations relations={getContractRelatedQuery.data} />
              ) : (
                countRelations(getContractRelatedQuery.data) === 0 && (
                  <Text
                    className="mx-8 font-semibold"
                    variant="body-2"
                  >
                    Связей не обнаружено
                  </Text>
                )
              )}
            </>
          )}
        </RightSidebar>
      )}
    />
  );
}
