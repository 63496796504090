import { yupRu } from '@shared/config';

export const purchaseObjectsSchema = yupRu.object({
  comment: yupRu
    .string()
    .max(255)
    .test({
      test: value => !value || value.length >= 3,
      message: 'Должен содержать как минимум 3 символа',
    }),
  object: yupRu.string().min(3).max(255).required(),
  okpd_2_value: yupRu.string(),
  supplier_id: yupRu.string().required(),
});

export type PurchaseObjectsSchema = yupRu.InferType<typeof purchaseObjectsSchema>;
