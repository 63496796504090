import { FormEventHandler, ReactNode } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview } from '@shared/ui';

import { Okpd2Schema } from '../model';

interface Okpd2FormProps {
  form: UseFormReturn<Okpd2Schema>;
  footer?: ReactNode;
  initialEditable?: boolean;
  disabled?: boolean;
  onSubmit?: FormEventHandler<HTMLFormElement>;
}

export function Okpd2Form({
  form,
  footer,
  initialEditable = true,
  disabled = false,
  onSubmit,
}: Okpd2FormProps) {
  const { createFieldRef, isFieldEditable, selectField } = useFieldsWithPreview({
    form,
    initialEditable,
  });

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={onSubmit}
    >
      <FieldWithPreview>
        <FieldWithPreview.Text
          ref={createFieldRef('value')}
          control={form.control}
          path="value"
          name="Значение"
          edit={isFieldEditable('value')}
          onFieldSelect={() => selectField('value')}
          textInputProps={{ disabled }}
          required
        />
      </FieldWithPreview>
      {footer}
    </form>
  );
}
