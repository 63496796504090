import { FormEventHandler, ReactNode } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview } from '@shared/ui';

import { RegionsSchema } from '../model';

interface RegionsFormProps {
  form: UseFormReturn<RegionsSchema>;
  footer?: ReactNode;
  initialEditable?: boolean;
  disabled?: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
}

export function RegionsForm({
  form,
  footer,
  initialEditable = true,
  disabled = false,
  onSubmit,
}: RegionsFormProps) {
  const { createFieldRef, isFieldEditable, selectField } = useFieldsWithPreview({
    form,
    initialEditable,
  });

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={e => {
        e.preventDefault();
        onSubmit(e);
      }}
    >
      <FieldWithPreview>
        <FieldWithPreview.Text
          ref={createFieldRef('name')}
          control={form.control}
          path="name"
          name="Наименование"
          edit={isFieldEditable('name')}
          onFieldSelect={() => selectField('name')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('code')}
          control={form.control}
          path="code"
          name="Код региона"
          edit={isFieldEditable('code')}
          onFieldSelect={() => selectField('code')}
          textInputProps={{ disabled, type: 'number' }}
          required
        />
      </FieldWithPreview>
      {footer}
    </form>
  );
}
