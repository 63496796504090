/* eslint-disable @typescript-eslint/no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';

import {
  $api,
  $mock,
  bodyRequestType,
  fetchClient,
  mockClient,
  ParametersPathType,
  ParametersQueryType,
} from '@shared/api';

function useGetHandbookKnowledgeBasesInfiniteQuery(
  query: ParametersQueryType<'get', '/handbook/knowledge-bases'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/handbook/knowledge-bases', query],
    queryFn: async ({ pageParam }) => {
      const { data } = await fetchClient.GET('/handbook/knowledge-bases', {
        // const { data } = await mockClient.GET('/handbook/knowledge-bases', {
        params: { query: { ...query, offset: pageParam } },
      });

      return data?.list || [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + query.limit;
    },
    enabled,
  });
}

const createHandbookKnowledgeBasesMutation = () =>
  $api.useMutation('post', '/handbook/knowledge-bases');
// const createHandbookKnowledgeBases = (body: bodyRequestType<'post', '/handbook/knowledge-bases'>) =>
//   createHandbookKnowledgeBasesMutation.mutateAsync({ body: body });

const deleteByIdHandbookKnowledgeBases = (
  id: ParametersPathType<'delete', '/handbook/knowledge-bases/{id}'>['id']
) =>
  $api
    .useMutation('delete', '/handbook/knowledge-bases/{id}')
    .mutateAsync({ params: { path: { id: id } } });

const useGetByIdHandbookKnowledgeBases = (
  id: ParametersPathType<'get', '/handbook/knowledge-bases/{id}'>['id']
) => $api.useQuery('get', '/handbook/knowledge-bases/{id}', { params: { path: { id: id } } });

const updateHandbookKnowledgeBasesMutation = () =>
  $api.useMutation('put', '/handbook/knowledge-bases/{id}');
// const updateHandbookKnowledgeBases = (
//   id: ParametersPathType<'put', '/handbook/knowledge-bases/{id}'>['id'],
//   body: bodyRequestType<'put', '/handbook/knowledge-bases/{id}'>
// ) => updateHandbookKnowledgeBasesMutation.mutateAsync({ body: body, params: { path: { id: id } } });

export const $knowledgeBasesHooks = {
  useGetAll: useGetHandbookKnowledgeBasesInfiniteQuery,
  useGetById: useGetByIdHandbookKnowledgeBases,
  createMutation: createHandbookKnowledgeBasesMutation,
  updateByIdMutation: updateHandbookKnowledgeBasesMutation,
  deleteById: deleteByIdHandbookKnowledgeBases,
};
