/* eslint-disable @typescript-eslint/no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';

import {
  $api,
  $mock,
  bodyRequestType,
  fetchClient,
  mockClient,
  ParametersPathType,
  ParametersQueryType,
} from '@shared/api';

function useGetHandbookRestrictionAdvantageRequirementsInfiniteQuery(
  query: ParametersQueryType<'get', '/handbook/restriction-advantage-requirements'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/handbook/restriction-advantage-requirements', query],
    queryFn: async ({ pageParam }) => {
      const { data } = await fetchClient.GET('/handbook/restriction-advantage-requirements', {
        // const { data } = await mockClient.GET('/handbook/restriction-advantage-requirements', {
        params: { query: { ...query, offset: pageParam } },
      });

      return data?.list || [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + query.limit;
    },
    enabled,
  });
}

const createHandbookRestrictionAdvantageRequirementsMutation = () =>
  $api.useMutation('post', '/handbook/restriction-advantage-requirements');
// const createHandbookRestrictionAdvantageRequirements = (
//   body: bodyRequestType<'post', '/handbook/restriction-advantage-requirements'>
// ) => createHandbookRestrictionAdvantageRequirementsMutation.mutateAsync({ body: body });

const deleteByIdHandbookRestrictionAdvantageRequirements = (
  id: ParametersPathType<'delete', '/handbook/restriction-advantage-requirements/{id}'>['id']
) =>
  $api
    .useMutation('delete', '/handbook/restriction-advantage-requirements/{id}')
    .mutateAsync({ params: { path: { id: id } } });

const useGetByIdHandbookRestrictionAdvantageRequirements = (
  id: ParametersPathType<'get', '/handbook/restriction-advantage-requirements/{id}'>['id']
) =>
  $api.useQuery('get', '/handbook/restriction-advantage-requirements/{id}', {
    params: { path: { id: id } },
  });

const updateHandbookRestrictionAdvantageRequirementsMutation = () =>
  $api.useMutation('put', '/handbook/restriction-advantage-requirements/{id}');
// const updateHandbookRestrictionAdvantageRequirements = (
//   id: ParametersPathType<'put', '/handbook/restriction-advantage-requirements/{id}'>['id'],
//   body: bodyRequestType<'put', '/handbook/restriction-advantage-requirements/{id}'>
// ) =>
//   updateHandbookRestrictionAdvantageRequirementsMutation.mutateAsync({
//     body: body,
//     params: { path: { id: id } },
//   });

export const $restrictionAdvantageRequirementsHooks = {
  useGetAll: useGetHandbookRestrictionAdvantageRequirementsInfiniteQuery,
  useGetById: useGetByIdHandbookRestrictionAdvantageRequirements,
  createMutation: createHandbookRestrictionAdvantageRequirementsMutation,
  updateByIdMutation: updateHandbookRestrictionAdvantageRequirementsMutation,
  deleteById: deleteByIdHandbookRestrictionAdvantageRequirements,
};
