import { yupResolver } from '@hookform/resolvers/yup';
import { ReactNode } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';

import { useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview } from '@shared/ui';

import { DEFAULT_VALUES } from '../constants';
import { OPTIONS_MOCK, PurchaseObjectsSchema, purchaseObjectsSchema } from '../model';

interface PurchaseObjectsFormProps {
  onValid?: SubmitHandler<PurchaseObjectsSchema>;
  onInvalid?: SubmitErrorHandler<PurchaseObjectsSchema>;
  footer?: ReactNode;
}

export function PurchaseObjectsForm({
  onValid = () => {},
  onInvalid,
  footer,
}: PurchaseObjectsFormProps) {
  const purchaseObjectsForm = useForm<PurchaseObjectsSchema>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(purchaseObjectsSchema),
    mode: 'all',
  });

  const { createFieldRef, isFieldEditable, selectField, resetSelectedField, selectedFieldPath } =
    useFieldsWithPreview({
      form: purchaseObjectsForm,
      initialEditable: true,
    });

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={purchaseObjectsForm.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        <FieldWithPreview.Text
          ref={createFieldRef('object')}
          control={purchaseObjectsForm.control}
          path="object"
          name="Товар"
          edit={isFieldEditable('object')}
          onFieldSelect={() => selectField('object')}
          required
        />
        <FieldWithPreview.Select
          ref={createFieldRef('okpd_2_value')}
          control={purchaseObjectsForm.control}
          path="okpd_2_value"
          name="Поставщик"
          edit={isFieldEditable('okpd_2_value')}
          onFieldSelect={() => selectField('okpd_2_value')}
          options={OPTIONS_MOCK['okpd_2_value']}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('supplier_id')}
          control={purchaseObjectsForm.control}
          path="supplier_id"
          name="Поставщик"
          edit={isFieldEditable('supplier_id')}
          onFieldSelect={() => selectField('supplier_id')}
          options={OPTIONS_MOCK['supplier_id']}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
          }}
          required
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('comment')}
          control={purchaseObjectsForm.control}
          path="comment"
          name="Комментарий"
          edit={isFieldEditable('comment')}
          onFieldSelect={() => selectField('comment')}
          wrapperProps={{ textAlign: 'start' }}
        />
      </FieldWithPreview>
      {footer}
    </form>
  );
}
