import {
  ArrowsOppositeToDots,
  Bug,
  CircleQuestion,
  // Cubes3,
  // DiamondExclamation,
  // Folder,
  // GraphNode,
  Wrench,
} from '@gravity-ui/icons';
import { AsideHeader, FooterItem } from '@gravity-ui/navigation';
import { Button, Icon, Text } from '@gravity-ui/uikit';
import { useAtom, useAtomValue } from 'jotai';
import { Fragment, ReactNode, useRef, useState } from 'react';
import { NavigateOptions, Path, useLocation } from 'react-router-dom';

import MainLogoCompact from '@shared/assets/icons/main-logo-compact.svg?react';
import MainLogo from '@shared/assets/icons/main-logo.svg?react';
import { URLS } from '@shared/consts';
import { cn, useNavigateTo } from '@shared/lib';
import { compactAtom, themeAtomLocalStorage } from '@shared/model';
import { ExpandList } from '@shared/ui';

import './NavigationSidebar.sass';

export function NavigationSidebar({ children }: { children: ReactNode }) {
  const { navigateTo } = useNavigateTo();
  function changeLocation(path: string, to?: Partial<Path>, options?: NavigateOptions) {
    setCurrentLocation(path);
    navigateTo(path, to, options);
    staticLocationPathname = path;
  }
  const ref = useRef<HTMLDivElement>(null);

  const theme = useAtomValue(themeAtomLocalStorage);

  let staticLocationPathname = useLocation().pathname;
  const [currentLocation, setCurrentLocation] = useState(staticLocationPathname);

  const [compact, setCompact] = useAtom(compactAtom);

  return (
    <AsideHeader
      ref={ref}
      qa={'ah-aside'}
      logo={{
        text: compact
          ? ''
          : () => (
              <Icon
                data={MainLogo}
                className={cn({
                  'b2g-logo_dark-theme': theme === 'dark',
                })}
              />
            ),
        icon: compact ? MainLogoCompact : '',
        'aria-label': 'B2G CRM',
        className: cn({
          'b2g-logo_container': compact,
          'b2g-logo_container not-compact': !compact,
        }),
        iconClassName: cn('b2g-logo w-[38px] h-[24px]', {
          'b2g-logo_dark-theme': theme === 'dark',
        }),
        onClick: e => {
          e.preventDefault();
          navigateTo('/');
        },
      }}
      menuItems={[]}
      subheaderItems={[
        {
          item: {
            id: 'navigation__processes',
            title: (
              <Button
                size="l"
                view="flat"
              >
                Процессы
              </Button>
            ),
            onItemClick: () => {
              setCompact(false);
              setCurrentLocation(URLS.processes.default);
            },
            icon: () => (
              <Icon
                data={ArrowsOppositeToDots}
                size={20}
                className={cn({
                  'text-line-brand': staticLocationPathname.includes(URLS.processes.default),
                })}
              />
            ),
            // @ts-expect-error баг в импорте айтема в либе, "@gravity-ui/navigation": "^2.16.0"
            itemWrapper(params, makeItem, { collapsed, compact }) {
              return !collapsed && !compact ? (
                <ExpandList
                  className="w-full px-2"
                  alternativeIconColor={staticLocationPathname.includes(URLS.processes.default)}
                  items={[
                    {
                      textProps: { className: 'inline-block w-[116px] text-left ml-4' },
                      buttonProps: { width: undefined },
                      expanded: currentLocation.includes(URLS.processes.default),
                      onUpdate: expanded => {
                        setCurrentLocation(expanded ? URLS.processes.default : '');
                      },
                      keepMounted: true,
                      iconProps: {
                        data: ArrowsOppositeToDots,
                      },
                      title: 'Процессы',
                      listItem: (
                        <div className="w-full">
                          <Button
                            size="l"
                            view="flat"
                            width="max"
                            className="justify-start pl-[52px]"
                            onClick={() => changeLocation(URLS.processes.commercialOffers)}
                            selected={staticLocationPathname === URLS.processes.commercialOffers}
                          >
                            <Text className="text-left inline-block w-full">КП</Text>
                          </Button>
                          <Button
                            size="l"
                            view="flat"
                            width="max"
                            className="justify-start pl-[52px]"
                            onClick={() => changeLocation(URLS.processes.tenders)}
                            selected={staticLocationPathname === URLS.processes.tenders}
                          >
                            <Text className="text-left inline-block w-full">Торги</Text>
                          </Button>
                          <Button
                            size="l"
                            view="flat"
                            width="max"
                            className="justify-start pl-[52px]"
                            onClick={() => changeLocation(URLS.processes.contracts)}
                            selected={staticLocationPathname === URLS.processes.contracts}
                          >
                            <Text className="text-left inline-block w-full">Контракты</Text>
                          </Button>
                          <Button
                            size="l"
                            view="flat"
                            width="max"
                            className="justify-start pl-[52px]"
                            onClick={() => changeLocation(URLS.processes.agreements)}
                            selected={staticLocationPathname === URLS.processes.agreements}
                          >
                            <Text className="text-left inline-block w-full">Согласования</Text>
                          </Button>
                        </div>
                      ),
                    },
                  ]}
                />
              ) : (
                makeItem(params)
              );
            },
          },
        },
        {
          item: {
            id: 'navigation__handling',
            title: (
              <Button
                size="l"
                view="flat"
              >
                Управление
              </Button>
            ),
            onItemClick: () => {
              setCompact(false);
              setCurrentLocation(URLS.handling.default);
            },
            icon: () => (
              <Icon
                data={Wrench}
                size={20}
                className={cn({
                  'text-line-brand': staticLocationPathname.includes(URLS.handling.default),
                })}
              />
            ),
            // @ts-expect-error баг в импорте айтема в либе, "@gravity-ui/navigation": "^2.16.0"
            itemWrapper(params, makeItem, { collapsed, compact }) {
              return !collapsed && !compact ? (
                <ExpandList
                  className="w-full px-2"
                  alternativeIconColor={staticLocationPathname.includes(URLS.handling.default)}
                  items={[
                    {
                      textProps: { className: 'inline-block w-[116px] text-left ml-4' },
                      buttonProps: { width: undefined },
                      expanded: currentLocation.includes(URLS.handling.default),
                      onUpdate: expanded => {
                        setCurrentLocation(expanded ? URLS.handling.default : '');
                      },
                      keepMounted: true,
                      iconProps: {
                        data: Wrench,
                      },
                      title: 'Управление',
                      listItem: (
                        <div className="w-full">
                          {/* <Button
                            size="l"
                            view="flat"
                            width="max"
                            className="justify-start pl-[52px]"
                            onClick={() =>
                              changeLocation('/processes/commercial-offers')
                            }
                            selected={
                              currentLocation === '/processes/commercial-offers'
                            }
                          >
                            <Text className="text-left inline-block w-full">КП</Text>
                          </Button>
                          <Button
                            size="l"
                            view="flat"
                            width="max"
                            className="justify-start pl-[52px]"
                            onClick={() => changeLocation('/processes/tenders')}
                            selected={currentLocation === '/processes/tenders'}
                          >
                            <Text className="text-left inline-block w-full">Торги</Text>
                          </Button>
                          <Button
                            size="l"
                            view="flat"
                            width="max"
                            className="justify-start pl-[52px]"
                            onClick={() => changeLocation('/processes/contracts')}
                            selected={currentLocation === '/processes/contracts'}
                          >
                            <Text className="text-left inline-block w-full">
                              Контракты
                            </Text>
                          </Button> */}
                        </div>
                      ),
                    },
                  ]}
                />
              ) : (
                makeItem(params)
              );
            },
          },
        },
        // {
        //   item: {
        //     id: 'navigation__guides',
        //     title: (
        //       <Button
        //         size="l"
        //         view="flat"
        //       >
        //         Справочники
        //       </Button>
        //     ),
        //     onItemClick: () => {
        //       setCompact(false);
        //       setCurrentLocation('/guides');
        //     },
        //     icon: () => (
        //       <Icon
        //         data={GraphNode}
        //         size={20}
        //         className={staticLocationPathname.includes('/guides') ? 'text-line-brand' : ''}
        //       />
        //     ),
        //     // onItemClick: () => {
        //     //   setVisiblePanel(undefined);
        //     //   setSubheaderPopupVisible(!subheaderPopupVisible);
        //     // },
        //     // @ts-ignore баг в импорте айтема в либе, "@gravity-ui/navigation": "^2.16.0"
        //     itemWrapper(params, makeItem, { collapsed, compact }) {
        //       return !collapsed && !compact ? (
        //         <ExpandList
        //           className="w-full px-2"
        //           alternativeIconColor={staticLocationPathname.includes('/guides')}
        //           items={[
        //             {
        //               iconProps: {
        //                 data: GraphNode,
        //               },
        //               expanded: currentLocation.includes('/guides'),
        //               onUpdate(expanded) {
        //                 expanded ? setCurrentLocation('/guides') : setCurrentLocation('');
        //               },
        //               keepMounted: true,
        //               title: 'Справочники',
        //               listItem: (
        //                 <div className="w-full">
        //                   {/* <Button
        //                     size="l"
        //                     view="flat"
        //                     width="max"
        //                     className="justify-start pl-[52px]"
        //                     onClick={() =>
        //                       changeLocation('/processes/commercial-offers')
        //                     }
        //                     selected={
        //                       currentLocation === '/processes/commercial-offers'
        //                     }
        //                   >
        //                     <Text className="text-left inline-block w-full">КП</Text>
        //                   </Button>
        //                   <Button
        //                     size="l"
        //                     view="flat"
        //                     width="max"
        //                     className="justify-start pl-[52px]"
        //                     onClick={() => changeLocation('/processes/tenders')}
        //                     selected={currentLocation === '/processes/tenders'}
        //                   >
        //                     <Text className="text-left inline-block w-full">Торги</Text>
        //                   </Button>
        //                   <Button
        //                     size="l"
        //                     view="flat"
        //                     width="max"
        //                     className="justify-start pl-[52px]"
        //                     onClick={() => changeLocation('/processes/contracts')}
        //                     selected={currentLocation === '/processes/contracts'}
        //                   >
        //                     <Text className="text-left inline-block w-full">
        //                       Контракты
        //                     </Text>
        //                   </Button> */}
        //                 </div>
        //               ),
        //             },
        //           ]}
        //         />
        //       ) : (
        //         makeItem(params)
        //       );
        //     },
        //   },
        // },

        // {
        //   item: {
        //     id: 'navigation__data-catalog',
        //     title: (
        //       <Button
        //         size="l"
        //         view="flat"
        //       >
        //         Каталог данных
        //       </Button>
        //     ),
        //     icon: () => (
        //       <Icon
        //         data={Cubes3}
        //         size={20}
        //         className={
        //           staticLocationPathname.includes('/data-catalog') ? 'text-line-brand' : ''
        //         }
        //       />
        //     ),
        //     onItemClick: () => {
        //       setCompact(false);
        //       setCurrentLocation('/data-catalog');
        //     },
        //     // @ts-ignore баг в импорте айтема в либе, "@gravity-ui/navigation": "^2.16.0"
        //     itemWrapper(params, makeItem, { collapsed, compact }) {
        //       return !collapsed && !compact ? (
        //         <ExpandList
        //           className="w-full px-2"
        //           alternativeIconColor={staticLocationPathname.includes('/data-catalog')}
        //           items={[
        //             {
        //               expanded: currentLocation.includes('/data-catalog'),
        //               onUpdate(expanded) {
        //                 expanded ? setCurrentLocation('/data-catalog') : setCurrentLocation('');
        //               },
        //               keepMounted: true,
        //               iconProps: {
        //                 data: Cubes3,
        //               },
        //               title: 'Каталог данных',
        //               listItem: (
        //                 <div className="w-full">
        //                   {/* <Button
        //                     size="l"
        //                     view="flat"
        //                     width="max"
        //                     className="justify-start pl-[52px]"
        //                     onClick={() =>
        //                       changeLocation('/processes/commercial-offers')
        //                     }
        //                     selected={
        //                       currentLocation === '/processes/commercial-offers'
        //                     }
        //                   >
        //                     <Text className="text-left inline-block w-full">КП</Text>
        //                   </Button>
        //                   <Button
        //                     size="l"
        //                     view="flat"
        //                     width="max"
        //                     className="justify-start pl-[52px]"
        //                     onClick={() => changeLocation('/processes/tenders')}
        //                     selected={currentLocation === '/processes/tenders'}
        //                   >
        //                     <Text className="text-left inline-block w-full">Торги</Text>
        //                   </Button>
        //                   <Button
        //                     size="l"
        //                     view="flat"
        //                     width="max"
        //                     className="justify-start pl-[52px]"
        //                     onClick={() => changeLocation('/processes/contracts')}
        //                     selected={currentLocation === '/processes/contracts'}
        //                   >
        //                     <Text className="text-left inline-block w-full">
        //                       Контракты
        //                     </Text>
        //                   </Button> */}
        //                 </div>
        //               ),
        //             },
        //           ]}
        //         />
        //       ) : (
        //         makeItem(params)
        //       );
        //     },
        //   },
        // },
        // {
        //   item: {
        //     id: 'navigation__in-development',
        //     title: (
        //       <Button
        //         size="l"
        //         view="flat"
        //       >
        //         В разработке
        //       </Button>
        //     ),
        //     onItemClick: () => {
        //       setCompact(false);
        //       setCurrentLocation('/in-development');
        //     },
        //     icon: () => (
        //       <Icon
        //         data={DiamondExclamation}
        //         size={20}
        //         className={
        //           staticLocationPathname.includes('/in-development') ? 'text-line-brand' : ''
        //         }
        //       />
        //     ),
        //     // @ts-ignore баг в импорте айтема в либе, "@gravity-ui/navigation": "^2.16.0"
        //     itemWrapper(params, makeItem, { collapsed, compact }) {
        //       return !collapsed && !compact ? (
        //         <ExpandList
        //           className="w-full px-2"
        //           alternativeIconColor={staticLocationPathname.includes('/in-development')}
        //           items={[
        //             {
        //               expanded: currentLocation.includes('/in-development'),
        //               onUpdate(expanded) {
        //                 expanded ? setCurrentLocation('/in-development') : setCurrentLocation('');
        //               },
        //               keepMounted: true,
        //               iconProps: {
        //                 data: DiamondExclamation,
        //               },
        //               title: 'В разработке',
        //               listItem: (
        //                 <div className="w-full">
        //                   {/* <Button
        //                     size="l"
        //                     view="flat"
        //                     width="max"
        //                     className="justify-start pl-[52px]"
        //                     onClick={() =>
        //                       changeLocation('/processes/commercial-offers')
        //                     }
        //                     selected={
        //                       currentLocation === '/processes/commercial-offers'
        //                     }
        //                   >
        //                     <Text className="text-left inline-block w-full">КП</Text>
        //                   </Button>
        //                   <Button
        //                     size="l"
        //                     view="flat"
        //                     width="max"
        //                     className="justify-start pl-[52px]"
        //                     onClick={() => changeLocation('/processes/tenders')}
        //                     selected={currentLocation === '/processes/tenders'}
        //                   >
        //                     <Text className="text-left inline-block w-full">Торги</Text>
        //                   </Button>
        //                   <Button
        //                     size="l"
        //                     view="flat"
        //                     width="max"
        //                     className="justify-start pl-[52px]"
        //                     onClick={() => changeLocation('/processes/contracts')}
        //                     selected={currentLocation === '/processes/contracts'}
        //                   >
        //                     <Text className="text-left inline-block w-full">
        //                       Контракты
        //                     </Text>
        //                   </Button> */}
        //                 </div>
        //               ),
        //             },
        //           ]}
        //         />
        //       ) : (
        //         makeItem(params)
        //       );
        //     },
        //   },
        // },
        // {
        //   item: {
        //     id: 'navigation__archive-processes',
        //     title: (
        //       <Button
        //         size="l"
        //         view="flat"
        //       >
        //         Архив процессов
        //       </Button>
        //     ),
        //     onItemClick: () => {
        //       setCompact(false);
        //       setCurrentLocation('/archive-processes');
        //     },
        //     icon: () => (
        //       <Icon
        //         data={Folder}
        //         size={20}
        //         className={
        //           staticLocationPathname.includes('/archive-processes') ? 'text-line-brand' : ''
        //         }
        //       />
        //     ),
        //     // @ts-ignore баг в импорте айтема в либе, "@gravity-ui/navigation": "^2.16.0"
        //     itemWrapper(params, makeItem, { collapsed, compact }) {
        //       return !collapsed && !compact ? (
        //         <ExpandList
        //           className="w-full px-2"
        //           alternativeIconColor={staticLocationPathname.includes('/archive-processes')}
        //           items={[
        //             {
        //               expanded: currentLocation.includes('/archive-processes'),
        //               onUpdate(expanded) {
        //                 expanded
        //                   ? setCurrentLocation('/archive-processes')
        //                   : setCurrentLocation('');
        //               },
        //               keepMounted: true,
        //               iconProps: {
        //                 data: Folder,
        //               },
        //               title: 'Архив процессов',
        //               listItem: (
        //                 <div className="w-full">
        //                   {/* <Button
        //                     size="l"
        //                     view="flat"
        //                     width="max"
        //                     className="justify-start pl-[52px]"
        //                     onClick={() =>
        //                       changeLocation('/processes/commercial-offers')
        //                     }
        //                     selected={
        //                       currentLocation === '/processes/commercial-offers'
        //                     }
        //                   >
        //                     <Text className="text-left inline-block w-full">КП</Text>
        //                   </Button>
        //                   <Button
        //                     size="l"
        //                     view="flat"
        //                     width="max"
        //                     className="justify-start pl-[52px]"
        //                     onClick={() => changeLocation('/processes/tenders')}
        //                     selected={currentLocation === '/processes/tenders'}
        //                   >
        //                     <Text className="text-left inline-block w-full">Торги</Text>
        //                   </Button>
        //                   <Button
        //                     size="l"
        //                     view="flat"
        //                     width="max"
        //                     className="justify-start pl-[52px]"
        //                     onClick={() => changeLocation('/processes/contracts')}
        //                     selected={currentLocation === '/processes/contracts'}
        //                   >
        //                     <Text className="text-left inline-block w-full">
        //                       Контракты
        //                     </Text>
        //                   </Button> */}
        //                 </div>
        //               ),
        //             },
        //           ]}
        //         />
        //       ) : (
        //         makeItem(params)
        //       );
        //     },
        //   },
        // },
      ]}
      compact={compact}
      renderFooter={({ compact }) => (
        <div id="navigation-footer">
          <FooterItem
            compact={compact}
            enableTooltip={true}
            item={{
              id: 'navigation__handbook',
              title: (
                <Button
                  size="l"
                  view="flat"
                >
                  Справочник
                </Button>
              ),
              onItemClick: () => {
                changeLocation(URLS.handbook.default);
              },
              icon: () => (
                <Icon
                  data={CircleQuestion}
                  size={20}
                  className={cn({
                    'text-line-brand': staticLocationPathname.startsWith(URLS.handbook.default),
                  })}
                />
              ),
              itemWrapper(params, makeItem, { collapsed, compact }) {
                return !collapsed && !compact ? (
                  <Button
                    size="l"
                    view="flat"
                    width="max"
                    pin="brick-brick"
                    className="justify-start pr-0 pl-4"
                    onClick={() => changeLocation(URLS.handbook.default)}
                    selected={staticLocationPathname.startsWith(URLS.handbook.default)}
                  >
                    <Icon
                      size={20}
                      data={CircleQuestion}
                      className={cn({
                        'text-line-brand': staticLocationPathname.startsWith(URLS.handbook.default),
                      })}
                    />
                    <Text className="text-left inline-block w-full ml-4">Справочник</Text>
                  </Button>
                ) : (
                  makeItem(params)
                );
              },
            }}
          />
          <FooterItem
            compact={compact}
            enableTooltip={true}
            item={{
              id: 'navigation__bug-report',
              title: (
                <Button
                  size="l"
                  view="flat"
                >
                  Сообщить об ошибке
                </Button>
              ),
              onItemClick: () => {
                changeLocation(URLS.bugReport);
              },
              icon: () => (
                <Icon
                  data={Bug}
                  size={20}
                  className={cn({
                    'text-line-brand': staticLocationPathname === URLS.bugReport,
                  })}
                />
              ),
              itemWrapper(params, makeItem, { collapsed, compact }) {
                return !collapsed && !compact ? (
                  <Button
                    size="l"
                    view="flat"
                    width="max"
                    pin="brick-brick"
                    className="justify-start pr-0 pl-4"
                    onClick={() => changeLocation(URLS.bugReport)}
                    selected={staticLocationPathname === URLS.bugReport}
                  >
                    <Icon
                      size={20}
                      data={Bug}
                      className={cn({
                        'text-line-brand': staticLocationPathname === URLS.bugReport,
                      })}
                    />
                    <Text className="text-left inline-block w-full ml-4">Сообщить об ошибке</Text>
                  </Button>
                ) : (
                  makeItem(params)
                );
              },
            }}
          />
        </div>
      )}
      renderContent={() => {
        return <Fragment>{children}</Fragment>;
      }}
      onChangeCompact={v => {
        setCompact(v);
      }}
    />
  );
}
