import { Comments } from '@gravity-ui/icons';
import { Alert, Button, Icon, RadioButton, Spin, Text, useToaster } from '@gravity-ui/uikit';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

import {
  $agreementHooks,
  convertAgreementResultStatusFromBackend,
  convertAgreementTypeFromBackend,
  userAtom,
} from '@entities';
import { AgreementSchema } from '@features';
// import { percentAndCurrencyValuesToBackend as percentAndCurrencyValuesToBackendCommercialOffers } from '@widgets/commercial-offer-drawer/lib';
import { ContractRelations } from '@widgets/contracts-drawer/ui/ContractRelations';
// import { percentAndCurrencyValuesToBackend as percentAndCurrencyValuesToBackendTenders } from '@widgets/tender-drawer/lib';
import { bodyResponseType } from '@shared/api';
import { URLS } from '@shared/consts';
import { ENVIRONMENT } from '@shared/consts/environment';
import { calculateMargin, useNavigateTo } from '@shared/lib';
import { RightSidebar } from '@shared/ui';

import {
  checkIsNotBlankRelations,
  countRelations,
  percentAndCurrencyValuesFromBackend,
  percentAndCurrencyValuesToBackend,
} from '../lib';
import { AgreementLayout } from './AgreementLayout';

interface EditAgreementProps {
  id: string | undefined;
  onClose: () => void;
}

function fromCoordinationToAgreementSchema(
  data: bodyResponseType<'get', '/coordination/{id}'>
): AgreementSchema {
  return {
    ...data,
    entity_type: data.tender ? 'TENDER' : 'OFFER',
    entity_id: data.entity_id,
    assigned_by_id: data.assigned_by_id,
    assigned_to_id: data.assigned_to_id,
    coordination_type: data.coordination_type,
    title: data.tender ? data.tender.title : data.offer ? data.offer.title : '',
    entity_card: data.tender
      ? `${ENVIRONMENT.URL + URLS.processes.tenders}/${data.tender.id}`
      : data.offer
        ? `${ENVIRONMENT.URL + URLS.processes.commercialOffers}/${data.offer.id}`
        : '',
    customer_id:
      data.tender && data.tender.customer_ids?.[0] && data.tender.customer_values?.[0]
        ? data.tender.customer_ids[0]
        : data.offer && data.offer.customer_id
          ? data.offer.customer_id
          : '',
    cost_price:
      typeof data.tender?.base_cost_price === 'number'
        ? data.tender.base_cost_price
        : typeof data.offer?.cost_price === 'number'
          ? data.offer.cost_price
          : undefined,
    specialist_cost_price:
      typeof data.tender?.specialist_min_cost_price === 'number'
        ? data.tender.specialist_min_cost_price
        : typeof data.offer?.proposed_price === 'number' && data.offer?.purpose === 'Закупка ЕП'
          ? data.offer.proposed_price
          : typeof data.offer?.offer_price === 'number' &&
              data.offer?.purpose === 'Обоснование НМЦК'
            ? data.offer?.offer_price
            : undefined,
    proposed_margin:
      typeof data.tender?.specialist_min_cost_price === 'number' &&
      typeof data.tender?.base_cost_price === 'number'
        ? calculateMargin(
            data.tender.specialist_min_cost_price / 100,
            data.tender.base_cost_price / 100
          ) * 100
        : typeof data.offer?.margin === 'number'
          ? data.offer.margin
          : undefined,
    price_justification:
      data.tender && data.tender.min_cost_price_reason
        ? data.tender.min_cost_price_reason
        : data.offer && data.offer.price_justification
          ? data.offer.price_justification
          : undefined,
    is_proposal_agree:
      data.status === 'PENDING'
        ? undefined
        : data.is_proposal_agree === true || data.is_proposal_agree === undefined
          ? 'Да'
          : 'Нет',
    planned_margin:
      typeof data.tender?.min_margin === 'number'
        ? data.tender.min_margin
        : typeof data.tender?.margin === 'number'
          ? data.tender?.margin
          : typeof data.offer?.margin === 'number'
            ? data.offer.margin
            : undefined,
    min_cost_price:
      typeof data.tender?.min_cost_price === 'number'
        ? data.tender.min_cost_price
        : typeof data.tender?.specialist_min_cost_price === 'number'
          ? data.tender?.specialist_min_cost_price
          : typeof data.offer?.proposed_price === 'number' && data.offer?.purpose === 'Закупка ЕП'
            ? data.offer.proposed_price
            : typeof data.offer?.offer_price === 'number' &&
                data.offer?.purpose === 'Обоснование НМЦК'
              ? data.offer?.offer_price
              : undefined,
    reject_reason: data.tender?.reject_reason
      ? data.tender.reject_reason
      : data.offer?.reject_reason
        ? data.offer.reject_reason
        : undefined,
    reject_description: data.tender?.reject_description
      ? data.tender.reject_description
      : data.offer?.reject_description
        ? data.offer.reject_description
        : undefined,
    calculation_file: data?.tender?.calculation_files?.length
      ? data.tender.calculation_files
      : data?.offer?.calculation_file
        ? [data.offer.calculation_file]
        : [],
    offer_file: data.offer?.offer_file ? data.offer.offer_file : '',
    request_file: data.tender?.request_file ? data.tender.request_file : '',
  };
}

export function EditAgreement({ id, onClose }: EditAgreementProps) {
  const [isOpen, setIsOpen] = useState(false);

  const user = useAtomValue(userAtom);

  const [activeTab, setActiveTab] = useState<'agreement' | 'relations'>('agreement');

  const { navigateTo } = useNavigateTo();
  const { add } = useToaster();

  // Queries
  const getAgreementQuery = $agreementHooks.getById(id || '', !!id);

  const updateAgreementMutation = $agreementHooks.update();

  useEffect(() => {
    if (getAgreementQuery.error) {
      navigateTo(URLS.processes.agreements);
      add({
        theme: 'danger',
        name: 'get-agreement-error',
        title: 'Произошла ошибка при получении согласования',
      });
    }
  }, [getAgreementQuery.isFetching]);

  useEffect(() => {
    // Уведомления об обновлении карточки КП
    if (updateAgreementMutation.isSuccess) {
      add({
        theme: 'success',
        name: 'update-agreement-success',
        title: 'Карточка Согласования обновлена успешно',
      });
    }
    if (updateAgreementMutation.isError) {
      add({
        theme: 'danger',
        name: 'update-agreement-error',
        title: 'Ошибка при обновлении карточки Согласования',
      });
    }
  }, [updateAgreementMutation.isPending]);

  useEffect(() => {
    if (id) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [id]);

  return (
    <AgreementLayout
      formProps={{
        onClickCancelButtonValid: async values => {
          if (!id) return;
          const valuesToBackend = percentAndCurrencyValuesToBackend(values);
          await updateAgreementMutation
            .mutateAsync({
              body: {
                comment: valuesToBackend.comment,
                id,
                // price: valuesToBackend.min_cost_price,
                status: 'REJECTED',
                user_name: valuesToBackend.assigned_to_id || undefined,
              },
              params: {
                path: {
                  id,
                },
              },
            })
            .then(() => {
              onClose();
            });
        },
        onClickCancelButtonInvalid: errors => console.log('@cancel-errors, ', errors),
        disabled:
          !(getAgreementQuery.data?.status === 'PENDING') ||
          !!(user && !user?.is_admin && getAgreementQuery.data?.status === 'PENDING') ||
          !!(
            user &&
            user.id !== getAgreementQuery.data?.assigned_to_id &&
            getAgreementQuery.data?.status === 'PENDING'
          ),
        response: getAgreementQuery.data
          ? {
              ...getAgreementQuery.data,
              ...percentAndCurrencyValuesFromBackend(
                fromCoordinationToAgreementSchema(getAgreementQuery.data)
              ),
              is_proposal_agree:
                getAgreementQuery.data.status === 'PENDING'
                  ? undefined
                  : getAgreementQuery.data.is_proposal_agree === true ||
                      getAgreementQuery.data.is_proposal_agree === undefined
                    ? 'Да'
                    : 'Нет',
            }
          : undefined,
        initialValues: getAgreementQuery.data
          ? percentAndCurrencyValuesFromBackend(
              fromCoordinationToAgreementSchema(getAgreementQuery.data)
            )
          : undefined,
        onClickAgreeButtonValid: async values => {
          if (!id) return;
          const valuesToBackend = percentAndCurrencyValuesToBackend(values);
          await updateAgreementMutation
            .mutateAsync({
              body: {
                comment: valuesToBackend.comment,
                id,
                price:
                  valuesToBackend.coordination_type === 'MIN_PRICE' ||
                  valuesToBackend.coordination_type === 'OFFER_PRICE'
                    ? valuesToBackend.min_cost_price
                    : undefined,
                status: 'ACCEPTED',
                user_name: valuesToBackend.assigned_to_id || undefined,
                is_proposal_agree: valuesToBackend.is_proposal_agree === false ? false : true,
              },
              params: {
                path: {
                  id,
                },
              },
            })
            .then(() => {
              onClose();
            });

          // if (updateAgreementMutation.isSuccess) {
          //   add({ content: 'Согласовано', theme: 'success', name: 'success-agreement' });
          //   if (getAgreementQuery.data?.offer) {
          //     await updateCommercialOfferMutation.mutateAsync({
          //       params: { path: { id: getAgreementQuery.data.offer.id } },
          //       body: {
          //         data: {
          //           ...percentAndCurrencyValuesToBackendCommercialOffers(
          //             getAgreementQuery.data.offer
          //           ),
          //           offer_price:
          //             typeof valuesToBackend.min_cost_price === 'number'
          //               ? valuesToBackend.min_cost_price
          //               : getAgreementQuery.data.offer.offer_price,
          //           margin:
          //             typeof valuesToBackend.planned_margin === 'number'
          //               ? valuesToBackend.planned_margin
          //               : getAgreementQuery.data.offer.margin,
          //           is_under_approval: false,
          //           is_editable: true, // TODO: рассчитать
          //         },
          //         meta: { id: getAgreementQuery.data.offer.id, user_name: user?.full_name },
          //       },
          //     });
          //   }
          //   if (getAgreementQuery.data?.tender) {
          //     await updateTenderMutation.mutateAsync({
          //       body: {
          //         ...percentAndCurrencyValuesToBackendTenders(getAgreementQuery.data.tender),
          //         min_margin: valuesToBackend.planned_margin,
          //         min_cost_price: valuesToBackend.min_cost_price,
          //         is_editable: true,
          //         is_under_approval: false,
          //       },
          //       params: {
          //         path: {
          //           id: getAgreementQuery.data.tender.id,
          //         },
          //       },
          //     });
          //   }
          // }
        },
        onClickAgreeButtonInvalid: errors => console.log('@agree-errors, ', errors),
      }}
      renderForm={formComponent => (
        <RightSidebar
          id="edit-agreement"
          onClose={() => {
            setIsOpen(false);
            onClose();
          }}
          open={isOpen}
        >
          <RightSidebar.Header
            title={
              getAgreementQuery.data?.offer?.title ||
              getAgreementQuery.data?.tender?.title ||
              'Просмотр согласования'
            }
            onClose={() => {
              setIsOpen(false);
              onClose();
            }}
          />
          <div className="px-8 pb-8 flex justify-between">
            <RadioButton
              size="l"
              onUpdate={setActiveTab}
              options={[
                { value: 'agreement', content: 'О согласовании' },
                {
                  value: 'relations',
                  content: (
                    <span>
                      Связи
                      <Text
                        variant="body-1"
                        color="hint"
                        className="ml-1"
                      >
                        {countRelations(getAgreementQuery.data)}
                      </Text>
                    </span>
                  ),
                },
              ]}
            />
            <Button
              size="l"
              onClick={() =>
                navigateTo(
                  URLS.messenger +
                    '/' +
                    (getAgreementQuery.data?.offer?.chat_id ||
                      getAgreementQuery.data?.tender?.chat_id ||
                      '')
                )
              }
            >
              <Icon data={Comments} />
              Чат
            </Button>
          </div>
          {activeTab === 'agreement' && getAgreementQuery.data && (
            <Alert
              layout="horizontal"
              corners="square"
              theme={
                getAgreementQuery.data.status === 'REJECTED'
                  ? 'danger'
                  : getAgreementQuery.data.status === 'ACCEPTED'
                    ? 'success'
                    : 'normal'
              }
              title={
                convertAgreementTypeFromBackend(getAgreementQuery.data.coordination_type) +
                ' / ' +
                convertAgreementResultStatusFromBackend(getAgreementQuery.data.status)
              }
              className="mb-4"
            />
          )}
          {activeTab === 'agreement' && formComponent}
          {activeTab === 'relations' && (
            <>
              {getAgreementQuery.isLoading && (
                <Spin
                  size="l"
                  className="mx-8"
                />
              )}
              {getAgreementQuery.isError && (
                <Text
                  className="mx-8"
                  color="danger-heavy"
                  variant="body-2"
                >
                  Ошибка загрузки связей
                </Text>
              )}
              {getAgreementQuery.data && checkIsNotBlankRelations(getAgreementQuery.data) ? (
                <ContractRelations relations={getAgreementQuery.data} />
              ) : (
                countRelations(getAgreementQuery.data) === 0 && (
                  <Text
                    className="mx-8 font-semibold"
                    variant="body-2"
                  >
                    Связей не обнаружено
                  </Text>
                )
              )}
            </>
          )}
        </RightSidebar>
      )}
    />
  );
}
