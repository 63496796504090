import { yupResolver } from '@hookform/resolvers/yup';
import { ReactNode } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';

import { useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview } from '@shared/ui';

import { DEFAULT_VALUES } from '../constants';
import { LegalEntitiesSchema, legalEntitiesSchema, OPTIONS_MOCK } from '../model';

interface LegalEntitiesFormProps {
  onValid?: SubmitHandler<LegalEntitiesSchema>;
  onInvalid?: SubmitErrorHandler<LegalEntitiesSchema>;
  footer?: ReactNode;
}

export function LegalEntitiesForm({
  onValid = () => {},
  onInvalid,
  footer,
}: LegalEntitiesFormProps) {
  const legalEntitiesForm = useForm<LegalEntitiesSchema>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(legalEntitiesSchema),
    mode: 'all',
  });

  const { createFieldRef, isFieldEditable, selectField, resetSelectedField, selectedFieldPath } =
    useFieldsWithPreview({
      form: legalEntitiesForm,
      initialEditable: true,
    });

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={legalEntitiesForm.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        <FieldWithPreview.Text
          ref={createFieldRef('name')}
          control={legalEntitiesForm.control}
          path="name"
          name="Наименование"
          edit={isFieldEditable('name')}
          onFieldSelect={() => selectField('name')}
          required
        />
        <FieldWithPreview.MultiSelect
          ref={createFieldRef('contact_ids')}
          control={legalEntitiesForm.control}
          path="contact_ids"
          edit={isFieldEditable('contact_ids')}
          options={OPTIONS_MOCK['contact_ids']}
          onFieldSelect={() => selectField('contact_ids')}
          name="Контакты"
          required
        />
        <FieldWithPreview.MultiFile
          ref={createFieldRef('files')}
          control={legalEntitiesForm.control}
          path="files"
          edit={isFieldEditable('files')}
          onFieldSelect={() => selectField('files')}
          name="Файлы"
        />
        <FieldWithPreview.Text
          ref={createFieldRef('inn')}
          control={legalEntitiesForm.control}
          path="inn"
          name="ИНН"
          edit={isFieldEditable('inn')}
          onFieldSelect={() => selectField('inn')}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('kpp')}
          control={legalEntitiesForm.control}
          path="kpp"
          name="КПП"
          edit={isFieldEditable('kpp')}
          onFieldSelect={() => selectField('kpp')}
          required
        />
        <FieldWithPreview.Select
          ref={createFieldRef('payment_detail_id')}
          control={legalEntitiesForm.control}
          path="payment_detail_id"
          edit={isFieldEditable('payment_detail_id')}
          options={OPTIONS_MOCK['payment_detail_id']}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          onFieldSelect={() => selectField('payment_detail_id')}
          name="Платежные реквизиты"
          required
        />
        <FieldWithPreview.Select
          ref={createFieldRef('purchase_object_id')}
          control={legalEntitiesForm.control}
          path="purchase_object_id"
          edit={isFieldEditable('purchase_object_id')}
          options={OPTIONS_MOCK['purchase_object_id']}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          onFieldSelect={() => selectField('purchase_object_id')}
          name="Объект поставок"
          required
        />
        <FieldWithPreview.Select
          ref={createFieldRef('region_id')}
          control={legalEntitiesForm.control}
          path="region_id"
          edit={isFieldEditable('region_id')}
          options={OPTIONS_MOCK['region_id']}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          onFieldSelect={() => selectField('region_id')}
          name="Регион"
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('website')}
          control={legalEntitiesForm.control}
          path="website"
          name="Сайт"
          edit={isFieldEditable('website')}
          onFieldSelect={() => selectField('website')}
        />
      </FieldWithPreview>
      {footer}
    </form>
  );
}
