import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { $agreementHooks, $tenderHooks } from '@entities';
import { getAgreementSubmitTender, TenderSchema } from '@features';
import { bodyResponseType } from '@shared/api';
import { fromCustomBooleanSchemaToBoolean } from '@shared/lib';
import { RightSidebar } from '@shared/ui';

import { percentAndCurrencyValuesToBackend } from '../lib';
import { tenderCreationAtom } from '../model';
import { TenderLayout } from './TenderLayout';

interface CreateTenderProps {
  open: boolean;
  onClose: () => void;
  afterCreate?: (createdTender: bodyResponseType<'post', '/tender/'>) => void;
  defaultValues?: Partial<TenderSchema>;
}

export function CreateTender({ open, onClose, afterCreate, defaultValues }: CreateTenderProps) {
  const { add } = useToaster();

  const [agreementType, setAgreementType] = useState<'Участия' | 'Минимальной цены' | ''>('');

  const queryClient = useQueryClient();

  const [tenderCreation, setTenderCreation] = useAtom(tenderCreationAtom);

  // Queries
  const createTenderMutation = $tenderHooks.create();

  const createAgreementMutation = $agreementHooks.create();
  //

  useEffect(() => {
    // Уведомления о создании карточки согласования
    if (createAgreementMutation.isSuccess) {
      add({
        theme: 'success',
        name: 'create-agreement-success',
        title: `Согласование ${agreementType} создано`,
      });
    }
    if (createAgreementMutation.isError) {
      add({
        theme: 'danger',
        name: 'create-agreement-error',
        title: `Не удалось создать Согласование ${agreementType}`,
      });
    }
  }, [createAgreementMutation.isPending]);

  return (
    <TenderLayout
      formProps={{
        defaultValues: defaultValues || tenderCreation,
        onValuesChange: values => {
          // console.log('prevTenderCreation:', tenderCreation, values);
          setTenderCreation(values);
        },
        hideTitleField: false,
        editTender: false,
        onClickCancelButton: () => {
          setTenderCreation(undefined);
          onClose();
        },
        submitButtonText: 'Создать',
        // disabled: underApproval,
        initialEditable: true,
        allowedStatuses: () => ['Подача'],
        allowedPurchaseStatuses: () => ['В процессе'],
        onValid: async values => {
          // function getCentralized(isCentrilized: IsCentralized | '' | undefined) {
          //   switch (isCentrilized) {
          //     case 'Да':
          //       return true;
          //     case 'Нет':
          //       return false;
          //     default:
          //       return undefined;
          //   }
          // }
          const createdTender = await createTenderMutation.mutateAsync({
            body: {
              ...values,
              ...percentAndCurrencyValuesToBackend(values),
              // is_blocked_work_params: getAgreementSubmitTender(values, false),
              is_centralized: fromCustomBooleanSchemaToBoolean(values.is_centralized),
              customer_priorities: values.customer_priorities
                ? [+values.customer_priorities]
                : undefined,
            },
          });
          if (createTenderMutation.isError) {
            return add({
              name: 'create-tender-error',
              theme: 'danger',
              content: 'Ошибка при создании карточки Торгов',
            });
          }
          add({
            name: 'create-tender-success',
            theme: 'success',
            content: 'Карточка Торгов успешна создана!',
          });
          queryClient.invalidateQueries({ queryKey: ['/tender/all'] });

          afterCreate?.(createdTender);

          if (getAgreementSubmitTender(values, false)) {
            setAgreementType('Минимальной цены');
            await createAgreementMutation.mutateAsync({
              body: {
                assigned_to_id: createdTender?.responsible_supervisor_id,
                coordination_type: 'MIN_PRICE',
                entity_id: createdTender.id,
                entity_type: 'TENDER',
              },
            });
          } else {
            setAgreementType('Участия');
            await createAgreementMutation.mutateAsync({
              body: {
                assigned_to_id: createdTender?.responsible_supervisor_id,
                coordination_type: 'PARTICIPATION',
                entity_id: createdTender.id,
                entity_type: 'TENDER',
              },
            });
          }

          onClose();

          setTenderCreation(undefined);
          onClose();
        },
        // onInvalid: errors => {
        //   add({
        //     name: 'create-tender-invalid',
        //     content: 'Есть12312 незаполненные поля',
        //     theme: 'warning',
        //   });
        //   console.log('@errors', errors);
        // },
      }}
      renderForm={formComponent => (
        <RightSidebar
          open={open}
          onClose={onClose}
        >
          <RightSidebar.Header
            title="Создание торгов"
            onClose={onClose}
          />
          {formComponent}
        </RightSidebar>
      )}
    />
  );
}
