import { useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDebounce } from 'use-debounce';

import { $eTradingPlatformsHooks } from '@entities';
import {
  CRMTable,
  CRMTableColumns,
  CRMTableData,
  ETradingPlatformsHandbookForm,
  ETradingPlatformsHandbookSchema,
  eTradingPlatformsHandbookSchema,
  useTableColumnSort,
} from '@features';
import { $api, components } from '@shared/api';

import { E_TRADING_PLATFORMS_TABLE_COLUMNS_WITH_FILTER } from '../consts';
import { ColumnsETradingPlatformsHandbookTable, CustomHandbookTableDefaultProps } from '../model';
import { CustomerHandbookDrawerLayout } from './CustomHandbookDrawerLayout';
import { CustomHandbookTableLayout } from './CustomHandbookTableLayout';
import { HandbookFormFooter } from './HandbookFormFooter';

type ETradingPlatformsHandbookTableProps = CustomHandbookTableDefaultProps;

export function ETradingPlatformsHandbookTable({
  creationOpen,
  onCloseCreation,
}: ETradingPlatformsHandbookTableProps) {
  const { add } = useToaster();

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const getHandbookETradingPlatformsInfiniteQuery = $eTradingPlatformsHooks.useGetAll(
    {
      query: debouncedSearchValue,
      limit: 30,
    },
    true
  );

  const flattedPages = getHandbookETradingPlatformsInfiniteQuery.data?.pages.flat();

  const data: CRMTableData<ColumnsETradingPlatformsHandbookTable> = useMemo(
    () =>
      flattedPages?.map(eTradingPlatform => ({
        Ссылка: eTradingPlatform?.link || '',
        Комментарий: eTradingPlatform?.comment || '',
        Наименование: eTradingPlatform?.name || '',
      })) ?? [],
    [getHandbookETradingPlatformsInfiniteQuery.data]
  );

  const { columnsSort, changeColumnSort, updateColumnsSortValuesBySettings } =
    useTableColumnSort(data);

  const columnWithHover = (name: ColumnsETradingPlatformsHandbookTable[number]) => {
    const sortValue = columnsSort[name];
    if (typeof sortValue === 'undefined') {
      return name;
    }

    return (
      <CRMTable.HoverColumnWrapper
        sort={sortValue}
        iconPosition="end"
        content={name}
        columnName={name}
        onSortTypeChange={(newSort, colName) => changeColumnSort(newSort, colName)}
      />
    );
  };

  const columns: CRMTableColumns<ColumnsETradingPlatformsHandbookTable> = [
    {
      id: 'Наименование',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      name: () => columnWithHover('Наименование'),
    },
    {
      id: 'Ссылка',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      name: () => columnWithHover('Ссылка'),
    },
    {
      id: 'Комментарий',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      className: 'max-w-[250px] truncate',
      name: () => columnWithHover('Комментарий'),
      template: item => <span title={item['Комментарий']}>{item['Комментарий']}</span>,
    },
  ];

  const [editableHandbook, setEditableHandbook] = useState<
    components['schemas']['handbook.ETradingPlatform'] | null
  >(null);

  const postHandbookETradingPlatformsMutation = $api.useMutation(
    'post',
    '/handbook/e-trading-platforms'
  );

  const createETradingPlatformsHandbookForm = useForm<ETradingPlatformsHandbookSchema>({
    resolver: yupResolver(eTradingPlatformsHandbookSchema),
    mode: 'all',
    defaultValues: {
      comment: '',
      link: '',
      name: '',
    },
  });

  console.log(createETradingPlatformsHandbookForm.getValues());

  const onValidCreateHandbook = async (values: ETradingPlatformsHandbookSchema) => {
    try {
      await postHandbookETradingPlatformsMutation.mutateAsync({ body: values });
      getHandbookETradingPlatformsInfiniteQuery.refetch();
      createETradingPlatformsHandbookForm.reset();
      add({
        name: 'eTradingPlatforms-create-success',
        theme: 'success',
        title: `Справочник успешно создан`,
      });
      onCloseCreation();
    } catch {
      add({
        name: 'eTradingPlatforms-create-failed',
        theme: 'danger',
        title: `Не удалось создать справочник`,
      });
    }
  };

  const putHandbookETradingPlatformsIdMutation = $api.useMutation(
    'put',
    '/handbook/e-trading-platforms/{id}'
  );

  const editETradingPlatformsHandbookForm = useForm<ETradingPlatformsHandbookSchema>({
    resolver: yupResolver(eTradingPlatformsHandbookSchema),
    values: editableHandbook?.name
      ? { ...editableHandbook, name: editableHandbook.name }
      : undefined,
    mode: 'all',
  });

  const isEditETradingPlatformsHandbookFormDirty =
    editETradingPlatformsHandbookForm.formState.isDirty;

  const handleEditHandbook = () => {
    editETradingPlatformsHandbookForm.handleSubmit(async values => {
      if (editableHandbook && isEditETradingPlatformsHandbookFormDirty) {
        try {
          await putHandbookETradingPlatformsIdMutation.mutateAsync({
            body: values,
            params: { path: { id: editableHandbook.id || 0 } },
          });
          add({
            name: 'eTradingPlatforms-create-success',
            theme: 'success',
            title: `Справочник успешно изменен`,
          });
          getHandbookETradingPlatformsInfiniteQuery.refetch();
        } catch {
          add({
            name: 'eTradingPlatforms-create-failed',
            theme: 'danger',
            title: `Не удалось изменить справочник`,
          });
        }
      }
    })();
    setEditableHandbook(null);
  };

  const deleteHandbookETradingPlatformsIdMutation = $api.useMutation(
    'delete',
    '/handbook/e-trading-platforms/{id}'
  );

  return (
    <>
      <CustomHandbookTableLayout
        columns={columns}
        data={data}
        tableColumnsWithFilter={E_TRADING_PLATFORMS_TABLE_COLUMNS_WITH_FILTER}
        searchValue={searchValue}
        onSearchValueUpdate={setSearchValue}
        updateColumnsSortValuesBySettings={updateColumnsSortValuesBySettings}
        onRowClick={(_, index) => setEditableHandbook(flattedPages?.[index] ?? null)}
        onIntersecting={(() => {
          let retry = false;

          return () => {
            if (
              getHandbookETradingPlatformsInfiniteQuery.isLoading ||
              getHandbookETradingPlatformsInfiniteQuery.isFetchingNextPage ||
              getHandbookETradingPlatformsInfiniteQuery.fetchStatus === 'fetching' ||
              !getHandbookETradingPlatformsInfiniteQuery.hasNextPage ||
              retry
            )
              return;

            getHandbookETradingPlatformsInfiniteQuery.fetchNextPage();
            retry = true;
          };
        })()}
        onClickDelete={async index => {
          const item = flattedPages?.[index];

          if (item) {
            try {
              await deleteHandbookETradingPlatformsIdMutation.mutateAsync({
                params: { path: { id: item.id || 0 } },
              });

              getHandbookETradingPlatformsInfiniteQuery.refetch();

              add({
                name: 'eTradingPlatforms-delete-success',
                theme: 'success',
                title: 'Атрибут успешно удален',
              });
            } catch {
              add({
                name: 'eTradingPlatforms-delete-failure',
                theme: 'danger',
                title: 'Не удалось удалить атрибут',
              });
            }
          }
        }}
      />
      <CustomerHandbookDrawerLayout
        open={creationOpen}
        onClose={onCloseCreation}
        title="Создать справочник «Электронные торговые площадки»"
      >
        <ETradingPlatformsHandbookForm
          form={createETradingPlatformsHandbookForm}
          onSubmit={createETradingPlatformsHandbookForm.handleSubmit(onValidCreateHandbook)}
          disabled={postHandbookETradingPlatformsMutation.isPending}
          footer={
            <HandbookFormFooter
              loading={postHandbookETradingPlatformsMutation.isPending}
              onClose={onCloseCreation}
            />
          }
        />
      </CustomerHandbookDrawerLayout>

      <CustomerHandbookDrawerLayout
        open={!!editableHandbook}
        onClose={handleEditHandbook}
        title={editableHandbook?.name}
      >
        <ETradingPlatformsHandbookForm
          form={editETradingPlatformsHandbookForm}
          disabled={putHandbookETradingPlatformsIdMutation.isPending}
          initialEditable={false}
        />
      </CustomerHandbookDrawerLayout>
    </>
  );
}
