import { yupRu } from '@shared/config';
import { MAX_FILE_SIZE } from '@shared/consts';
import { formatBytes } from '@shared/lib';
import { FieldWithPreviewMultiFileItem } from '@shared/ui';

export const legalEntitiesSchema = yupRu.object({
  // TODO: временно, пока нету дизайна
  // addresses: yupRu.array(yupRu.string().required().min(3).max(255)),
  contact_ids: yupRu.array(yupRu.number().required()).required().min(1),
  files: yupRu
    .array(yupRu.mixed<FieldWithPreviewMultiFileItem>().required())
    .required()
    .test({
      name: 'file-sizes',
      test: function (value) {
        const totalSize = value.reduce((size, { file }) => size + (file?.size || 0), 0);

        if (totalSize > MAX_FILE_SIZE) {
          return this.createError({
            message: `Общий вес файлов (${formatBytes(totalSize)}) превыщает ${formatBytes(MAX_FILE_SIZE)}`,
          });
        }

        return true;
      },
    }),
  inn: yupRu
    .string()
    .min(10)
    .max(12)
    .required()
    .test({
      test: value => !isNaN(Number(value)),
      message: 'Должен состоять из цифр',
    }),
  kpp: yupRu.string().min(9).max(9).required(),
  name: yupRu.string().min(3).max(255).required(),
  payment_detail_id: yupRu.number().required(),
  purchase_object_id: yupRu.number().required(),
  region_id: yupRu.number().required(),
  // TODO: временно, пока нету дизайна
  // tags: yupRu.array(yupRu.string().min(3).max(255).required()).required().min(1),
  website: yupRu
    .string()
    .url()
    .max(255)
    .test({
      test: value => !value || value.length >= 3,
      message: 'Должен содержать как минимум 3 символа',
    }),
});

export type LegalEntitiesSchema = yupRu.InferType<typeof legalEntitiesSchema>;
