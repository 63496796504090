/* eslint-disable @typescript-eslint/no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';

import {
  $api,
  $mock,
  bodyRequestType,
  fetchClient,
  mockClient,
  ParametersPathType,
  ParametersQueryType,
} from '@shared/api';

function useGetHandbookETradingPlatformsInfiniteQuery(
  query: ParametersQueryType<'get', '/handbook/e-trading-platforms'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/handbook/e-trading-platforms', query],
    queryFn: async ({ pageParam }) => {
      const { data } = await fetchClient.GET('/handbook/e-trading-platforms', {
        // const { data } = await mockClient.GET('/handbook/e-trading-platforms', {
        params: { query: { ...query, offset: pageParam } },
      });

      return data?.list || [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + query.limit;
    },
    enabled,
  });
}

const createHandbookETradingPlatformsMutation = () =>
  $api.useMutation('post', '/handbook/e-trading-platforms');
// const createHandbookETradingPlatforms = (
//   body: bodyRequestType<'post', '/handbook/e-trading-platforms'>
// ) => createHandbookETradingPlatformsMutation.mutateAsync({ body: body });

const deleteByIdHandbookETradingPlatforms = (
  id: ParametersPathType<'delete', '/handbook/e-trading-platforms/{id}'>['id']
) =>
  $api
    .useMutation('delete', '/handbook/e-trading-platforms/{id}')
    .mutateAsync({ params: { path: { id: id } } });

const useGetByIdHandbookETradingPlatforms = (
  id: ParametersPathType<'get', '/handbook/e-trading-platforms/{id}'>['id']
) => $api.useQuery('get', '/handbook/e-trading-platforms/{id}', { params: { path: { id: id } } });

const updateHandbookETradingPlatformsMutation = () =>
  $api.useMutation('put', '/handbook/e-trading-platforms/{id}');
// const updateHandbookETradingPlatforms = (
//   id: ParametersPathType<'put', '/handbook/e-trading-platforms/{id}'>['id'],
//   body: bodyRequestType<'put', '/handbook/e-trading-platforms/{id}'>
// ) =>
//   updateHandbookETradingPlatformsMutation.mutateAsync({ body: body, params: { path: { id: id } } });

export const $eTradingPlatformsHooks = {
  useGetAll: useGetHandbookETradingPlatformsInfiniteQuery,
  useGetById: useGetByIdHandbookETradingPlatforms,
  createMutation: createHandbookETradingPlatformsMutation,
  updateByIdMutation: updateHandbookETradingPlatformsMutation,
  deleteById: deleteByIdHandbookETradingPlatforms,
};
