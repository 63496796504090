import { OrganizationType } from '@entities';
import { components } from '@shared/api';
import { yupRu } from '@shared/config';
import { emailRegex, phoneRegex } from '@shared/lib';

type ContactStatus = NonNullable<components['schemas']['handbook.Contact']['status']>;
type ContactTypeContact = NonNullable<components['schemas']['handbook.Contact']['type']>;

export const contactsSchema = yupRu.object({
  /** @description Комментарий @gotags: validate:"min_len:3|max_len:255" */
  comment: yupRu
    .string()
    .max(255)
    .test({
      test: value => !value || value.length >= 3,
      message: 'Должен содержать как минимум 3 символа',
    })
    .transform(value => value || undefined),
  /** @description Эл. адрес @gotags: validate:"email" */
  email: yupRu
    .string()
    .matches(emailRegex, {
      message: 'Некорректный e-mail',
      excludeEmptyString: true,
    })
    .transform(value => value || undefined),
  /** @description ФИО @gotags: validate:"required|min_len:3|max_len:255" */
  full_name: yupRu.string().min(3).max(255).required(),
  /** @description Идентификатор организации */
  organization_id: yupRu.string().transform(value => value || undefined),
  /** @description Тип организации  */
  organization_type: yupRu.string().transform(value => value || undefined),
  organization_with_type: yupRu
    .string<`${string}/${OrganizationType}`>()
    .transform(value => value || undefined),
  /** @description Телефон @gotags: validate:"required|min_len:3|max_len:255" */
  phone: yupRu.string().required().matches(phoneRegex, {
    message: 'Некорректный номер телефона',
    excludeEmptyString: true,
  }),
  /** @description Должность @gotags: validate:"min_len:3|max_len:255" */
  position: yupRu
    .string()
    .max(255)
    .test({
      test: value => !value || value.length >= 3,
      message: 'Должен содержать как минимум 3 символа',
    })
    .transform(value => value || undefined),
  /**
   * @description Статус контактного лица @gotags: enums:"Ассистент менеджера,Менеджер,Бухгалтерия,Доставка,Иное,Руководитель менеджера,Склад"
   * @enum {string}
   */
  status: yupRu
    .string()
    .oneOf<ContactStatus>([
      'Ассистент менеджера',
      'Менеджер',
      'Бухгалтерия',
      'Доставка',
      'Иное',
      'Руководитель менеджера',
      'Склад',
    ]),
  /**
   * @description Тип контактного лица @gotags: enums:"ГОССНАБ,Заказчик,Поставщик"
   * @enum {string}
   */
  type: yupRu
    .string()
    .oneOf<ContactTypeContact>(['ГОССНАБ', 'Заказчик', 'Поставщик'])
    .transform(value => value || undefined),
});

export type ContactsSchema = yupRu.InferType<typeof contactsSchema>;
