import { components } from '@shared/api';
import { yupRu } from '@shared/config';
import { emailRegex, phoneRegex } from '@shared/lib';

type UserMsg = components['schemas']['user.UserMsg'];

// type EmployeesAffiliation = UserMsg['affiliation'];
type EmployeesType = UserMsg['type'];

export const employeesSchema = yupRu.object({
  /**
   * @description Принадлежность сотрудника (например, к какой-то группе или проекту) @gotags: validate:"min_len:3|max_len:255" enums:"Внутренний,Внешний"
   * @enum {string}
   */
  // affiliation: yupRu
  //   .string()
  //   .oneOf<EmployeesAffiliation>(['Внешний', 'Внутренний', undefined])
  //   .transform(value => value || undefined),

  // Доступ
  blocked_at: yupRu
    .string()
    .oneOf<'Действующий' | 'Недействующий'>(['Действующий', 'Недействующий'])
    .transform(value => (value ? 'Недействующий' : 'Действующий')),
  /** @description Дата создания @gotags: swaggertype:"primitive,string"' */
  created_at: yupRu.string().transform(value => value || undefined),
  /** @description Подразделение, в котором работает сотрудник @gotags: swaggertype:"string" */
  department_id: yupRu.string().transform(value => value || undefined),
  /** @description Количество прямых подчиненных */
  direct_subordinate_ids: yupRu
    .array(yupRu.string().required())
    .transform(value => (value.length > 0 && value) || undefined),
  /** @description Электронная почта сотрудника @gotags: validate:"email" */
  email: yupRu
    .string()
    .matches(emailRegex, {
      message: 'Некорректный e-mail',
      excludeEmptyString: true,
    })
    .transform(value => value || undefined),
  /** @description ФИО полностью @gotags: validate:"required" */
  full_name: yupRu.string().required(),
  /** @description Идекнтификатор пользователя в формате UUID @gotags: validate:"required" */
  id: yupRu.string().required(),
  /** @description Количество косвенных подчиненных */
  indirect_subordinate_ids: yupRu
    .array(yupRu.string().required())
    .transform(value => (value.length > 0 && value) || undefined),
  /** @description Мобильный телефон сотрудника */
  mobile_phone: yupRu
    .string()
    .matches(phoneRegex, {
      message: 'Некорректный номер телефона',
      excludeEmptyString: true,
    })
    .transform(value => value || undefined),
  /** @description Ф.И.О. @gotags: validate:"required" */
  name_initials: yupRu.string().required(),
  /** @description Примечание или дополнительная информация о сотруднике @gotags: validate:"min_len:3|max_len:255" */
  note: yupRu
    .string()
    .min(3)
    .max(255)
    .transform(value => value || undefined),
  password: yupRu.string().transform(value => value || undefined),
  /** @description Фотография сотрудника URL @gotags: validate:"min_len:3|max_len:255" */
  photo: yupRu.string().transform(value => value || undefined),
  /** @description Должность сотрудника @gotags: validate:"required|min_len:3|max_len:255" */
  position: yupRu.string().min(3).max(255).required(),
  /** @description Разряд @gotags: validate:"min_len:3|max_len:255" */
  rank: yupRu
    .string()
    .min(3)
    .max(255)
    .transform(value => value || undefined),
  /**
   * @description Тип сотрудника (например, постоянный, временный и т.д.) @gotags: validate:"required|min_len:3|max_len:255" enums:"Внутренний,Внешний"
   * @enum {string}
   */
  type: yupRu
    .string()
    .oneOf<EmployeesType>(['Внешний', 'Внутренний', undefined])
    .transform(value => value || undefined),
  /** @description Рабочий телефон сотрудника */
  work_phone: yupRu
    .string()
    // .matches(phoneRegex, {
    //   message: 'Некорректный номер телефона',
    //   excludeEmptyString: true,
    // })
    .transform(value => value || undefined),
});

export type EmployeesSchema = yupRu.InferType<typeof employeesSchema>;
