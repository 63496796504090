import { useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { Provider } from 'jotai';
import { ReactNode, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { $agenciesHooks } from '@entities';
import {
  AgenciesHandbookForm,
  AgenciesHandbookSchema,
  agenciesHandbookSchema,
  ColumnFilterPopup,
  ColumnsFilters,
  CRMTable,
  CRMTableColumns,
  CRMTableData,
  CRMTableSettings,
  useTableColumnSort,
  useTableFilters,
} from '@features';
import { $api, bodyRequestType, components } from '@shared/api';

import { AGENCIES_TABLE_COLUMNS_WITH_FILTER } from '../consts';
import { ColumnsAgenciesHandbookTable, CustomHandbookTableDefaultProps } from '../model';
import { CustomerHandbookDrawerLayout } from './CustomHandbookDrawerLayout';
import { CustomHandbookTableLayout } from './CustomHandbookTableLayout';
import { HandbookFormFooter } from './HandbookFormFooter';

export function AgenciesHandbookTable({
  creationOpen,
  onCloseCreation,
}: CustomHandbookTableDefaultProps) {
  const { add } = useToaster();

  const [searchValue, setSearchValue] = useState('');

  const [isUpdateConfig, setIsUpdateConfig] = useState(false);

  // НАЧАЛО фильтры сайдбар

  const {
    selectedFilters,
    sidebarFilters,
    setSelectedFilters,
    updateFiltersByDateColumns,
    updateFiltersByCurrencyOrMarginColumns,
    resetFilters,
    deleteFilterValueByName,
  } = useTableFilters(AGENCIES_TABLE_COLUMNS_WITH_FILTER);

  // КОНЕЦ фильтры сайдбар

  const getHandbookAgenciesInfiniteQuery = $agenciesHooks.useGetAll(
    {
      query: searchValue,
      limit: 30,
    },
    true
  );

  const flattedPages = getHandbookAgenciesInfiniteQuery.data?.pages.flat();

  const data: CRMTableData<ColumnsAgenciesHandbookTable> = useMemo(
    () =>
      flattedPages?.map(agency => ({
        ИНН: agency.inn || '',
        Комментарий: agency?.comment || '',
        'Краткое наименование': agency.name,
      })) ?? [],
    [getHandbookAgenciesInfiniteQuery.data]
  );

  const columns: CRMTableColumns<ColumnsAgenciesHandbookTable> = [
    {
      id: 'Краткое наименование',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      name: () => columnWithHover('Краткое наименование'),
    },
    {
      id: 'ИНН',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      name: () => columnWithHover('ИНН'),
    },
    {
      id: 'Комментарий',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      // className: 'max-w-[250px] truncate',
      name: () => columnWithHover('Комментарий'),
      // template: item => <span title={item['Комментарий']}>{item['Комментарий']}</span>,
    },
  ];

  const initialSettings: CRMTableSettings<ColumnsAgenciesHandbookTable> = columns.map(col => ({
    id: col.id,
    isSelected: true,
  }));

  const [tableSettings, setTableSettings] = useState<
    CRMTableSettings<ColumnsAgenciesHandbookTable>
  >(() => structuredClone(initialSettings));

  const tableSettingsToBack = useMemo(() => {
    return tableSettings.reduce<string[]>((acc, setting) => {
      if (setting.isSelected) {
        acc = [...acc, setting.id];
      }
      return acc;
    }, []);
  }, [tableSettings]);

  const { sortOrder, sortColumn, changeSortOrder } = useTableColumnSort(tableSettings);

  const columnWithHover = (name: ColumnsAgenciesHandbookTable[number], currencyValue?: string) => {
    const filter = selectedFilters[name];
    let content: ReactNode | null = null;

    const withPopup = (cellName: ColumnsAgenciesHandbookTable[number], renderNode: ReactNode) => (
      <Provider>
        <ColumnFilterPopup cellName={cellName}>{renderNode}</ColumnFilterPopup>
      </Provider>
    );

    const getColumnFilterPopupByFilterType: Partial<Record<ColumnsFilters, ReactNode>> = {
      date: withPopup(
        name,
        <ColumnFilterPopup.Date
          dateFormat={'fullDate'}
          onValid={cFilterData => {
            updateFiltersByDateColumns(name, 'fullDate', cFilterData.from, cFilterData.to);
            setIsUpdateConfig(true);
          }}
        />
      ),
      dateWithTime: withPopup(
        name,
        <ColumnFilterPopup.Date
          dateFormat={'fullDateWithTime'}
          onValid={cFilterData => {
            updateFiltersByDateColumns(name, 'fullDateWithTime', cFilterData.from, cFilterData.to);
            setIsUpdateConfig(true);
          }}
        />
      ),
      currency: withPopup(
        name,
        <ColumnFilterPopup.Currency
          currencyValue={currencyValue}
          onValid={cFilterData => {
            updateFiltersByCurrencyOrMarginColumns(name, cFilterData.from, cFilterData.to);
            setIsUpdateConfig(true);
          }}
        />
      ),
      priority: withPopup(
        name,
        <ColumnFilterPopup.Priority
          onValid={cFilterData => {
            updateFiltersByCurrencyOrMarginColumns(name, cFilterData.from, cFilterData.to);
            setIsUpdateConfig(true);
          }}
        />
      ),
      margin: withPopup(
        name,
        <ColumnFilterPopup.Margin
          onValid={cFilterData => {
            updateFiltersByCurrencyOrMarginColumns(name, cFilterData.from, cFilterData.to);
            setIsUpdateConfig(true);
          }}
        />
      ),
    };

    if (filter.type && filter.type in getColumnFilterPopupByFilterType) {
      content = getColumnFilterPopupByFilterType[filter.type];
    }

    return (
      <CRMTable.HoverColumnWrapper
        sort={sortOrder}
        iconPosition="end"
        content={content || name}
        columnName={name}
        currentColumnName={sortColumn}
        onSortTypeChange={(newSort, colName) => {
          changeSortOrder(newSort, colName);
          setIsUpdateConfig(true);
        }}
      />
    );
  };

  // const [selectedIds, setSelectedIds] = useState<string[]>([]);

  // НАЧАЛО нижняя панель

  // const [bottomPanelOpen, setBottomPanelOpen] = useState(false);
  // const [amountDialogOpen, setAmountDialogOpen] = useState(false);
  // const [updateCommercialOfferDialogOpen, setUpdateCommercialOfferDialogOpen] = useState(false);

  // const [selectedRows, currencyColumnsNames] = useMemo(() => {
  //   return [
  //     tableData.filter((_, index) => !selectedIds.length || selectedIds.includes(index.toString())),
  //     objectKeysSafeToArray(AGENCIES_TABLE_COLUMNS_WITH_FILTER).reduce(
  //       (acc, key) => {
  //         if (AGENCIES_TABLE_COLUMNS_WITH_FILTER[key] === 'currency') {
  //           acc.push(key);
  //         }
  //         return acc;
  //       },
  //       [] as ColumnsAgenciesHandbookTable[number][]
  //     ),
  //   ];
  // }, [selectedIds]);

  const [editableHandbook, setEditableHandbook] = useState<
    components['schemas']['handbook.Agency'] | null
  >(null);

  const postHandbookAgenciesMutation = $api.useMutation('post', '/handbook/agencies');

  const createAgenciesHandbookForm = useForm<AgenciesHandbookSchema>({
    resolver: yupResolver(agenciesHandbookSchema),
    mode: 'all',
    defaultValues: {
      comment: '',
      inn: '',
      name: '',
    },
  });

  const onValidCreateHandbook = async (values: AgenciesHandbookSchema) => {
    try {
      await postHandbookAgenciesMutation.mutateAsync({
        body: values as bodyRequestType<'post', '/handbook/agencies'>, // TODO: убрать as, когда пофиксят схему
      });
      createAgenciesHandbookForm.reset();
      getHandbookAgenciesInfiniteQuery.refetch();
      add({
        name: 'agencies-create-success',
        theme: 'success',
        title: `Справочник успешно создан`,
      });
      onCloseCreation();
    } catch {
      add({
        name: 'agencies-create-failed',
        theme: 'danger',
        title: `Не удалось создать справочник`,
      });
    }
  };

  const putHandbookAgenciesIdMutation = $api.useMutation('put', '/handbook/agencies/{id}');

  const editAgenciesHandbookForm = useForm<AgenciesHandbookSchema>({
    resolver: yupResolver(agenciesHandbookSchema),
    values: editableHandbook?.name
      ? { ...editableHandbook, name: editableHandbook.name }
      : undefined,
    mode: 'all',
  });

  const isEditAgenciesHandbookFormDirty = editAgenciesHandbookForm.formState.isDirty;

  const handleEditHandbook = () => {
    editAgenciesHandbookForm.handleSubmit(async values => {
      if (editableHandbook && isEditAgenciesHandbookFormDirty) {
        try {
          await putHandbookAgenciesIdMutation.mutateAsync({
            body: values as bodyRequestType<'put', '/handbook/agencies'>, // TODO: убрать as, когда пофиксят схему
            params: { path: { id: editableHandbook.id } },
          });
          add({
            name: 'agencies-create-success',
            theme: 'success',
            title: `Справочник успешно изменен`,
          });
          getHandbookAgenciesInfiniteQuery.refetch();
        } catch {
          add({
            name: 'agencies-create-failed',
            theme: 'danger',
            title: `Не удалось изменить справочник`,
          });
        }
      }
    })();
    setEditableHandbook(null);
  };

  // const deleteHandbookAgenciesIdMutation = $api.useMutation('delete', '/handbook/agencies/{id}');

  return (
    <>
      <CustomHandbookTableLayout
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        sidebarFilters={sidebarFilters}
        deleteFilterValueByName={deleteFilterValueByName}
        resetFilters={resetFilters}
        setIsUpdateConfig={setIsUpdateConfig}
        // TODO: как готов запрос
        isFetching={getHandbookAgenciesInfiniteQuery.isFetching}
        columns={columns}
        data={data}
        tableColumnsWithFilter={AGENCIES_TABLE_COLUMNS_WITH_FILTER}
        searchValue={searchValue}
        onSearchValueUpdate={setSearchValue}
        onRowClick={(_, index) => setEditableHandbook(flattedPages?.[index] ?? null)}
        onIntersecting={() => {
          getHandbookAgenciesInfiniteQuery.fetchNextPage();
        }}
        // onClickDelete={async index => {
        //   const item = flattedPages?.[index];

        //   if (item) {
        //     try {
        //       await deleteHandbookAgenciesIdMutation.mutateAsync({
        //         params: { path: { id: item.id || 0 } },
        //       });

        //       getHandbookAgenciesInfiniteQuery.refetch();

        //       add({
        //         name: 'agencies-delete-success',
        //         theme: 'success',
        //         title: 'Атрибут успешно удален',
        //       });
        //     } catch {
        //       add({
        //         name: 'agencies-delete-failure',
        //         theme: 'danger',
        //         title: 'Не удалось удалить атрибут',
        //       });
        //     }
        //   }
        // }}
      />
      <CustomerHandbookDrawerLayout
        open={creationOpen}
        onClose={onCloseCreation}
        title="Создать справочник «Ведомства»"
      >
        <AgenciesHandbookForm
          form={createAgenciesHandbookForm}
          onSubmit={createAgenciesHandbookForm.handleSubmit(onValidCreateHandbook)}
          disabled={postHandbookAgenciesMutation.isPending}
          footer={
            <HandbookFormFooter
              loading={postHandbookAgenciesMutation.isPending}
              onClose={onCloseCreation}
            />
          }
        />
      </CustomerHandbookDrawerLayout>

      <CustomerHandbookDrawerLayout
        open={!!editableHandbook}
        onClose={handleEditHandbook}
        title={editableHandbook?.name}
      >
        <AgenciesHandbookForm
          form={editAgenciesHandbookForm}
          disabled={putHandbookAgenciesIdMutation.isPending}
          initialEditable={false}
        />
      </CustomerHandbookDrawerLayout>
    </>
  );
}
