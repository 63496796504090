import { Button, ButtonProps, Divider, Text, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm, UseFormProps } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

import {
  $contractHooks,
  $customersHooks,
  $employeesHooks,
  $eTradingPlatformsHooks,
  $purchaseObjectsHooks,
  $regionsHooks,
  $suppliersHooks,
  useGetSystemHandbookQuery,
  userAtom,
} from '@entities';
import { bodyResponseType } from '@shared/api';
import { DATE_FORMATS, MAX_FILE_SIZE, NOT_ALLOWED_FILE_EXTENSIONS } from '@shared/consts';
import { useFieldsWithPreview } from '@shared/lib';
import { YesNo } from '@shared/model';
import { FieldWithPreview, RightSidebar } from '@shared/ui';

import { CONTRACT_OPTIONS } from '../consts';
import { generateTitle } from '../lib';
import { contractSchema, ContractSchema } from '../model';

export interface ContractFormProps {
  onValuesChange?: (values: ContractSchema) => void;
  response?: Omit<
    bodyResponseType<'get', '/contract/{id}'>,
    'has_smp_restriction' | 'has_contract_security' | 'has_warranty_security' | 'customer_priority'
  > & {
    has_smp_restriction: YesNo | undefined;
    has_contract_security: YesNo | undefined;
    has_warranty_security: YesNo | undefined;
    customer_priority?: '1' | '2' | '3' | '4' | '5';
  };
  onValid?: SubmitHandler<ContractSchema>;
  onInvalid?: SubmitErrorHandler<ContractSchema>;
  onClickCancelButton?: () => void;
  initialEditable?: boolean;
  disabled?: boolean;
  hideBottomPanel?: boolean;
  submitButtonText?: string;
  cancelButtonText?: string;
  hide?: boolean;
  approvalButtonProps?: Pick<ButtonProps, 'onClick' | 'disabled'>;
  hideTitleField?: boolean;
  defaultValues?: UseFormProps<ContractSchema>['defaultValues'];
  values?: UseFormProps<ContractSchema>['values'];
  allowedStages?: NonNullable<ContractSchema['stage']>[];
  // editableSettings?: Partial<
  //   Record<
  //     keyof ContractSchema,
  //     Parameters<ReturnType<typeof useFieldsWithPreview>['isFieldEditable']>['1']
  //   >
  // >;
}

export function ContractForm({
  onValuesChange,
  response,
  initialEditable = false,
  disabled = false,
  hideBottomPanel = false,
  onClickCancelButton,
  onInvalid,
  onValid = () => {},
  hideTitleField = true,
  values: externalValues,
  defaultValues,
  hide = false,
  cancelButtonText = 'Отмена',
  submitButtonText = 'Применить',
  allowedStages,
}: ContractFormProps) {
  const { add } = useToaster();
  const user = useAtomValue(userAtom);
  const userOptions = user ? [{ content: user.full_name, value: user.id }] : [];

  const contractForm = useForm<ContractSchema>({
    resolver: yupResolver(contractSchema),
    mode: 'all',
    defaultValues: defaultValues,
    resetOptions: {
      keepDirtyValues: true,
      keepDefaultValues: false,
    },
  });

  const { createFieldRef, isFieldEditable, resetSelectedField, selectField, selectedFieldPath } =
    useFieldsWithPreview({
      form: contractForm,
      initialEditable,
      disabled,
    });
  const fieldPreviewForStage = useFieldsWithPreview({
    form: contractForm,
    initialEditable,
    disabled: response?.stage === 'Расторгнут',
  });

  const values = contractForm.watch();

  useDeepCompareEffect(() => {
    onValuesChange?.(values);
  }, [values]);

  // System filters
  const [filterStage, setFilterStage] = useState('');
  const [filterBasis, setFilterBasis] = useState('');
  const [filterGuaranteeKind, setFilterGuaranteeKind] = useState('');
  //

  // Queries
  const getGuaranteeKindQuery = useGetSystemHandbookQuery(
    'guarantee_kind',
    selectedFieldPath === 'contract_security_type' || selectedFieldPath === 'warranty_security_type'
  );

  const [filterEmployees, setFilterEmployees] = useState('');
  const getHandbookEmployeesInfiniteQuery = $employeesHooks.useGetAll(
    {
      query: filterEmployees,
      limit: 30,
    },
    selectedFieldPath === 'responsible_id'
  );
  const flattedEmployees = useMemo(
    () => getHandbookEmployeesInfiniteQuery.data?.pages.flat(),
    [getHandbookEmployeesInfiniteQuery.data]
  );

  const [filterETradingPlatforms, setFilterETradingPlatforms] = useState('');
  const getHandbookETradingPlatformsInfiniteQuery = $eTradingPlatformsHooks.useGetAll(
    {
      query: filterETradingPlatforms,
      limit: 30,
    },
    selectedFieldPath === 'trading_platform_id'
  );
  const flattedETradingPlatforms = useMemo(
    () => getHandbookETradingPlatformsInfiniteQuery.data?.pages.flat(),
    [getHandbookETradingPlatformsInfiniteQuery.data]
  );

  const [filterCustomers, setFilterCustomers] = useState('');
  const getHandbookCustomersInfiniteQuery = $customersHooks.useGetAll(
    {
      query: filterCustomers,
      limit: 30,
    },
    selectedFieldPath === 'customer_id'
  );
  const flattedCustomers = useMemo(
    () => getHandbookCustomersInfiniteQuery.data?.pages.flat().filter(data => !!data),
    [getHandbookCustomersInfiniteQuery.data]
  );

  const [filterSuppliers, setFilterSuppliers] = useState('');
  const getHandbookSuppliersInfiniteQuery = $suppliersHooks.useGetAll(
    {
      query: filterSuppliers,
      limit: 30,
    },
    selectedFieldPath === 'customer_id'
  );
  const flattedSuppliers = useMemo(
    () => getHandbookSuppliersInfiniteQuery.data?.pages.flat().filter(data => !!data),
    [getHandbookSuppliersInfiniteQuery.data]
  );

  const [filterPurchaseObjects, setFilterPurchaseObjects] = useState('');
  const getHandbookPurchaseObjectsInfiniteQuery = $purchaseObjectsHooks.useGetAll(
    {
      query: filterPurchaseObjects,
      limit: 30,
    },
    selectedFieldPath === 'object_ids'
  );
  const flattedPurchaseObjects = useMemo(
    () => getHandbookPurchaseObjectsInfiniteQuery.data?.pages.flat().filter(data => !!data),
    [getHandbookPurchaseObjectsInfiniteQuery.data]
  );

  const [filterRegions, setFilterRegions] = useState('');
  const getHandbookRegionsInfiniteQuery = $regionsHooks.useGetAll(
    {
      query: filterRegions,
      limit: 30,
    },
    selectedFieldPath === 'region_id'
  );
  const flattedRegions = useMemo(
    () => getHandbookRegionsInfiniteQuery.data?.pages.flat(),
    [getHandbookRegionsInfiniteQuery.data]
  );
  //

  // Торги и КП
  const [filterRelatedCard, setFilterRelatedCard] = useState('');
  const getHandbookRelatedCardInfiniteQuery = $contractHooks.offersAndTenders(
    {
      query: filterRelatedCard,
      limit: 30,
    },
    selectedFieldPath === 'related_card_id'
  );
  const flattedRelatedCard = useMemo(
    () => getHandbookRelatedCardInfiniteQuery.data?.pages.flat(),
    [getHandbookRelatedCardInfiniteQuery.data]
  );
  //

  useEffect(() => {
    if (externalValues) contractForm.reset(externalValues);
  }, [externalValues]);

  // Изменение наименования
  useEffect(() => {
    if (values['unique_number'] && values['customer_id'] && values['object_ids']?.[0]) {
      const uniqueNumber = values['unique_number'];
      const customerInitials = flattedCustomers?.find(
        customer => customer.id === values['customer_id']
      )?.name;
      const objectName = flattedPurchaseObjects?.find(
        supplyObject => supplyObject.id === values['object_ids'][0]
      )?.object;
      if (customerInitials && objectName) {
        const title = generateTitle({
          customerInitials,
          objectName,
          uniqueNumber,
        });

        contractForm.setValue('name', title);
      }
    }
  }, [values['unique_number'], values['customer_id'], values['object_ids']]);

  const [fileFieldsLoading, setFileFieldsLoading] = useState<Record<string, boolean>>({});

  const isFilesLoading = Object.values(fileFieldsLoading).some(
    fileFieldLoading => fileFieldLoading
  );

  const isConfirmButtonDisabled = disabled || isFilesLoading;

  useDeepCompareEffect(() => {
    if (response) contractForm.reset(response);
  }, [response || {}]);

  useEffect(() => {
    if (
      Object.values(contractForm.formState.errors).length > 0 &&
      contractForm.formState.isSubmitted
    )
      add({
        title: 'Не все обязательные поля заполнены',
        name: 'validation-fields-error',
        theme: 'warning',
      });
  }, [contractForm.formState.isSubmitted]);

  if (hide) return null;

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={contractForm.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        <Text
          variant="body-2"
          className="font-semibold uppercase"
        >
          Параметры Контракта
        </Text>
        {/* Параметры Контракта */}
        <FieldWithPreview.Text
          ref={createFieldRef('name')}
          control={contractForm.control}
          path="name"
          edit={false}
          show={!hideTitleField}
          onFieldSelect={() => selectField('name')}
          name="Наименование"
          textInputProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('basis')}
          control={contractForm.control}
          path="basis"
          edit={isFieldEditable('basis')}
          onFieldSelect={() => selectField('basis')}
          name="Основание"
          options={CONTRACT_OPTIONS['basis']}
          filter={filterBasis}
          onFilterChange={filter => setFilterBasis(filter)}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
          required
        />
        <FieldWithPreview.Radio
          ref={createFieldRef('fz')}
          control={contractForm.control}
          path="fz"
          edit={isFieldEditable('fz')}
          onFieldSelect={() => selectField('fz')}
          name="ФЗ"
          options={CONTRACT_OPTIONS['fz']}
          defaultOption={response?.fz ? { value: response.fz, content: response.fz } : undefined}
          resetSelectedField={resetSelectedField}
          required
        />
        <FieldWithPreview.Radio
          ref={createFieldRef('gozfz')}
          control={contractForm.control}
          path="gozfz"
          edit={isFieldEditable('gozfz')}
          onFieldSelect={() => selectField('gozfz')}
          name="В рамках ГОЗ по 275-ФЗ"
          options={CONTRACT_OPTIONS['gozfz']}
          defaultOption={
            response?.gozfz ? { value: response.gozfz, content: response.gozfz } : undefined
          }
          resetSelectedField={resetSelectedField}
          required
        />
        <FieldWithPreview.Radio
          ref={createFieldRef('maintenance')}
          show={values['gozfz'] === 'ГОЗ'}
          control={contractForm.control}
          path="maintenance"
          edit={isFieldEditable('maintenance')}
          onFieldSelect={() => selectField('maintenance')}
          name="Сопровождение"
          options={CONTRACT_OPTIONS['maintenance']}
          defaultOption={
            response?.maintenance
              ? { value: response.maintenance, content: response.maintenance }
              : undefined
          }
          resetSelectedField={resetSelectedField}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('unique_number')}
          control={contractForm.control}
          path="unique_number"
          edit={isFieldEditable('unique_number')}
          onFieldSelect={() => selectField('unique_number')}
          name="Уникальный номер"
          textInputProps={{
            disabled,
          }}
          required
        />
        <FieldWithPreview.Select
          ref={fieldPreviewForStage.createFieldRef('stage')}
          control={contractForm.control}
          path="stage"
          edit={fieldPreviewForStage.isFieldEditable('stage')}
          onFieldSelect={() => fieldPreviewForStage.selectField('stage')}
          name="Этап"
          options={CONTRACT_OPTIONS['stage'].filter(opt =>
            allowedStages ? allowedStages.includes(opt.value) : opt.value === 'Проект'
          )}
          defaultOptions={
            !allowedStages && CONTRACT_OPTIONS['stage'][2]
              ? [CONTRACT_OPTIONS['stage'][2]]
              : undefined
          }
          filter={filterStage}
          onFilterChange={filter => setFilterStage(filter)}
          delayFilter={0}
          resetSelectedField={fieldPreviewForStage.resetSelectedField}
          selectedFieldPath={fieldPreviewForStage.selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled: response?.stage === 'Расторгнут',
          }}
        />
        <FieldWithPreview.TextArea
          show={values.stage === 'Расторгнут'}
          ref={fieldPreviewForStage.createFieldRef('termination_reason')}
          control={contractForm.control}
          path="termination_reason"
          edit={fieldPreviewForStage.isFieldEditable('termination_reason')}
          onFieldSelect={() => fieldPreviewForStage.selectField('termination_reason')}
          name="Причина расторжения"
          wrapperProps={{
            textAlign: 'start',
          }}
          textAreaProps={{
            disabled: response?.stage === 'Расторгнут',
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('responsible_id')}
          control={contractForm.control}
          path="responsible_id"
          edit={isFieldEditable('responsible_id')}
          onFieldSelect={() => selectField('responsible_id')}
          name="Ответственный"
          options={
            flattedEmployees?.map(employee => ({
              content: employee?.name_initials,
              value: employee.id,
            })) || []
          }
          defaultOptions={
            response && response.responsible_id
              ? [{ content: response.responsible_value, value: response.responsible_id }]
              : userOptions
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterEmployees}
          onFilterChange={filter => setFilterEmployees(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookEmployeesInfiniteQuery.isFetching ||
              getHandbookEmployeesInfiniteQuery.isFetchingNextPage ||
              getHandbookEmployeesInfiniteQuery.isLoading ||
              getHandbookEmployeesInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookEmployeesInfiniteQuery.fetchNextPage();
            },
          }}
          required
        />
        <FieldWithPreview.Select
          ref={createFieldRef('customer_id')}
          control={contractForm.control}
          path="customer_id"
          edit={isFieldEditable('customer_id')}
          onFieldSelect={() => selectField('customer_id')}
          name="Заказчик"
          options={
            flattedCustomers?.map(customer => ({
              content: customer.name,
              value: customer.id,
            })) || []
          }
          defaultOptions={
            response
              ? [{ content: response.customer_value, value: response.customer_id }]
              : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterCustomers}
          onFilterChange={filter => setFilterCustomers(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookCustomersInfiniteQuery.isFetching ||
              getHandbookCustomersInfiniteQuery.isFetchingNextPage ||
              getHandbookCustomersInfiniteQuery.isLoading ||
              getHandbookCustomersInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookCustomersInfiniteQuery.fetchNextPage();
            },
          }}
          required
        />
        <FieldWithPreview.Radio
          ref={createFieldRef('customer_priority')}
          control={contractForm.control}
          path="customer_priority"
          edit={isFieldEditable('customer_priority')}
          onFieldSelect={() => selectField('customer_priority')}
          name="Приоритет заказчика"
          defaultOption={
            response?.customer_priority
              ? {
                  content: response.customer_priority,
                  value: response.customer_priority,
                }
              : undefined
          }
          options={CONTRACT_OPTIONS['customer_priority']}
          resetSelectedField={resetSelectedField}
          radioButtonProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('supplier_id')}
          control={contractForm.control}
          path="supplier_id"
          edit={response?.stage !== 'Заключен' && isFieldEditable('supplier_id')}
          onFieldSelect={() => selectField('supplier_id')}
          name="Поставщик"
          options={
            flattedSuppliers?.map(supplier => ({
              content: supplier.name,
              value: supplier.id,
            })) || []
          }
          defaultOptions={
            response
              ? [{ content: response.supplier_value, value: response.supplier_id }]
              : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterSuppliers}
          onFilterChange={filter => setFilterSuppliers(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled: disabled || response?.stage === 'Заключен',
            loading:
              getHandbookSuppliersInfiniteQuery.isFetching ||
              getHandbookSuppliersInfiniteQuery.isFetchingNextPage ||
              getHandbookSuppliersInfiniteQuery.isLoading ||
              getHandbookSuppliersInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookSuppliersInfiniteQuery.fetchNextPage();
            },
          }}
          required
        />
        <FieldWithPreview.MultiSelect
          ref={createFieldRef('object_ids')}
          control={contractForm.control}
          path="object_ids"
          edit={response?.stage !== 'Заключен' && isFieldEditable('object_ids')}
          onFieldSelect={() => selectField('object_ids')}
          name="Объект торгов"
          options={
            flattedPurchaseObjects?.map(object => ({
              content: object.object,
              value: object.id,
            })) || []
          }
          defaultOptions={
            response
              ? response.object_ids.map((object, i) => ({
                  value: object,
                  content: response.object_values?.[i],
                }))
              : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterPurchaseObjects}
          onFilterChange={filter => setFilterPurchaseObjects(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled: disabled || response?.stage === 'Заключен',
            loading:
              getHandbookPurchaseObjectsInfiniteQuery.isFetching ||
              getHandbookPurchaseObjectsInfiniteQuery.isFetchingNextPage ||
              getHandbookPurchaseObjectsInfiniteQuery.isLoading ||
              getHandbookPurchaseObjectsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookPurchaseObjectsInfiniteQuery.fetchNextPage();
            },
          }}
          required
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('comment')}
          control={contractForm.control}
          path="comment"
          edit={isFieldEditable('comment')}
          onFieldSelect={() => selectField('comment')}
          name="Комментарий"
          wrapperProps={{
            textAlign: 'start',
          }}
          textAreaProps={{
            disabled,
          }}
        />

        <Divider />
        <Text
          variant="body-2"
          className="font-semibold uppercase"
        >
          Срок оплаты с момента поставки
        </Text>

        {/* Срок оплаты с момента поставки */}
        <FieldWithPreview.Radio
          ref={createFieldRef('days_type')}
          control={contractForm.control}
          path="days_type"
          edit={isFieldEditable('days_type')}
          onFieldSelect={() => selectField('days_type')}
          name="Дни"
          options={CONTRACT_OPTIONS['days_type']}
          defaultOption={
            response?.days_type
              ? { value: response.days_type, content: response.days_type }
              : undefined
          }
          resetSelectedField={resetSelectedField}
          radioButtonProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Text
          show={!!values['days_type']}
          ref={createFieldRef('payment_term')}
          control={contractForm.control}
          path="payment_term"
          edit={isFieldEditable('payment_term')}
          onFieldSelect={() => selectField('payment_term')}
          name="Фактический срок оплаты с момента оплаты, дн"
          textInputProps={{
            disabled,
          }}
        />

        <Divider />
        <Text
          variant="body-2"
          className="font-semibold uppercase"
        >
          Контракт
        </Text>

        {/* Контракт */}
        <FieldWithPreview.Currency
          ref={createFieldRef('contract_sum')}
          control={contractForm.control}
          path="contract_sum"
          edit={response?.stage !== 'Заключен' && isFieldEditable('contract_sum')}
          onFieldSelect={() => selectField('contract_sum')}
          name="Сумма контракта"
          numberFormatProps={{
            disabled: disabled || response?.stage === 'Заключен',
            min: 0,
          }}
          required
        />
        <FieldWithPreview.Text
          show={values.basis !== 'Прямая закупка без договора'}
          ref={createFieldRef('contract_number')}
          control={contractForm.control}
          path="contract_number"
          edit={response?.stage !== 'Заключен' && isFieldEditable('contract_number')}
          onFieldSelect={() => selectField('contract_number')}
          name="Номер контракта"
          textInputProps={{
            disabled: disabled || response?.stage === 'Заключен',
          }}
        />
        <FieldWithPreview.Date
          ref={createFieldRef('signing_date')}
          control={contractForm.control}
          path="signing_date"
          edit={response?.stage !== 'Заключен' && isFieldEditable('signing_date')}
          onFieldSelect={() => selectField('signing_date')}
          name="Дата подписания"
          format={DATE_FORMATS.fullDate}
          formatPreview={DATE_FORMATS.fullDate}
          datePickerProps={{
            placeholder: 'Дата и время',
            disabled: disabled || response?.stage === 'Заключен',
          }}
        />
        <FieldWithPreview.Date
          show={values.basis !== 'Прямая закупка без договора'}
          ref={createFieldRef('contract_duration')}
          control={contractForm.control}
          path="contract_duration"
          edit={isFieldEditable('contract_duration')}
          onFieldSelect={() => selectField('contract_duration')}
          name="Срок действия контракта"
          format={DATE_FORMATS.fullDate}
          formatPreview={DATE_FORMATS.fullDate}
          datePickerProps={{
            placeholder: 'Дата и время',
            disabled,
          }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('contract_registration_number')}
          control={contractForm.control}
          path="contract_registration_number"
          edit={isFieldEditable('contract_registration_number')}
          onFieldSelect={() => selectField('contract_registration_number')}
          name="Реестровый номер контракта"
          textInputProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Select
          show={
            values.basis !== 'Прямая закупка без договора' &&
            values.basis !== 'Прямая закупка с договором'
          }
          ref={createFieldRef('trading_platform_id')}
          control={contractForm.control}
          path="trading_platform_id"
          edit={isFieldEditable('trading_platform_id')}
          onFieldSelect={() => selectField('trading_platform_id')}
          name="Площадка"
          options={
            flattedETradingPlatforms?.map(platform => ({
              content: platform.name,
              value: platform.id,
            })) || []
          }
          defaultOptions={
            response && response.trading_platform_id
              ? [{ content: response.trading_platform_value, value: response.trading_platform_id }]
              : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterETradingPlatforms}
          onFilterChange={filter => setFilterETradingPlatforms(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookETradingPlatformsInfiniteQuery.isFetching ||
              getHandbookETradingPlatformsInfiniteQuery.isFetchingNextPage ||
              getHandbookETradingPlatformsInfiniteQuery.isLoading ||
              getHandbookETradingPlatformsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookETradingPlatformsInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('related_card_id')}
          control={contractForm.control}
          path="related_card_id"
          edit={isFieldEditable('related_card_id')}
          onFieldSelect={() => selectField('related_card_id')}
          name="КП/Тендер"
          options={
            flattedRelatedCard?.map(card => ({
              content: card.object.title,
              value: card.id,
            })) || []
          }
          defaultOptions={
            response && response.related_card_id
              ? [
                  {
                    content: response.related_card_value?.title,
                    value: response.related_card_id,
                  },
                ]
              : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterRelatedCard}
          onFilterChange={filter => setFilterRelatedCard(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookRelatedCardInfiniteQuery.isFetching ||
              getHandbookRelatedCardInfiniteQuery.isFetchingNextPage ||
              getHandbookRelatedCardInfiniteQuery.isLoading ||
              getHandbookRelatedCardInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookRelatedCardInfiniteQuery.fetchNextPage();
            },
            onUpdate: ([id]) => {
              const type = flattedRelatedCard?.find(card => card.id === id)?.type;
              if (type) contractForm.setValue('related_card_type', type);
            },
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('region_id')}
          control={contractForm.control}
          path="region_id"
          edit={isFieldEditable('region_id')}
          onFieldSelect={() => selectField('region_id')}
          name="Регион поставки"
          options={
            flattedRegions?.map(region => ({
              content: region.name,
              value: region.id,
            })) || []
          }
          defaultOptions={
            response && response.region_id
              ? [{ content: response.region_value, value: response.region_id }]
              : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterRegions}
          onFilterChange={filter => setFilterRegions(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookRegionsInfiniteQuery.isFetching ||
              getHandbookRegionsInfiniteQuery.isFetchingNextPage ||
              getHandbookRegionsInfiniteQuery.isLoading ||
              getHandbookRegionsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookRegionsInfiniteQuery.fetchNextPage();
            },
          }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('delivery_address')}
          control={contractForm.control}
          path="delivery_address"
          edit={isFieldEditable('delivery_address')}
          onFieldSelect={() => selectField('delivery_address')}
          name="Адрес поставки"
          textInputProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('delivery_term')}
          control={contractForm.control}
          path="delivery_term"
          edit={isFieldEditable('delivery_term')}
          onFieldSelect={() => selectField('delivery_term')}
          name="Срок поставки"
          textInputProps={{
            disabled,
          }}
        />
        <FieldWithPreview.MultiFile
          control={contractForm.control}
          path="actual_files"
          name="Актуальный файл расчета"
          edit={response?.stage !== 'Заключен' && isFieldEditable('actual_files')}
          onFieldSelect={() => selectField('actual_files')}
          ref={createFieldRef('actual_files')}
          onLoadingChange={loading =>
            setFileFieldsLoading(prev => ({ ...prev, actual_files: loading }))
          }
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          disabled={disabled || response?.stage === 'Заключен'}
        />
        <FieldWithPreview.MultiFile
          control={contractForm.control}
          path="basis_files"
          name="Файл основания (контракт, обеспечение, доп.соглашение/договор/счет)"
          edit={response?.stage !== 'Заключен' && isFieldEditable('basis_files')}
          onFieldSelect={() => selectField('basis_files')}
          ref={createFieldRef('basis_files')}
          onLoadingChange={loading =>
            setFileFieldsLoading(prev => ({ ...prev, basis_files: loading }))
          }
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          disabled={disabled || response?.stage === 'Заключен'}
        />

        {values['fz'] === '44-ФЗ' && (
          <>
            <Divider />
            <Text
              variant="body-2"
              className="font-semibold uppercase"
            >
              Обеспечение исполнения контракта и гарантийных обязательств
            </Text>
          </>
        )}

        {/* Обеспечение исполнения контракта и гарантийных обязательств */}
        <FieldWithPreview.Radio
          show={values['fz'] === '44-ФЗ'}
          ref={createFieldRef('has_smp_restriction')}
          control={contractForm.control}
          path="has_smp_restriction"
          defaultOption={
            response?.has_smp_restriction !== undefined
              ? {
                  value: response.has_smp_restriction,
                  content: response.has_smp_restriction,
                }
              : undefined
          }
          edit={isFieldEditable('has_smp_restriction')}
          onFieldSelect={() => selectField('has_smp_restriction')}
          name="Есть преимущество/ограничение для СМП?"
          options={CONTRACT_OPTIONS['has_smp_restriction']}
          resetSelectedField={resetSelectedField}
          radioButtonProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Radio
          show={values['fz'] === '44-ФЗ'}
          ref={createFieldRef('has_contract_security')}
          control={contractForm.control}
          path="has_contract_security"
          edit={isFieldEditable('has_contract_security')}
          defaultOption={
            response?.has_contract_security !== undefined
              ? {
                  value: response.has_contract_security,
                  content: response.has_contract_security,
                }
              : undefined
          }
          onFieldSelect={() => selectField('has_contract_security')}
          name="Обеспечение исполнения контракта есть"
          options={CONTRACT_OPTIONS['has_contract_security']}
          resetSelectedField={resetSelectedField}
          radioButtonProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Currency
          show={values['has_contract_security'] === 'Да'}
          ref={createFieldRef('contract_security_sum')}
          control={contractForm.control}
          path="contract_security_sum"
          edit={isFieldEditable('contract_security_sum')}
          onFieldSelect={() => selectField('contract_security_sum')}
          name="Сумма обеспечения исполнения"
          numberFormatProps={{
            disabled,
            min: 0,
          }}
        />
        <FieldWithPreview.Select
          show={values['has_contract_security'] === 'Да'}
          ref={createFieldRef('contract_security_type')}
          control={contractForm.control}
          path="contract_security_type"
          edit={isFieldEditable('contract_security_type')}
          onFieldSelect={() => selectField('contract_security_type')}
          name="Вид обеспечения исполнения"
          delayFilter={300}
          options={
            getGuaranteeKindQuery?.data
              ?.filter(kind => kind.includes(filterGuaranteeKind))
              .map(kind => ({
                content: kind,
                value: kind,
              })) || []
          }
          defaultOptions={
            response && response.contract_security_type
              ? [
                  {
                    content: response.contract_security_type,
                    value: response.contract_security_type,
                  },
                ]
              : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterGuaranteeKind}
          onFilterChange={filter => setFilterGuaranteeKind(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading: getGuaranteeKindQuery.isFetching || getGuaranteeKindQuery.isLoading,
          }}
        />
        <FieldWithPreview.Date
          show={values['has_contract_security'] === 'Да'}
          ref={createFieldRef('contract_security_term')}
          control={contractForm.control}
          path="contract_security_term"
          edit={isFieldEditable('contract_security_term')}
          onFieldSelect={() => selectField('contract_security_term')}
          name="Срок обеспечения исполнения"
          format={DATE_FORMATS.fullDate}
          formatPreview={DATE_FORMATS.fullDate}
          datePickerProps={{
            placeholder: 'Дата',
            disabled,
          }}
        />
        <FieldWithPreview.Radio
          show={values['fz'] === '44-ФЗ'}
          ref={createFieldRef('has_warranty_security')}
          control={contractForm.control}
          path="has_warranty_security"
          edit={isFieldEditable('has_warranty_security')}
          defaultOption={
            response?.has_warranty_security !== undefined
              ? {
                  value: response.has_warranty_security,
                  content: response.has_warranty_security,
                }
              : undefined
          }
          onFieldSelect={() => selectField('has_warranty_security')}
          name="Обеспечение гарантийных обязательств есть"
          options={CONTRACT_OPTIONS['has_warranty_security']}
          resetSelectedField={resetSelectedField}
          radioButtonProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Currency
          show={values['has_warranty_security'] === 'Да'}
          ref={createFieldRef('warranty_security_sum')}
          control={contractForm.control}
          path="warranty_security_sum"
          edit={isFieldEditable('warranty_security_sum')}
          onFieldSelect={() => selectField('warranty_security_sum')}
          name="Сумма обеспечения гарантийных обязательств"
          numberFormatProps={{
            disabled,
            min: 0,
          }}
        />
        <FieldWithPreview.Select
          show={values['has_warranty_security'] === 'Да'}
          ref={createFieldRef('warranty_security_type')}
          control={contractForm.control}
          path="warranty_security_type"
          edit={isFieldEditable('warranty_security_type')}
          onFieldSelect={() => selectField('warranty_security_type')}
          name="Вид обеспечения гарантийных обязательств"
          options={
            getGuaranteeKindQuery?.data
              ?.filter(kind => kind.includes(filterGuaranteeKind))
              .map(kind => ({
                content: kind,
                value: kind,
              })) || []
          }
          defaultOptions={
            response && response.warranty_security_type
              ? [
                  {
                    content: response.warranty_security_type,
                    value: response.warranty_security_type,
                  },
                ]
              : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterGuaranteeKind}
          onFilterChange={filter => setFilterGuaranteeKind(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading: getGuaranteeKindQuery.isFetching || getGuaranteeKindQuery.isLoading,
          }}
        />
        <FieldWithPreview.Date
          show={values['has_warranty_security'] === 'Да'}
          ref={createFieldRef('warranty_security_term')}
          control={contractForm.control}
          path="warranty_security_term"
          edit={isFieldEditable('warranty_security_term')}
          onFieldSelect={() => selectField('warranty_security_term')}
          name="Срок обеспечения гарантийных обязательств"
          format={DATE_FORMATS.fullDate}
          formatPreview={DATE_FORMATS.fullDate}
          datePickerProps={{
            placeholder: 'Дата',
            disabled,
          }}
        />

        <Divider />
        <Text
          variant="body-2"
          className="font-semibold uppercase"
        >
          Контроль поставок и оплат
        </Text>

        {/* Контроль поставок и оплат  */}
        <FieldWithPreview.Progress
          ref={createFieldRef('progress_percent')}
          control={contractForm.control}
          path="progress_percent"
          edit={isFieldEditable('progress_percent')}
          onFieldSelect={() => selectField('progress_percent')}
          name="Прогресс исполнения"
          sliderProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Currency
          ref={createFieldRef('contract_limit')}
          control={contractForm.control}
          path="contract_limit"
          edit={isFieldEditable('contract_limit')}
          onFieldSelect={() => selectField('contract_limit')}
          name="Лимит контракта"
          numberFormatProps={{
            disabled,
            min: 0,
          }}
        />
        <FieldWithPreview.Currency
          ref={createFieldRef('shipment_sum_with_vat')}
          control={contractForm.control}
          path="shipment_sum_with_vat"
          edit={isFieldEditable('shipment_sum_with_vat')}
          onFieldSelect={() => selectField('shipment_sum_with_vat')}
          name="Сумма отгрузок, с НДС"
          numberFormatProps={{
            disabled,
            min: 0,
          }}
        />
        <FieldWithPreview.Currency
          ref={createFieldRef('shipment_sum_without_vat')}
          control={contractForm.control}
          path="shipment_sum_without_vat"
          edit={isFieldEditable('shipment_sum_without_vat')}
          onFieldSelect={() => selectField('shipment_sum_without_vat')}
          name="Сумма отгрузок, без НДС"
          numberFormatProps={{
            disabled,
            min: 0,
          }}
        />
        <FieldWithPreview.Currency
          ref={createFieldRef('vat_sum')}
          control={contractForm.control}
          path="vat_sum"
          edit={isFieldEditable('vat_sum')}
          onFieldSelect={() => selectField('vat_sum')}
          name="Сумма НДС"
          numberFormatProps={{
            disabled,
            min: 0,
          }}
        />
        <FieldWithPreview.Currency
          ref={createFieldRef('purchase_sum')}
          control={contractForm.control}
          path="purchase_sum"
          edit={isFieldEditable('purchase_sum')}
          onFieldSelect={() => selectField('purchase_sum')}
          name="Сумма закупок"
          numberFormatProps={{
            disabled,
            min: 0,
          }}
        />
        <FieldWithPreview.Currency
          ref={createFieldRef('limit_balance')}
          control={contractForm.control}
          path="limit_balance"
          edit={isFieldEditable('limit_balance')}
          onFieldSelect={() => selectField('limit_balance')}
          name="Остаток лимита"
          numberFormatProps={{
            disabled,
            min: 0,
          }}
        />
        <FieldWithPreview.Currency
          ref={createFieldRef('payment_sum')}
          control={contractForm.control}
          path="payment_sum"
          edit={isFieldEditable('payment_sum')}
          onFieldSelect={() => selectField('payment_sum')}
          name="Сумма оплаты"
          numberFormatProps={{
            disabled,
            min: 0,
          }}
        />
        <FieldWithPreview.Currency
          ref={createFieldRef('receivables_sum')}
          control={contractForm.control}
          path="receivables_sum"
          edit={isFieldEditable('receivables_sum')}
          onFieldSelect={() => selectField('receivables_sum')}
          name="Сумма ДЗ"
          numberFormatProps={{
            disabled,
            min: 0,
          }}
        />
        <FieldWithPreview.Currency
          ref={createFieldRef('overdue_receivables_sum')}
          control={contractForm.control}
          path="overdue_receivables_sum"
          edit={isFieldEditable('overdue_receivables_sum')}
          onFieldSelect={() => selectField('overdue_receivables_sum')}
          name="В т.ч. Сумма ПДЗ"
          numberFormatProps={{
            disabled,
            min: 0,
          }}
        />

        {/* {response && (response.created_at) && (
          <>
            <Divider />
            <Text
              variant="body-2"
              className="font-semibold uppercase"
            >
              Обеспечение исполнения контракта и гарантийных обязательств
            </Text>
          </>
        )} */}
      </FieldWithPreview>
      {!hideBottomPanel && (
        <RightSidebar.BottomPanel>
          <Button
            size="xl"
            view="normal"
            width="max"
            onClick={onClickCancelButton}
            disabled={disabled}
          >
            {cancelButtonText}
          </Button>
          <Button
            size="xl"
            view="action"
            type="submit"
            width="max"
            onClick={contractForm.handleSubmit(onValid, onInvalid)}
            disabled={disabled || isConfirmButtonDisabled}
          >
            {submitButtonText}
          </Button>
        </RightSidebar.BottomPanel>
      )}
    </form>
  );
}
