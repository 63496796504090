export const NOT_ALLOWED_FILE_EXTENSIONS = [
  '7z',
  'ace',
  'arj',
  'bin',
  'bin',
  'cab',
  'cab',
  'cbr',
  'deb',
  'exe',
  'gz',
  'gzip',
  'jar',
  'one',
  'pak',
  'pak',
  'pak',
  'pkg',
  'pkg',
  'pkg',
  'pkg',
  'ppt',
  'rar',
  'rpm',
  'sh',
  'sib',
  'sis',
  'sisx',
  'sit',
  'sitx',
  'spl',
  'spl',
  'tar',
  'tar-gz',
  'tgz',
  'xar',
  'zip',
  'zip',
  'zipx',
];
