import { components } from '@shared/api';
import { yupRu } from '@shared/config';

type OfferCreation = components['schemas']['offer.OfferCreation'];
type CommercialOfferPurpose = NonNullable<OfferCreation['purpose']>;
type CommercialOfferStatus = NonNullable<OfferCreation['status']>;
type CommercialOfferBasisPurchase = NonNullable<OfferCreation['basis_purchase']>;
type CommercialOfferRejectReason = NonNullable<
  components['schemas']['offer.OfferMsg']['reject_reason']
>;
type CommercialOfferWinnerType = NonNullable<OfferCreation['winner_type']>;

const saveCommercialOfferSchema = {
  // Работа с заказчиком
  /** @description Наименование КП @gotags: validate:"required|min_len:3|max_len:255" */
  title: yupRu
    .string()
    .max(255)
    .required()
    .test({
      test: value => value.length >= 3,
    }),
  /** @description Ответственный @gotags: validate:"required" */
  responsible_id: yupRu.string().required(),
  /** @description Заказчик @gotags: validate:"required" */
  customer_id: yupRu.string().required(),
  // Начальные параметры
  /** @description Цель КП @gotags: validate:"required|min_len:3|max_len:255" */
  purpose: yupRu
    .string()
    .oneOf<CommercialOfferPurpose>(['Закупка ЕП', 'Обоснование НМЦК'])
    .required(),
  /** @description Статус КП @gotags: enums:"Подача,Ожидание,Торги опубликованы,Выигран,Проигран,Отказ,Отмена,Не отработано,Не подано,Торги не отработаны" validate:"required,min_len:3,max_len:255 */
  status: yupRu
    .string()
    .oneOf<CommercialOfferStatus>([
      'Подача',
      'Ожидание',
      'Торги опубликованы',
      'Выигран',
      'Проигран',
      'Отказ',
      'Отмена',
      'Не подано',
      'Торги не отработаны',
    ])
    .required()
    .when('purpose', {
      is: (value: CommercialOfferPurpose) => value === 'Закупка ЕП',
      then: schema =>
        schema.oneOf<CommercialOfferStatus>([
          'Выигран',
          'Проигран',
          'Отказ',
          'Отмена',
          'Подача',
          'Ожидание',
          'Не подано',
        ]),
      otherwise: schema =>
        schema.oneOf<CommercialOfferStatus>([
          'Торги опубликованы',
          'Торги не отработаны',
          'Отказ',
          'Отмена',
          'Подача',
          'Ожидание',
          'Не подано',
        ]),
    }),

  /** @description Причина отказа @gotags: enums:"Не успели рассчитать,Не успели подготовить заявку,Низкая маржа начальная,Иная причина" */
  reject_reason: yupRu
    .string()
    .oneOf<CommercialOfferRejectReason>([
      'Иная причина',
      'Не успели подготовить заявку',
      'Не успели рассчитать',
      'Низкая маржа начальная',
    ]),
  reject_description: yupRu.string().min(3).max(255),
  tender_id: yupRu.string().when('status', {
    is: (status: string) => status === 'Торги опубликованы',
    then: schema => schema.required(),
  }),
  /** @description Дата перевода @gotags: swaggertype:"string" */
  transfer_at: yupRu.string(),
  /** @description Регион поставки @gotags: validate:"required|min_len:3|max_len:255" */
  delivery_region_id: yupRu.string().required(),
  /** @description Адрес поставки @gotags: validate:"required|min_len:3|max_len:255" */
  delivery_address: yupRu.string().required(),
  /** @description Срок поставки @gotags: validate:"required|min_len:3|max_len:255" */
  delivery_deadline: yupRu.string().min(3).max(255).required(),
  /** @description Основание закупки @gotags: validate:"required|min_len:3|max_len:255" */
  basis_purchase: yupRu
    .string()
    .oneOf<CommercialOfferBasisPurchase>([
      'Прямая закупка без договора',
      'Прямая закупка с договором',
    ])
    .when(['purpose', 'status'], {
      is: (purpose: CommercialOfferPurpose, status: CommercialOfferStatus) =>
        purpose === 'Закупка ЕП' && status === 'Выигран',
      then: schema => schema.required(),
      otherwise: schema => schema.transform(() => undefined),
    }),
  /** @description Окончание подачи @gotags: validate:"required" @gotags: swaggertype:"string" */
  submission_deadline: yupRu.string().required(),
  /** @description Срок проведения закупки по @gotags: swaggertype:"string" */
  producement_end_at: yupRu.string().transform(value => (value === null ? undefined : value)),
  /** @description Срок проведения закупки с @gotags: swaggertype:"string" */
  producement_start_at: yupRu.string().transform(value => (value === null ? undefined : value)),
  /** @description Ссылки на Документы */
  documents: yupRu
    // .array(yupRu.mixed<FieldWithPreviewMultiFileItem>().required())
    .array(yupRu.string().required()),
  // .test({
  //   name: 'file-sizes',
  //   test: function (value) {
  //     const totalSize = value.reduce((size, { file }) => size + (file?.size || 0), 0);

  //     if (totalSize > MAX_FILE_SIZE) {
  //       return this.createError({
  //         message: `Общий вес файлов (${formatBytes(totalSize)}) превышает ${formatBytes(MAX_FILE_SIZE)}`,
  //       });
  //     }

  //     return true;
  //   },
  // })
  /** @description Объект закупки */
  purchase_object_id: yupRu.string().when('status', {
    is: (status: CommercialOfferStatus) => status === 'Выигран',
    then: schema => schema.required(),
    otherwise: schema => schema.transform(v => v || undefined),
  }),
  /** @description Комментарий @gotags: validate:"min_len:3|max_len:255" */
  comment: yupRu
    .string()
    .max(255)
    .trim()
    .test({
      test: value => !value || value.length >= 3,
      message: 'Должен содержать как минимум 3 символа',
    }),

  // Рабочие параметры
  /** @description Файл расчёта @gotags: validate:"required" */
  // calculation_file: yupRu.mixed<File>().fileSizeLessThan(MAX_FILE_SIZE),
  calculation_file: yupRu.string(),
  /** @description Себестоимость @gotags: validate:"required" */
  cost_price: yupRu
    .number()
    .test({ test: value => value !== 0, message: 'Не может быть равным 0' }),
  /** @description Цена предложения @gotags: validate:"required|min:0" */
  offer_price: yupRu
    .number()
    .test({ test: value => value !== 0, message: 'Не может быть равным 0' }),
  /** @description Маржинальность @gotags: validate:"required|min:0" */
  margin: yupRu
    .number()
    .max(100)
    .test({ test: value => value !== 100, message: 'Не может быть равным 100' }),
  /** @description Файл КП @gotags: validate:"required" */
  // offer_file: yupRu.mixed<File>().fileSizeLessThan(MAX_FILE_SIZE),
  offer_file: yupRu.string(),
  /** @description Поставщик */
  provider_id: yupRu.string(),
  /** @description Предлагаемая специалистом цена @gotags: validate:"required|min:0" */
  proposed_price: yupRu
    .number()
    .test({ test: value => value !== 0, message: 'Не может быть равным 0' })
    .when('purpose', {
      is: (purpose: CommercialOfferPurpose) => purpose === 'Обоснование НМЦК',
      then: schema => schema.transform(() => undefined),
    }),
  /** @description Обоснование предложения цены @gotags: validate:"min_len:3|max_len:255" */
  price_justification: yupRu
    .string()
    .trim()
    .min(3)
    .max(255)
    .test({
      test: value => !value || value.length >= 3,
      message: 'Должен содержать как минимум 3 символа',
    })
    .when('purpose', {
      is: (purpose: CommercialOfferPurpose) => purpose === 'Обоснование НМЦК',
      then: schema => schema.transform(() => undefined),
    }),

  /** @description Комментарий согласования @gotags: validate:"min_len:3|max_len:255" */
  approval_comment: yupRu.string(),

  //  Итоговые  параметры
  /** @description Итоговая цена @gotags: validate:"min:0" */
  final_price: yupRu
    .number()
    .test({ test: value => value !== 0, message: 'Не может быть равным 0' })
    .when(['purpose', 'status'], {
      is: (purpose: CommercialOfferPurpose, status: CommercialOfferStatus) =>
        purpose === 'Закупка ЕП' && (status === 'Выигран' || status === 'Проигран'),
      then: schema => schema.required(),
      otherwise: schema => schema.transform(() => undefined),
    }),
  /** @description Победитель */
  winner_id: yupRu.string().when(['purpose', 'status'], {
    is: (purpose: CommercialOfferPurpose, status: CommercialOfferStatus) =>
      purpose === 'Закупка ЕП' && (status === 'Выигран' || status === 'Проигран'),
    then: schema => schema.required(),
    otherwise: schema => schema.transform(() => undefined),
  }),
  /** @description Тип победителя */
  winner_type: yupRu
    .string()
    .oneOf<CommercialOfferWinnerType>(['competitor', 'legal_entity'])
    .when(['purpose', 'status'], {
      is: (purpose: CommercialOfferPurpose, status: CommercialOfferStatus) =>
        purpose === 'Закупка ЕП' && (status === 'Выигран' || status === 'Проигран'),
      then: schema => schema.required(),
      otherwise: schema => schema.transform(() => undefined),
    }),
  winner_with_type: yupRu
    .string<`${string}/${'legal_entity' | 'competitor'}`>()
    .transform(() => undefined),
  /** @description Согласование с руководителем @gotags: validate:"required" */
  /** @description Руководитель ответственного */
  responsible_manager_id: yupRu.string(),
  is_under_approval: yupRu.bool(),
};

export const commercialOfferSchema = yupRu.object(saveCommercialOfferSchema);

export type CommercialOfferSchema = yupRu.InferType<typeof commercialOfferSchema>;
