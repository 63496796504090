import { Button, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { bodyResponseType } from '@shared/api';
import { useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview, RightSidebar } from '@shared/ui';

import { agenciesSchema, AgenciesSchema } from '../model';

interface AgenciesFormProps {
  onValid: SubmitHandler<AgenciesSchema>;
  onValuesChange?: (values: AgenciesSchema) => void;
  response?: bodyResponseType<'get', '/handbook/agencies/{id}'>;
  onInvalid?: SubmitErrorHandler<AgenciesSchema>;
  onClickCancelButton?: () => void;
  initialEditable?: boolean;
  disabled?: boolean;
  hideBottomPanel: boolean;
  rightSidebarButtons?: boolean;
  defaultValues?: AgenciesSchema;
  hideTitleField?: boolean;
  cancelButtonText?: string;
  submitButtonText?: string;
}

export function AgenciesForm({
  onValid,
  onValuesChange,
  response,
  onInvalid,
  initialEditable = false,
  onClickCancelButton,
  disabled = false,
  hideBottomPanel = false,
  defaultValues,
  hideTitleField = false,
  cancelButtonText = 'Отмена',
  submitButtonText = 'Применить',
}: AgenciesFormProps) {
  const { add } = useToaster();

  const form = useForm<AgenciesSchema>({
    resolver: yupResolver(agenciesSchema),
    mode: 'all',
    defaultValues,
    resetOptions: {
      keepDirtyValues: true,
      keepDefaultValues: false,
    },
  });

  const { createFieldRef, isFieldEditable, selectField } = useFieldsWithPreview({
    form: form,
    initialEditable,
    disabled,
  });

  const values = form.watch();

  useDeepCompareEffect(() => {
    onValuesChange?.(values);
  }, [values]);

  useDeepCompareEffect(() => {
    if (response) form.reset(response);
  }, [response || {}]);

  useEffect(() => {
    if (Object.values(form.formState.errors).length > 0 && form.formState.isSubmitted)
      add({
        title: 'Не все обязательные поля заполнены',
        name: 'validation-fields-error',
        theme: 'warning',
      });
  }, [form.formState.isSubmitted]);

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={form.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        <FieldWithPreview.Text
          ref={createFieldRef('name')}
          show={!hideTitleField}
          control={form.control}
          path="name"
          name="Краткое наименование"
          edit={isFieldEditable('name')}
          onFieldSelect={() => selectField('name')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('inn')}
          control={form.control}
          path="inn"
          name="ИНН"
          edit={isFieldEditable('inn')}
          onFieldSelect={() => selectField('inn')}
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('comment')}
          control={form.control}
          path="comment"
          name="Комментарий"
          edit={isFieldEditable('comment')}
          onFieldSelect={() => selectField('comment')}
          wrapperProps={{ textAlign: 'start' }}
          textAreaProps={{ disabled }}
        />
      </FieldWithPreview>
      {!hideBottomPanel && (
        <RightSidebar.BottomPanel>
          <Button
            size="xl"
            view="normal"
            width="max"
            onClick={onClickCancelButton}
            disabled={disabled}
          >
            {cancelButtonText}
          </Button>
          <Button
            size="xl"
            view="action"
            type="submit"
            width="max"
            onClick={form.handleSubmit(onValid, onInvalid)}
            disabled={disabled}
          >
            {submitButtonText}
          </Button>
        </RightSidebar.BottomPanel>
      )}
    </form>
  );
}
