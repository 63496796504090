import { yupRu } from '@shared/config';

export const regionsSchema = yupRu.object({
  /** @description Код региона */
  code: yupRu.number().min(0).max(999),
  /** @description Наименование @gotags: validate:"required" */
  name: yupRu.string().required().max(255),
});

export type RegionsSchema = yupRu.InferType<typeof regionsSchema>;
