import { Button, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { OrganizationType, useGetHandbookOrganizationsInfiniteQuery } from '@entities';
import { bodyResponseType } from '@shared/api';
import { useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview, RightSidebar } from '@shared/ui';

import { CONTACT_OPTIONS } from '../consts';
import { contactsSchema, ContactsSchema } from '../model';

interface ContactsFormProps {
  onValid: SubmitHandler<ContactsSchema>;
  onValuesChange?: (values: ContactsSchema) => void;
  response?: bodyResponseType<'get', '/handbook/contacts/{id}'>;
  onInvalid?: SubmitErrorHandler<ContactsSchema>;
  onClickCancelButton?: () => void;
  initialEditable?: boolean;
  disabled?: boolean;
  hideBottomPanel: boolean;
  rightSidebarButtons?: boolean;
  defaultValues?: ContactsSchema;
  hideTitleField?: boolean;
  cancelButtonText?: string;
  submitButtonText?: string;
}

export function ContactsForm({
  onValid,
  onValuesChange,
  response,
  onInvalid,
  initialEditable = false,
  onClickCancelButton,
  disabled = false,
  hideBottomPanel = false,
  defaultValues,
  hideTitleField = false,
  cancelButtonText = 'Отмена',
  submitButtonText = 'Применить',
}: ContactsFormProps) {
  const { add } = useToaster();

  const form = useForm<ContactsSchema>({
    resolver: yupResolver(contactsSchema),
    mode: 'all',
    defaultValues,
    resetOptions: {
      keepDirtyValues: true,
      keepDefaultValues: false,
    },
  });

  const { createFieldRef, isFieldEditable, selectField, selectedFieldPath, resetSelectedField } =
    useFieldsWithPreview({
      form: form,
      initialEditable,
      disabled,
    });

  const values = form.watch();

  // Queries
  const [filterOrganizations, setFilterOrganizations] = useState('');
  const getHandbookOrganizationsInfiniteQuery = useGetHandbookOrganizationsInfiniteQuery(
    {
      query: filterOrganizations,
      limit: 30,
    },
    selectedFieldPath === 'organization_id'
  );
  const flattedOrganizations = useMemo(
    () => getHandbookOrganizationsInfiniteQuery.data?.pages.flat(),
    [getHandbookOrganizationsInfiniteQuery.data]
  );
  //

  useDeepCompareEffect(() => {
    onValuesChange?.(values);
  }, [values]);

  useDeepCompareEffect(() => {
    if (response) form.reset(response);
  }, [response || {}]);

  useEffect(() => {
    if (Object.values(form.formState.errors).length > 0 && form.formState.isSubmitted)
      add({
        title: 'Не все обязательные поля заполнены',
        name: 'validation-fields-error',
        theme: 'warning',
      });
  }, [form.formState.isSubmitted]);

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={form.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        <FieldWithPreview.Text
          ref={createFieldRef('full_name')}
          control={form.control}
          show={!hideTitleField}
          path="full_name"
          name="ФИО"
          edit={isFieldEditable('full_name')}
          onFieldSelect={() => selectField('full_name')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Select
          ref={createFieldRef('type')}
          control={form.control}
          path="type"
          name="Тип контактного лица"
          edit={isFieldEditable('type')}
          options={CONTACT_OPTIONS.type}
          defaultOptions={
            response?.type ? [{ content: response.type, value: response.type }] : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          onFieldSelect={() => selectField('type')}
          selectProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('status')}
          control={form.control}
          path="status"
          name="Статус контактного лица"
          edit={isFieldEditable('status')}
          options={CONTACT_OPTIONS.status}
          defaultOptions={
            response?.status ? [{ content: response.status, value: response.status }] : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          onFieldSelect={() => selectField('status')}
          selectProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('position')}
          control={form.control}
          path="position"
          name="Должность"
          edit={isFieldEditable('position')}
          onFieldSelect={() => selectField('position')}
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.Phone
          ref={createFieldRef('phone')}
          control={form.control}
          path="phone"
          name="Телефон"
          edit={isFieldEditable('phone')}
          onFieldSelect={() => selectField('phone')}
          patternFormatProps={{ disabled }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('email')}
          control={form.control}
          path="email"
          name="Эл. адрес"
          edit={isFieldEditable('email')}
          onFieldSelect={() => selectField('email')}
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('comment')}
          control={form.control}
          path="comment"
          name="Комментарий"
          edit={isFieldEditable('comment')}
          onFieldSelect={() => selectField('comment')}
          wrapperProps={{ textAlign: 'start' }}
          textAreaProps={{ disabled }}
        />
        <FieldWithPreview.Select
          control={form.control}
          path="organization_with_type"
          name="Организация"
          edit={isFieldEditable('organization_with_type')}
          ref={createFieldRef('organization_with_type')}
          onFieldSelect={() => selectField('organization_with_type')}
          options={
            flattedOrganizations?.map(organization => ({
              content: organization.name,
              value: organization.id + '/' + organization.type,
            })) || []
          }
          defaultOptions={
            response && response.organization_id && response.organization_type
              ? [
                  {
                    content: response.organization_value,
                    value: response.organization_id + '/' + response.organization_type,
                  },
                ]
              : undefined
          }
          filter={filterOrganizations}
          onFilterChange={filter => setFilterOrganizations(filter)}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            onUpdate: ([organizationWithType]) => {
              if (organizationWithType) {
                const [organizationId, organizationType] = organizationWithType.split('/');
                form.setValue('organization_id', organizationId);
                form.setValue('organization_type', organizationType as OrganizationType);
              } else {
                form.resetField('organization_type');
                form.resetField('organization_id');
              }
            },
            loading:
              getHandbookOrganizationsInfiniteQuery.isFetching ||
              getHandbookOrganizationsInfiniteQuery.isFetchingNextPage ||
              getHandbookOrganizationsInfiniteQuery.isLoading ||
              getHandbookOrganizationsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookOrganizationsInfiniteQuery.fetchNextPage();
            },
          }}
        />
      </FieldWithPreview>
      {!hideBottomPanel && (
        <RightSidebar.BottomPanel>
          <Button
            size="xl"
            view="normal"
            width="max"
            onClick={onClickCancelButton}
            disabled={disabled}
          >
            {cancelButtonText}
          </Button>
          <Button
            size="xl"
            view="action"
            type="submit"
            width="max"
            onClick={form.handleSubmit(onValid, onInvalid)}
            disabled={disabled}
          >
            {submitButtonText}
          </Button>
        </RightSidebar.BottomPanel>
      )}
    </form>
  );
}
