import { dateTime } from '@gravity-ui/date-utils';
import { Button, ButtonProps, Divider, Text, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm, UseFormProps } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

import {
  $customersHooks,
  $employeesHooks,
  $purchaseObjectsHooks,
  $regionsHooks,
  $suppliersHooks,
  $tenderHooks,
  CommercialOfferStatuses,
  useGetHandbookWinnersInfiniteQuery,
  userAtom,
} from '@entities';
import { bodyResponseType } from '@shared/api';
import { DATE_FORMATS, MAX_FILE_SIZE, NOT_ALLOWED_FILE_EXTENSIONS } from '@shared/consts';
import { calculateMargin, calculatePrice, maxDateTime, useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview, RightSidebar } from '@shared/ui';

import { DEFAULT_VALUES, ENUM_OPTIONS } from '../constants';
import { generateTitle, getAgreementSubmit, getIsSubmissionDeadlineExpired } from '../lib';
import { commercialOfferSchema, CommercialOfferSchema, createdTenderForViewAtom } from '../model';

export interface CommercialOfferFormProps {
  response?: bodyResponseType<'get', '/offer/{id}'>;
  defaultValues?: UseFormProps<CommercialOfferSchema>['defaultValues'];
  onValuesChange?: (values: CommercialOfferSchema) => void;
  initialEditable?: boolean;
  hide?: boolean;
  disabled?: boolean;
  allowedStatuses: (values: CommercialOfferSchema) => CommercialOfferStatuses[];
  submitButtonText?: string;
  cancelButtonText?: string;
  onClickCancelButton?: () => void;
  approvalButtonProps?: Pick<ButtonProps, 'onClick' | 'disabled'>;
  hideTitleField?: boolean;
  hideBottomPanel?: boolean;
  onTileChange?: (title: string) => void;
  onValid?: SubmitHandler<CommercialOfferSchema>;
  onInvalid?: SubmitErrorHandler<CommercialOfferSchema>;
  onClickCreateCustomerHandbook: () => void;
  onClickCreateRegionsHandbook: () => void;
  onClickCreatePurchaseObjectsHandbook: () => void;
  onClickCreateLegalEntitiesHandbook: () => void;
  onClickCreateCompetitorsHandbook: () => void;
  onClickCreateTender: () => void;
}

export const CommercialOfferForm = ({
  onValuesChange,
  response,
  defaultValues,
  initialEditable = false,
  hide = false,
  disabled,
  hideTitleField = false,
  hideBottomPanel = false,
  allowedStatuses: getAllowedStatuses,
  submitButtonText = 'Применить',
  cancelButtonText = 'Отмена',
  onClickCancelButton,
  onTileChange,
  onValid = () => {},
  onInvalid,
  onClickCreateCustomerHandbook,
  onClickCreateRegionsHandbook,
  onClickCreatePurchaseObjectsHandbook,
  onClickCreateCompetitorsHandbook,
  onClickCreateLegalEntitiesHandbook,
  onClickCreateTender,
}: CommercialOfferFormProps) => {
  const [createdTenderForView, setCreatedTenderForView] = useAtom(createdTenderForViewAtom);

  const commercialOfferForm = useForm<CommercialOfferSchema>({
    resolver: yupResolver(commercialOfferSchema),
    mode: 'all',
    defaultValues: { ...DEFAULT_VALUES, ...defaultValues },
    resetOptions: {
      keepDirtyValues: true,
      keepDefaultValues: false,
    },
  });

  const { createFieldRef, isFieldEditable, resetSelectedField, selectField, selectedFieldPath } =
    useFieldsWithPreview({
      form: commercialOfferForm,
      initialEditable,
      disabled,
    });

  const { add } = useToaster();

  const values = commercialOfferForm.watch();

  useDeepCompareEffect(() => {
    onValuesChange?.(values);
  }, [values]);

  // atoms
  const currentUser = useAtomValue(userAtom);
  //

  // Queries
  const [filterEmployees, setFilterEmployees] = useState('');
  const getHandbookEmployeesInfiniteQuery = $employeesHooks.useGetAll(
    {
      query: filterEmployees,
      limit: 30,
    },
    selectedFieldPath === 'responsible_id'
  );
  const flattedEmployees = useMemo(
    () => getHandbookEmployeesInfiniteQuery.data?.pages.flat(),
    [getHandbookEmployeesInfiniteQuery.data]
  );

  const [filterCustomers, setFilterCustomers] = useState('');
  const getHandbookCustomersInfiniteQuery = $customersHooks.useGetAll(
    {
      query: filterCustomers,
      limit: 30,
    },
    selectedFieldPath === 'customer_id'
  );
  const flattedCustomers = useMemo(
    () => getHandbookCustomersInfiniteQuery.data?.pages.flat().filter(data => !!data),
    [getHandbookCustomersInfiniteQuery.data]
  );

  const [filterTenders, setFilterTenders] = useState('');
  const getTendersInfiniteQuery = $tenderHooks.getAll(
    {
      search_value: filterTenders,
      limit: 30,
    },
    selectedFieldPath === 'tender_id' || !!createdTenderForView
  );
  const flattedTenders = useMemo(
    () => getTendersInfiniteQuery.data?.pages.flat(),
    [getTendersInfiniteQuery.data]
  );

  const [filterRegions, setFilterRegions] = useState('');
  const getHandbookRegionsInfiniteQuery = $regionsHooks.useGetAll(
    {
      query: filterRegions,
      limit: 30,
    },
    selectedFieldPath === 'delivery_region_id'
  );
  const flattedRegions = useMemo(
    () => getHandbookRegionsInfiniteQuery.data?.pages.flat(),
    [getHandbookRegionsInfiniteQuery.data]
  );

  const [filterPurchaseObjects, setFilterPurchaseObjects] = useState('');
  const getHandbookPurchaseObjectsInfiniteQuery = $purchaseObjectsHooks.useGetAll(
    {
      query: filterPurchaseObjects,
      limit: 30,
    },
    selectedFieldPath === 'purchase_object_id'
  );
  const flattedPurchaseObjects = useMemo(
    () => getHandbookPurchaseObjectsInfiniteQuery.data?.pages.flat(),
    [getHandbookPurchaseObjectsInfiniteQuery.data]
  );

  const [filterSuppliers, setFilterSuppliers] = useState('');
  const getHandbookSuppliersInfiniteQuery = $suppliersHooks.useGetAll(
    {
      query: filterSuppliers,
      limit: 30,
    },
    selectedFieldPath === 'provider_id'
  );
  const flattedSuppliers = useMemo(
    () => getHandbookSuppliersInfiniteQuery.data?.pages.flat(),
    [getHandbookSuppliersInfiniteQuery.data]
  );

  const [filterWinners, setFilterWinners] = useState('');
  const getHandbookWinnersInfiniteQuery = useGetHandbookWinnersInfiniteQuery(
    {
      query: filterWinners,
      limit: 30,
    },
    selectedFieldPath === 'winner_id' &&
      (values.status === 'Выигран' || values.status === 'Проигран')
  );
  const flattedWinners = useMemo(
    () =>
      getHandbookWinnersInfiniteQuery.data?.pages
        .flat()
        .filter(winner =>
          values.status === 'Проигран'
            ? winner.type === 'competitor'
            : winner.type === 'legal_entity'
        ),
    [
      getHandbookWinnersInfiniteQuery.data,
      values.status === 'Выигран',
      values.status === 'Проигран',
    ]
  );
  //

  const [filterStatus, setFilterStatus] = useState('');
  const [filterPurpose, setFilterPurpose] = useState('');
  const allowedStatuses = getAllowedStatuses(values);
  const allowedStatusOptions = ENUM_OPTIONS.status.filter(statusOption =>
    allowedStatuses.some(allowedStatus => allowedStatus === statusOption.value)
  );

  const [filterBasisPurchase, setFilterBasisPurchase] = useState('');
  const [filterRejectReason, setFilterRejectReason] = useState('');

  useEffect(() => {
    if (createdTenderForView) {
      commercialOfferForm.setValue('tender_id', createdTenderForView.tender_id);
    }
  }, [createdTenderForView]);

  useEffect(() => {
    const allowedStatuses = getAllowedStatuses(values);
    if (allowedStatuses.every(allowedStatus => allowedStatus !== values.status)) {
      commercialOfferForm.resetField('status', { defaultValue: undefined });
    }
  }, [values.purpose, values.status]);

  useEffect(() => {
    if (values.customer_id && values.submission_deadline && flattedCustomers) {
      const customer = flattedCustomers.find(customer => customer.id === values.customer_id)?.name;
      const title =
        generateTitle({ customer, submissionDeadline: values.submission_deadline }) ?? '';
      commercialOfferForm.setValue('title', title);
      onTileChange?.(title);
    }
  }, [values.customer_id, values.submission_deadline]);

  const submissionDeadlineDate = values.submission_deadline
    ? dateTime({ input: values.submission_deadline })
    : undefined;

  const producementStartAtDate = values.producement_start_at
    ? dateTime({ input: values.producement_start_at })
    : undefined;

  useEffect(() => {
    const producementEndAtDate = values.producement_end_at
      ? dateTime({ input: values.producement_end_at })
      : undefined;

    if (
      producementEndAtDate &&
      (producementStartAtDate?.isAfter(producementEndAtDate) ||
        submissionDeadlineDate?.isAfter(producementEndAtDate))
    ) {
      commercialOfferForm.resetField('producement_end_at', {
        defaultValue: undefined,
      });
    }
  }, [values.submission_deadline, values.producement_start_at, values.producement_end_at]);

  useEffect(() => {
    if (values.status === 'Отказ') {
      const isSubmissionDeadlineExpired = getIsSubmissionDeadlineExpired(
        values.submission_deadline
      );

      if (isSubmissionDeadlineExpired) {
        commercialOfferForm.resetField('status');
        return add({
          name: 'commercial-offer-cannot-reject',
          theme: 'danger',
          title:
            'Невозможно отказаться после 16:00 (МСК) предшествующего дня окончания подачи заявки',
        });
      }
    }
  }, [values.status, values.submission_deadline]);

  const [fileFieldsLoading, setFileFieldsLoading] = useState<Record<string, boolean>>({});

  const isFilesLoading = Object.values(fileFieldsLoading).some(
    fileFieldLoading => fileFieldLoading
  );

  useDeepCompareEffect(() => {
    if (response)
      commercialOfferForm.reset({
        ...response,
        winner_with_type:
          response.winner_id && response.winner_type
            ? response.winner_id + '/' + response.winner_type
            : undefined,
      });
  }, [response || {}]);

  useEffect(() => {
    if (
      Object.values(commercialOfferForm.formState.errors).length > 0 &&
      commercialOfferForm.formState.isSubmitted
    )
      add({
        title: 'Не все обязательные поля заполнены',
        name: 'validation-fields-error',
        theme: 'warning',
      });
  }, [commercialOfferForm.formState.isSubmitted]);

  const submitButtonTextForAgreement = useMemo(
    () => getAgreementSubmit(values, !!response?.is_blocked_work_params),
    [values]
  );

  console.log(values);
  console.log(
    response && response.winner_id && response.winner_type
      ? [
          {
            content: response.winner_value,
            value: response.winner_id + '/' + response.winner_type,
          },
        ]
      : undefined
  );

  if (hide) return null;

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={commercialOfferForm.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        <Text
          variant="body-2"
          className="font-semibold uppercase"
        >
          Работа с заказчиком
        </Text>
        {/* Работа с заказчиком */}
        <FieldWithPreview.Text
          control={commercialOfferForm.control}
          name="Наименование"
          show={!hideTitleField}
          path="title"
          edit={false}
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('responsible_id')}
          control={commercialOfferForm.control}
          path="responsible_id"
          edit={isFieldEditable('responsible_id', { whenDirty: false })}
          onFieldSelect={() => selectField('responsible_id')}
          name="Ответственный"
          options={
            flattedEmployees?.map(employee => ({
              content: employee?.name_initials,
              value: employee.id,
            })) || []
          }
          defaultOptions={
            response
              ? [
                  {
                    content: response.responsible_value,
                    value: response.responsible_id,
                  },
                ]
              : currentUser
                ? [
                    {
                      content: currentUser.name_initials,
                      value: currentUser.id,
                    },
                  ]
                : []
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          filter={filterEmployees}
          onFilterChange={filter => setFilterEmployees(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookEmployeesInfiniteQuery.isFetching ||
              getHandbookEmployeesInfiniteQuery.isFetchingNextPage ||
              getHandbookEmployeesInfiniteQuery.isLoading ||
              getHandbookEmployeesInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookEmployeesInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.Select
          control={commercialOfferForm.control}
          path="customer_id"
          name="Заказчик"
          edit={isFieldEditable('customer_id')}
          ref={createFieldRef('customer_id')}
          options={
            flattedCustomers?.map(customer => ({
              content: customer.name,
              value: customer.id,
            })) || []
          }
          defaultOptions={
            response
              ? [
                  {
                    content: response.customer_value,
                    value: response.customer_id,
                  },
                ]
              : []
          }
          filter={filterCustomers}
          onFilterChange={filter => setFilterCustomers(filter)}
          onPlusClick={onClickCreateCustomerHandbook}
          onFieldSelect={() => selectField('customer_id')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            loading:
              getHandbookCustomersInfiniteQuery.isFetching ||
              getHandbookCustomersInfiniteQuery.isFetchingNextPage ||
              getHandbookCustomersInfiniteQuery.isLoading ||
              getHandbookCustomersInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookCustomersInfiniteQuery.fetchNextPage();
            },
          }}
        />

        <Divider />
        <Text
          variant="body-2"
          className="font-semibold uppercase"
        >
          Начальные параметры
        </Text>

        {/* Начальные параметры */}
        <FieldWithPreview.Select
          control={commercialOfferForm.control}
          path="purpose"
          name="Цель КП"
          edit={response?.is_blocked_work_params ? false : isFieldEditable('purpose')}
          ref={createFieldRef('purpose')}
          options={ENUM_OPTIONS.purpose.filter(
            option => !filterPurpose || option.value.includes(filterPurpose)
          )}
          filter={filterPurpose}
          delayFilter={0}
          onFilterChange={filter => setFilterPurpose(filter)}
          onFieldSelect={() => selectField('purpose')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            disabled: disabled || !!response?.is_blocked_work_params,
            placeholder: 'Выбрать',
            onUpdate: () => {
              if (values.purpose === 'Закупка ЕП') {
                const offerPrice = values['offer_price'];
                const costPrice = values['cost_price'];
                if (typeof offerPrice === 'number' && typeof costPrice === 'number') {
                  commercialOfferForm.setValue('margin', calculateMargin(offerPrice, costPrice));
                } else {
                  commercialOfferForm.setValue('margin', undefined);
                }
              } else {
                const proposedPrice = values['proposed_price'];
                const costPrice = values['cost_price'];
                if (typeof proposedPrice === 'number' && typeof costPrice === 'number') {
                  commercialOfferForm.setValue('margin', calculateMargin(proposedPrice, costPrice));
                } else {
                  commercialOfferForm.setValue('margin', undefined);
                }
              }
            },
          }}
          required
        />
        <FieldWithPreview.Select
          control={commercialOfferForm.control}
          path="status"
          name="Статус КП"
          edit={isFieldEditable('status')}
          ref={createFieldRef('status')}
          options={allowedStatusOptions.filter(
            option => !filterStatus || option.value.includes(filterStatus)
          )}
          filter={filterStatus}
          delayFilter={0}
          onFilterChange={filter => setFilterStatus(filter)}
          onFieldSelect={() => selectField('status')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          selectProps={{
            placeholder: 'Выбрать',
            disabled: disabled || !allowedStatuses.length,
            onUpdate: ([newStatus]) => {
              if (
                (newStatus === 'Не подано' || newStatus === 'Торги не отработаны') &&
                selectedFieldPath === 'status'
              ) {
                commercialOfferForm.setValue('transfer_at', new Date().toISOString());
              }
            },
          }}
        />
        <FieldWithPreview.Select
          name="Причина отказа"
          show={values['status'] === 'Отказ'}
          control={commercialOfferForm.control}
          path="reject_reason"
          edit={isFieldEditable('reject_reason')}
          ref={createFieldRef('reject_reason')}
          options={ENUM_OPTIONS.reject_reason}
          filter={filterRejectReason}
          delayFilter={0}
          onFilterChange={filter => setFilterRejectReason(filter)}
          onFieldSelect={() => selectField('reject_reason')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled: disabled,
          }}
        />
        <FieldWithPreview.TextArea
          path="reject_description"
          control={commercialOfferForm.control}
          show={values.status === 'Отказ'}
          name="Комментарий к причине отказа"
          edit={isFieldEditable('reject_description')}
          ref={createFieldRef('reject_description')}
          onFieldSelect={() => selectField('reject_description')}
          required
          textAreaProps={{
            disabled,
          }}
          wrapperProps={{
            textAlign: 'start',
          }}
        />
        <FieldWithPreview.Select
          control={commercialOfferForm.control}
          path="tender_id"
          show={values.status === 'Торги опубликованы'}
          name="Тендер"
          edit={isFieldEditable('tender_id')}
          ref={createFieldRef('tender_id')}
          options={
            flattedTenders?.map(tender => ({
              content: tender.title,
              value: tender.id,
            })) || []
          }
          defaultOptions={
            createdTenderForView
              ? [
                  {
                    content: createdTenderForView.tender_value,
                    value: createdTenderForView.tender_id,
                  },
                ]
              : response?.tender_id && response?.tender_value
                ? [
                    {
                      content: response.tender_value.title,
                      value: response.tender_id,
                    },
                  ]
                : []
          }
          delayFilter={300}
          filter={filterTenders}
          onFilterChange={filter => setFilterTenders(filter)}
          onFieldSelect={() => selectField('tender_id')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          onPlusClick={onClickCreateTender}
          selectProps={{
            placeholder: 'Выбрать',
            disabled: disabled,
            loading:
              getTendersInfiniteQuery.isFetching ||
              getTendersInfiniteQuery.isFetchingNextPage ||
              getTendersInfiniteQuery.isLoading ||
              getTendersInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getTendersInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.Date
          control={commercialOfferForm.control}
          show={values.status === 'Не подано' || values.status === 'Торги не отработаны'}
          path="transfer_at"
          name="Дата перевода"
          edit={isFieldEditable('transfer_at')}
          ref={createFieldRef('transfer_at')}
          onFieldSelect={() => selectField('transfer_at')}
          format={DATE_FORMATS.fullDateWithTime}
          formatPreview={DATE_FORMATS.fullDateWithTime}
          datePickerProps={{
            disabled,
            placeholder: 'Дата и время',
          }}
        />
        <FieldWithPreview.Select
          control={commercialOfferForm.control}
          path="delivery_region_id"
          name="Регион поставки"
          edit={isFieldEditable('delivery_region_id')}
          ref={createFieldRef('delivery_region_id')}
          options={
            flattedRegions?.map(region => ({
              content: region.name,
              value: region.id,
            })) || []
          }
          defaultOptions={
            response
              ? [
                  {
                    content: response.delivery_region_value,
                    value: response.delivery_region_id,
                  },
                ]
              : []
          }
          filter={filterRegions}
          onFilterChange={filter => setFilterRegions(filter)}
          onPlusClick={onClickCreateRegionsHandbook}
          onFieldSelect={() => selectField('delivery_region_id')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            loading:
              getHandbookRegionsInfiniteQuery.isFetching ||
              getHandbookRegionsInfiniteQuery.isFetchingNextPage ||
              getHandbookRegionsInfiniteQuery.isLoading ||
              getHandbookRegionsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookRegionsInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.TextArea
          control={commercialOfferForm.control}
          path="delivery_address"
          name="Адрес поставки"
          edit={isFieldEditable('delivery_address')}
          ref={createFieldRef('delivery_address')}
          onFieldSelect={() => selectField('delivery_address')}
          required
          textAreaProps={{
            disabled,
          }}
        />
        <FieldWithPreview.TextArea
          control={commercialOfferForm.control}
          name="Срок поставки"
          path="delivery_deadline"
          edit={isFieldEditable('delivery_deadline')}
          ref={createFieldRef('delivery_deadline')}
          onFieldSelect={() => selectField('delivery_deadline')}
          required
          textAreaProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Select
          control={commercialOfferForm.control}
          show={values.purpose === 'Закупка ЕП' && values.status === 'Выигран'}
          path="basis_purchase"
          name="Основание"
          edit={isFieldEditable('basis_purchase')}
          ref={createFieldRef('basis_purchase')}
          options={ENUM_OPTIONS.basis_purchase.filter(
            option => !filterBasisPurchase || option.value.includes(filterBasisPurchase)
          )}
          filter={filterBasisPurchase}
          delayFilter={0}
          onFilterChange={filter => setFilterBasisPurchase(filter)}
          onFieldSelect={() => selectField('basis_purchase')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
          }}
        />
        <FieldWithPreview.Date
          control={commercialOfferForm.control}
          path="submission_deadline"
          name="Окончание подачи"
          edit={isFieldEditable('submission_deadline')}
          ref={createFieldRef('submission_deadline')}
          onFieldSelect={() => selectField('submission_deadline')}
          format={DATE_FORMATS.fullDate}
          formatPreview={DATE_FORMATS.fullDate}
          datePickerProps={{
            disabled,
            placeholder: 'Дата и время',
          }}
          required
        />
        <FieldWithPreview.Date
          control={commercialOfferForm.control}
          edit={isFieldEditable('producement_start_at')}
          name="Срок проведения закупки с"
          path="producement_start_at"
          format={DATE_FORMATS.fullDateWithTime}
          formatPreview={`с ${DATE_FORMATS.fullDateWithTimeRounded}`}
          datePickerProps={{
            disabled,
            placeholder: 'Начало',
          }}
          onFieldSelect={() => selectField('producement_start_at')}
          ref={createFieldRef('producement_start_at')}
        />
        <FieldWithPreview.Date
          control={commercialOfferForm.control}
          edit={isFieldEditable('producement_end_at')}
          name="Срок проведения закупки по"
          path="producement_end_at"
          format={DATE_FORMATS.fullDateWithTime}
          formatPreview={`по ${DATE_FORMATS.fullDateWithTimeRounded}`}
          datePickerProps={{
            disabled,
            minValue: producementStartAtDate
              ? submissionDeadlineDate
                ? maxDateTime(producementStartAtDate, submissionDeadlineDate)
                : producementStartAtDate
              : submissionDeadlineDate || undefined,
            placeholder: 'Конец',
          }}
          onFieldSelect={() => selectField('producement_end_at')}
          ref={createFieldRef('producement_end_at')}
        />
        <FieldWithPreview.MultiFile
          control={commercialOfferForm.control}
          path="documents"
          name="Документы запроса"
          edit={isFieldEditable('documents')}
          onFieldSelect={() => selectField('documents')}
          ref={createFieldRef('documents')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          onLoadingChange={loading =>
            setFileFieldsLoading(prev => ({ ...prev, documents: loading }))
          }
          disabled={disabled}
        />
        <FieldWithPreview.Select
          control={commercialOfferForm.control}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          path="purchase_object_id"
          name="Объект закупки"
          edit={isFieldEditable('purchase_object_id')}
          required={values.status === 'Выигран'}
          options={
            flattedPurchaseObjects?.map(object => ({
              content: object.object,
              value: object.id,
            })) || []
          }
          defaultOptions={
            response && response.purchase_object_id
              ? [
                  {
                    content: response.purchase_object_value,
                    value: response.purchase_object_id,
                  },
                ]
              : []
          }
          filter={filterPurchaseObjects}
          onFilterChange={filter => setFilterPurchaseObjects(filter)}
          onPlusClick={onClickCreatePurchaseObjectsHandbook}
          ref={createFieldRef('purchase_object_id')}
          onFieldSelect={() => selectField('purchase_object_id')}
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            loading:
              getHandbookPurchaseObjectsInfiniteQuery.isFetching ||
              getHandbookPurchaseObjectsInfiniteQuery.isFetchingNextPage ||
              getHandbookPurchaseObjectsInfiniteQuery.isLoading ||
              getHandbookPurchaseObjectsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookPurchaseObjectsInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.TextArea
          control={commercialOfferForm.control}
          path="comment"
          name="Комментарий"
          edit={isFieldEditable('comment')}
          ref={createFieldRef('comment')}
          onFieldSelect={() => selectField('comment')}
          textAreaProps={{
            disabled,
          }}
          wrapperProps={{
            textAlign: 'start',
          }}
        />

        <Divider />
        <Text
          variant="body-2"
          className="font-semibold uppercase"
        >
          Рабочие параметры
        </Text>

        {/* Рабочие параметры */}
        <FieldWithPreview.File
          control={commercialOfferForm.control}
          path="calculation_file"
          name="Файл расчета"
          edit={response?.is_blocked_work_params ? false : isFieldEditable('calculation_file')}
          ref={createFieldRef('calculation_file')}
          onFieldSelect={() => selectField('calculation_file')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          disabled={disabled || !!response?.is_blocked_work_params}
          onLoadingChange={loading =>
            setFileFieldsLoading(prev => ({ ...prev, calculation_file: loading }))
          }
        />
        <FieldWithPreview.Currency
          control={commercialOfferForm.control}
          path="cost_price"
          name="Себестоимость"
          edit={response?.is_blocked_work_params ? false : isFieldEditable('cost_price')}
          ref={createFieldRef('cost_price')}
          onFieldSelect={() => selectField('cost_price')}
          numberFormatProps={{
            disabled: disabled || !!response?.is_blocked_work_params,
            min: 0,
            onValueChange: ({ floatValue }) => {
              if (
                (typeof floatValue === 'number' && floatValue <= 0) ||
                selectedFieldPath !== 'cost_price'
              )
                return;
              const offerPrice = values['offer_price'];

              if (typeof offerPrice === 'number' && typeof floatValue === 'number') {
                commercialOfferForm.setValue('margin', calculateMargin(offerPrice, floatValue));
              }
            },
          }}
        />
        <FieldWithPreview.Currency
          control={commercialOfferForm.control}
          path="offer_price"
          name="Цена предложения"
          edit={response?.is_blocked_work_params ? false : isFieldEditable('offer_price')}
          ref={createFieldRef('offer_price')}
          onFieldSelect={() => selectField('offer_price')}
          numberFormatProps={{
            disabled: disabled || !!response?.is_blocked_work_params,
            min: 0,
            onValueChange: ({ floatValue }) => {
              if (
                (typeof floatValue === 'number' && floatValue <= 0) ||
                selectedFieldPath !== 'offer_price'
              )
                return;
              if (values.purpose === 'Закупка ЕП') return;
              const costPrice = values['cost_price'];
              if (typeof costPrice === 'number' && typeof floatValue === 'number') {
                commercialOfferForm.setValue('margin', calculateMargin(floatValue, costPrice));
              }
            },
          }}
        />
        <FieldWithPreview.Percentage
          control={commercialOfferForm.control}
          path="margin"
          name="Маржа"
          edit={response?.is_blocked_work_params ? false : isFieldEditable('margin')}
          ref={createFieldRef('margin')}
          onFieldSelect={() => selectField('margin')}
          numberFormatProps={{
            disabled: disabled || !!response?.is_blocked_work_params,
            max: 100,
            onValueChange: ({ floatValue }) => {
              if (
                (typeof floatValue === 'number' && floatValue >= 100) ||
                selectedFieldPath !== 'margin'
              )
                return;
              if (values.purpose === 'Закупка ЕП') {
                const costPrice = values['cost_price'];
                if (typeof costPrice === 'number' && typeof floatValue === 'number') {
                  commercialOfferForm.setValue(
                    'proposed_price',
                    calculatePrice(costPrice, floatValue)
                  );
                }
              } else {
                const costPrice = values['cost_price'];
                if (typeof costPrice === 'number' && typeof floatValue === 'number') {
                  commercialOfferForm.setValue(
                    'offer_price',
                    calculatePrice(costPrice, floatValue)
                  );
                }
              }
            },
          }}
        />
        <FieldWithPreview.File
          control={commercialOfferForm.control}
          path="offer_file"
          name="Файл КП"
          edit={response?.is_blocked_work_params ? false : isFieldEditable('offer_file')}
          ref={createFieldRef('offer_file')}
          onFieldSelect={() => selectField('offer_file')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          onLoadingChange={loading =>
            setFileFieldsLoading(prev => ({ ...prev, offer_file: loading }))
          }
          disabled={disabled || !!response?.is_blocked_work_params}
        />
        <FieldWithPreview.Select
          control={commercialOfferForm.control}
          path="provider_id"
          name="Поставщик"
          edit={response?.is_blocked_work_params ? false : isFieldEditable('provider_id')}
          ref={createFieldRef('provider_id')}
          options={
            flattedSuppliers?.map(supplier => ({
              content: supplier.name,
              value: supplier.id,
            })) || []
          }
          defaultOptions={
            response && response.provider_id
              ? [
                  {
                    content: response.provider_value,
                    value: response.provider_id,
                  },
                ]
              : []
          }
          filter={filterSuppliers}
          onFilterChange={filter => setFilterSuppliers(filter)}
          onPlusClick={onClickCreateLegalEntitiesHandbook}
          onFieldSelect={() => selectField('provider_id')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            disabled: disabled || !!response?.is_blocked_work_params,
            placeholder: 'Выбрать',
            loading:
              getHandbookSuppliersInfiniteQuery.isFetching ||
              getHandbookSuppliersInfiniteQuery.isFetchingNextPage ||
              getHandbookSuppliersInfiniteQuery.isLoading ||
              getHandbookSuppliersInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookSuppliersInfiniteQuery.fetchNextPage();
            },
          }}
        />

        <FieldWithPreview.Currency
          control={commercialOfferForm.control}
          show={
            (values.status === 'Подача' && values.purpose === 'Закупка ЕП') ||
            (!!response?.is_blocked_work_params && values.purpose === 'Закупка ЕП')
          }
          path="proposed_price"
          name="Предлагаемая специалистом цена"
          edit={response?.is_blocked_work_params ? false : isFieldEditable('proposed_price')}
          ref={createFieldRef('proposed_price')}
          onFieldSelect={() => selectField('proposed_price')}
          numberFormatProps={{
            disabled: disabled || !!response?.is_blocked_work_params,
            min: 0,
            onValueChange: ({ floatValue }) => {
              if (
                (typeof floatValue === 'number' && floatValue <= 0) ||
                selectedFieldPath !== 'proposed_price'
              )
                return;
              if (values.purpose === 'Обоснование НМЦК') return;
              const costPrice = values['cost_price'];
              if (typeof costPrice === 'number' && typeof floatValue === 'number') {
                commercialOfferForm.setValue('margin', calculateMargin(floatValue, costPrice));
              }
            },
          }}
        />
        <FieldWithPreview.TextArea
          control={commercialOfferForm.control}
          path="price_justification"
          name="Обоснование предложения цены"
          show={
            (values.status === 'Подача' && values.purpose === 'Закупка ЕП') ||
            (!!response?.is_blocked_work_params && values.purpose === 'Закупка ЕП')
          }
          edit={response?.is_blocked_work_params ? false : isFieldEditable('price_justification')}
          ref={createFieldRef('price_justification')}
          onFieldSelect={() => selectField('price_justification')}
          textAreaProps={{
            disabled: disabled || !!response?.is_blocked_work_params,
          }}
          wrapperProps={{
            textAlign: 'start',
          }}
        />
        <FieldWithPreview.TextArea
          control={commercialOfferForm.control}
          show={values.status === 'Подача'}
          path="approval_comment"
          name="Комментарий согласования"
          edit={false}
          ref={createFieldRef('approval_comment')}
          onFieldSelect={() => selectField('approval_comment')}
          textAreaProps={{
            disabled: true,
          }}
          wrapperProps={{
            textAlign: 'start',
          }}
        />

        {values.purpose === 'Закупка ЕП' &&
          (values.status === 'Выигран' || values.status === 'Проигран') && (
            <>
              <Divider />
              <Text
                variant="body-2"
                className="font-semibold uppercase"
              >
                Итоговые параметры
              </Text>
            </>
          )}

        <FieldWithPreview.Currency
          control={commercialOfferForm.control}
          show={
            values.purpose === 'Закупка ЕП' &&
            (values.status === 'Выигран' || values.status === 'Проигран')
          }
          path="final_price"
          name="Итоговая цена"
          edit={isFieldEditable('final_price')}
          ref={createFieldRef('final_price')}
          onFieldSelect={() => selectField('final_price')}
          numberFormatProps={{
            disabled,
            min: 0,
          }}
          required
        />
        <FieldWithPreview.Select
          control={commercialOfferForm.control}
          show={
            values.purpose === 'Закупка ЕП' &&
            (values.status === 'Выигран' || values.status === 'Проигран')
          }
          path="winner_with_type"
          name="Победитель"
          edit={isFieldEditable('winner_with_type')}
          ref={createFieldRef('winner_with_type')}
          options={[
            { value: 'add-legal-entities', content: 'Добавить в «Наши организации»' },
            { value: 'add-competitors', content: 'Добавить в «Конкуренты»' },
            ...(flattedWinners?.map(winner => ({
              content: winner.name,
              value: winner.id + '/' + winner.type,
            })) || []),
          ]}
          defaultOptions={
            response && response.winner_id && response.winner_type
              ? [
                  {
                    content: response.winner_value,
                    value: response.winner_id + '/' + response.winner_type,
                  },
                ]
              : undefined
          }
          filter={filterWinners}
          onFilterChange={filter => setFilterWinners(filter)}
          onFieldSelect={() => selectField('winner_with_type')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            onUpdate: ([winnerWithType]) => {
              if (selectedFieldPath !== 'winner_with_type') return;
              if (winnerWithType === 'add-legal-entities') {
                commercialOfferForm.resetField('winner_id');
                commercialOfferForm.resetField('winner_type');
                return onClickCreateLegalEntitiesHandbook();
              }

              if (winnerWithType === 'add-competitors') {
                commercialOfferForm.resetField('winner_id');
                commercialOfferForm.resetField('winner_type');
                return onClickCreateCompetitorsHandbook();
              }

              if (winnerWithType && response) {
                const winnerId = winnerWithType.split('/')[0]!;
                const winnerType = winnerWithType.split('/')[1]! as NonNullable<
                  typeof response.winner_type
                >;
                commercialOfferForm.setValue('winner_id', winnerId);
                commercialOfferForm.setValue('winner_type', winnerType);
              } else {
                commercialOfferForm.resetField('winner_type');
                commercialOfferForm.resetField('winner_id');
              }
            },
            loading:
              getHandbookWinnersInfiniteQuery.isFetching ||
              getHandbookWinnersInfiniteQuery.isFetchingNextPage ||
              getHandbookWinnersInfiniteQuery.isLoading ||
              getHandbookWinnersInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookWinnersInfiniteQuery.fetchNextPage();
            },
          }}
        />
      </FieldWithPreview>
      {!hideBottomPanel && (
        <RightSidebar.BottomPanel>
          <Button
            size="xl"
            view="normal"
            width="max"
            onClick={onClickCancelButton}
            disabled={disabled || isFilesLoading}
          >
            {cancelButtonText}
          </Button>
          <Button
            size="xl"
            view="action"
            type="submit"
            width="max"
            onClick={() => {
              commercialOfferForm.handleSubmit(onValid, onInvalid);
              setCreatedTenderForView(undefined);
            }}
            disabled={disabled || isFilesLoading}
          >
            {submitButtonTextForAgreement || (values.status === 'Отказ' && response?.is_editable)
              ? 'Согласовать'
              : submitButtonText || 'Применить'}
          </Button>
        </RightSidebar.BottomPanel>
      )}
    </form>
  );
};
