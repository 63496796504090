import './CommercialOfferLayout.sass';

import { Button, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSetAtom } from 'jotai';
// import { useAtomValue } from 'jotai';
import { ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';

import { $regionsHooks } from '@entities';
import {
  CommercialOfferForm,
  // commercialOfferFormAtom,
  CommercialOfferFormProps,
  CommercialOfferSchema,
  CompetitorsHandbookForm,
  competitorsHandbookSchema,
  createdTenderForViewAtom,
  CustomerHandbookForm,
  LegalEntitiesHandbookForm,
  PurchaseObjectsHandbookForm,
  RegionsForm,
  regionsSchema,
  RegionsSchema,
} from '@features';
import { CreateTender } from '@widgets/tender-drawer/ui/CreateTender';

import { CreateHandbookLayout } from './CreateHandbookLayout';

interface CommercialOfferLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: Omit<
    CommercialOfferFormProps,
    | 'onClickCreateCustomerHandbook'
    | 'onClickCreateRegionsHandbook'
    | 'onClickCreatePurchaseObjectsHandbook'
    | 'onClickCreateTenderHandbook'
    | 'onClickCreateCompetitorsHandbook'
    | 'onClickCreateLegalEntitiesHandbook'
    | 'onClickCreateTender'
  >;
}

export const CommercialOfferLayout = ({ renderForm, formProps }: CommercialOfferLayoutProps) => {
  const { add } = useToaster();

  const setCreatedTenderForView = useSetAtom(createdTenderForViewAtom);

  const createCompetitorsHandbookForm = useForm({
    resolver: yupResolver(competitorsHandbookSchema),
    mode: 'all',
  });

  const [createCustomerHandbookOpen, setCreateCustomerHandbookOpen] = useState(false);
  const [createRegionsHandbookOpen, setCreateRegionsHandbookOpen] = useState(false);
  const [createPurchaseObjectsHandbookOpen, setCreatePurchaseObjectsHandbookOpen] = useState(false);
  const [createCompetitorsHandbookOpen, setCreateCompetitorsHandbookOpen] = useState(false);
  const [createLegalEntitiesHandbookOpen, setCreateLegalEntitiesHandbookOpen] = useState(false);
  const [createTenderOpen, setCreateTenderOpen] = useState(false);

  const createRegionMutation = $regionsHooks.createMutation();

  const createRegionsHandbookForm = useForm<RegionsSchema>({
    resolver: yupResolver(regionsSchema),
    mode: 'all',
  });

  const [offerValues, setOfferValues] = useState<CommercialOfferSchema | undefined>();

  return (
    <>
      {renderForm(
        <CommercialOfferForm
          {...formProps}
          onClickCreateCustomerHandbook={() => setCreateCustomerHandbookOpen(true)}
          onClickCreateRegionsHandbook={() => setCreateRegionsHandbookOpen(true)}
          onClickCreatePurchaseObjectsHandbook={() => setCreatePurchaseObjectsHandbookOpen(true)}
          onClickCreateCompetitorsHandbook={() => setCreateCompetitorsHandbookOpen(true)}
          onClickCreateLegalEntitiesHandbook={() => setCreateLegalEntitiesHandbookOpen(true)}
          onClickCreateTender={() => setCreateTenderOpen(true)}
          onValuesChange={values => {
            formProps.onValuesChange?.(values);
            setOfferValues(values);
          }}
        />
      )}
      <CreateHandbookLayout
        title="Создать карточку в справочнике «Заказчики»"
        open={createCustomerHandbookOpen}
        onClose={() => setCreateCustomerHandbookOpen(false)}
      >
        <CustomerHandbookForm
          onValid={values => {
            console.log('@customer-handbook@valid', values);
            setCreateCustomerHandbookOpen(false);
          }}
          onInvalid={errors => console.log('@customer-handbook@invalid', errors)}
          footer={
            <div className="flex items-center justify-end h-20 gap-4 px-8">
              <Button
                size="l"
                view="normal"
                onClick={() => setCreateCustomerHandbookOpen(false)}
              >
                Отмена
              </Button>
              <Button
                size="l"
                view="action"
                type="submit"
              >
                Создать
              </Button>
            </div>
          }
        />
      </CreateHandbookLayout>
      <CreateHandbookLayout
        title="Создать карточку в справочнике «Регионы»"
        open={createRegionsHandbookOpen}
        onClose={() => setCreateRegionsHandbookOpen(false)}
      >
        <RegionsForm
          form={createRegionsHandbookForm}
          disabled={createRegionMutation.isPending}
          initialEditable={true}
          onSubmit={async () => {
            if (!createRegionsHandbookForm.formState.isValid)
              return add({
                name: 'create-regions-validation-error',
                content: `Не заполнены обязательные поля`,
                theme: 'warning',
              });
            const values = createRegionsHandbookForm.getValues();
            await createRegionMutation.mutateAsync({
              // TODO:
              body: { code: values.code, name: values.name },
            });
            if (createRegionMutation.isSuccess) {
              add({
                name: 'create-regions-success',
                content: 'Успешно создано!',
                theme: 'success',
              });
              setTimeout(() => {
                setCreateRegionsHandbookOpen(false);
              }, 3000);
            }
          }}
          footer={
            <div className="flex items-center justify-end h-20 gap-4 px-8">
              <Button
                size="l"
                view="normal"
                onClick={() => setCreateRegionsHandbookOpen(false)}
              >
                Отмена
              </Button>
              <Button
                size="l"
                view="action"
                type="submit"
              >
                Создать
              </Button>
            </div>
          }
        />
      </CreateHandbookLayout>
      <CreateHandbookLayout
        title="Создать карточку в справочнике «Объекты закупки»"
        open={createPurchaseObjectsHandbookOpen}
        onClose={() => setCreatePurchaseObjectsHandbookOpen(false)}
      >
        <PurchaseObjectsHandbookForm
          onValid={values => {
            console.log('@purchase-objects-handbook@valid', values);
            setCreatePurchaseObjectsHandbookOpen(false);
          }}
          onInvalid={errors => console.log('@purchase-objects-handbook@invalid', errors)}
          footer={
            <div className="flex items-center justify-end h-20 gap-4 px-8">
              <Button
                size="l"
                view="normal"
                onClick={() => setCreateCustomerHandbookOpen(false)}
              >
                Отмена
              </Button>
              <Button
                size="l"
                view="action"
                type="submit"
              >
                Создать
              </Button>
            </div>
          }
        />
      </CreateHandbookLayout>
      <CreateHandbookLayout
        title="Создать карточку в справочнике «Конкуренты»"
        open={createCompetitorsHandbookOpen}
        onClose={() => setCreateCompetitorsHandbookOpen(false)}
      >
        <CompetitorsHandbookForm
          form={createCompetitorsHandbookForm}
          onSubmit={createCompetitorsHandbookForm.handleSubmit(
            values => {
              console.log('@competitors-handbook@valid', values);
              setCreateCompetitorsHandbookOpen(false);
            },
            errors => console.log('@competitors-handbook@invalid', errors)
          )}
          footer={
            <div className="flex items-center justify-end h-20 gap-4 px-8">
              <Button
                size="l"
                view="normal"
                onClick={() => setCreateCustomerHandbookOpen(false)}
              >
                Отмена
              </Button>
              <Button
                size="l"
                view="action"
                type="submit"
              >
                Создать
              </Button>
            </div>
          }
        />
      </CreateHandbookLayout>
      <CreateHandbookLayout
        title="Создать карточку в справочнике «Юридические лица»"
        open={createLegalEntitiesHandbookOpen}
        onClose={() => setCreateLegalEntitiesHandbookOpen(false)}
      >
        <LegalEntitiesHandbookForm
          onValid={values => {
            console.log('@legal-entities-handbook@valid', values);
            setCreateLegalEntitiesHandbookOpen(false);
          }}
          onInvalid={errors => console.log('@legal-entities-handbook@invalid', errors)}
          footer={
            <div className="flex items-center justify-end h-20 gap-4 px-8">
              <Button
                size="l"
                view="normal"
                onClick={() => setCreateCustomerHandbookOpen(false)}
              >
                Отмена
              </Button>
              <Button
                size="l"
                view="action"
                type="submit"
              >
                Создать
              </Button>
            </div>
          }
        />
      </CreateHandbookLayout>
      <CreateTender
        open={createTenderOpen}
        onClose={() => setCreateTenderOpen(false)}
        afterCreate={createdTender => {
          setCreatedTenderForView({
            tender_value: createdTender.title,
            tender_id: createdTender.id,
          });
        }}
        defaultValues={
          offerValues
            ? {
                responsible_id: offerValues['responsible_id'],
                customer_ids: [offerValues['customer_id']],
                delivery_region_id: offerValues.delivery_region_id,
                object_ids: offerValues.purchase_object_id ? [offerValues.purchase_object_id] : [],
              }
            : undefined
        }
      />
    </>
  );
};
