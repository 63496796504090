import { Button, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { $agenciesHooks, $contactsHooks, $employeesHooks, $paymentDetailsHooks } from '@entities';
import { bodyResponseType } from '@shared/api';
import { useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview, RightSidebar } from '@shared/ui';

import { CUSTOMERS_OPTIONS } from '../consts';
import { customersSchema, CustomersSchema } from '../model';

interface CustomersFormProps {
  onValid: SubmitHandler<CustomersSchema>;
  onValuesChange?: (values: CustomersSchema) => void;
  response?: bodyResponseType<'get', '/handbook/customers/{id}'>;
  onInvalid?: SubmitErrorHandler<CustomersSchema>;
  onClickCancelButton?: () => void;
  initialEditable?: boolean;
  disabled?: boolean;
  hideBottomPanel: boolean;
  rightSidebarButtons?: boolean;
  defaultValues?: CustomersSchema;
  hideTitleField?: boolean;
  cancelButtonText?: string;
  submitButtonText?: string;
}

export function CustomersForm({
  onValid,
  onValuesChange,
  response,
  onInvalid,
  initialEditable = false,
  onClickCancelButton,
  disabled = false,
  hideBottomPanel = false,
  defaultValues,
  hideTitleField = false,
  cancelButtonText = 'Отмена',
  submitButtonText = 'Применить',
}: CustomersFormProps) {
  const { add } = useToaster();

  const form = useForm<CustomersSchema>({
    resolver: yupResolver(customersSchema),
    mode: 'all',
    defaultValues,
    resetOptions: {
      keepDirtyValues: true,
      keepDefaultValues: false,
    },
  });

  const { createFieldRef, isFieldEditable, selectField, selectedFieldPath, resetSelectedField } =
    useFieldsWithPreview({
      form: form,
      initialEditable,
      disabled,
    });

  const values = form.watch();

  // Queries
  const [filterEmployees, setFilterEmployees] = useState('');
  const getHandbookEmployeesInfiniteQuery = $employeesHooks.useGetAll(
    {
      query: filterEmployees,
      limit: 30,
    },
    selectedFieldPath === 'responsible_id' || selectedFieldPath === 'head_responsible_id'
  );
  const flattedEmployees = useMemo(
    () => getHandbookEmployeesInfiniteQuery.data?.pages.flat(),
    [getHandbookEmployeesInfiniteQuery.data]
  );

  const [filterAgencies, setFilterAgencies] = useState('');
  const getHandbookAgenciesInfiniteQuery = $agenciesHooks.useGetAll(
    {
      query: filterAgencies,
      limit: 30,
    },
    selectedFieldPath === 'higher_agency_id'
  );
  const flattedAgencies = useMemo(
    () => getHandbookAgenciesInfiniteQuery.data?.pages.flat(),
    [getHandbookAgenciesInfiniteQuery.data]
  );

  const [filterContacts, setFilterContacts] = useState('');
  const getHandbookContactsInfiniteQuery = $contactsHooks.useGetAll(
    {
      query: filterContacts,
      limit: 30,
    },
    selectedFieldPath === 'contact_ids'
  );
  const flattedContacts = useMemo(
    () => getHandbookContactsInfiniteQuery.data?.pages.flat(),
    [getHandbookContactsInfiniteQuery.data]
  );

  const [filterPaymentDetails, setFilterPaymentDetails] = useState('');
  const getHandbookPaymentDetailsInfiniteQuery = $paymentDetailsHooks.useGetAll(
    {
      query: filterPaymentDetails,
      limit: 30,
    },
    selectedFieldPath === 'payment_detail_id'
  );
  const flattedPaymentDetails = useMemo(
    () => getHandbookPaymentDetailsInfiniteQuery.data?.pages.flat(),
    [getHandbookPaymentDetailsInfiniteQuery.data]
  );
  //

  useDeepCompareEffect(() => {
    onValuesChange?.(values);
  }, [values]);

  useDeepCompareEffect(() => {
    if (response) form.reset(response);
  }, [response || {}]);

  useEffect(() => {
    if (Object.values(form.formState.errors).length > 0 && form.formState.isSubmitted)
      add({
        title: 'Не все обязательные поля заполнены',
        name: 'validation-fields-error',
        theme: 'warning',
      });
  }, [form.formState.isSubmitted]);

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={form.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        <FieldWithPreview.Text
          ref={createFieldRef('name')}
          show={!hideTitleField}
          control={form.control}
          path="name"
          name="Краткое наименование"
          edit={isFieldEditable('name')}
          onFieldSelect={() => selectField('name')}
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('full_name')}
          control={form.control}
          path="full_name"
          name="Полное наименование"
          edit={isFieldEditable('full_name')}
          onFieldSelect={() => selectField('full_name')}
          required
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('inn')}
          control={form.control}
          path="inn"
          name="ИНН"
          edit={isFieldEditable('inn')}
          onFieldSelect={() => selectField('inn')}
          required
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('kpp')}
          control={form.control}
          path="kpp"
          name="КПП"
          edit={isFieldEditable('kpp')}
          onFieldSelect={() => selectField('kpp')}
          required
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.Radio
          ref={createFieldRef('customer_type')}
          control={form.control}
          edit={isFieldEditable('customer_type')}
          name="Тип заказчика"
          options={CUSTOMERS_OPTIONS['customer_type']}
          defaultOption={
            response?.customer_type
              ? { value: response.customer_type, content: response.customer_type }
              : undefined
          }
          path="customer_type"
          resetSelectedField={resetSelectedField}
          onFieldSelect={() => selectField('customer_type')}
          radioButtonProps={{ disabled }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('unique_number')}
          control={form.control}
          path="unique_number"
          name="Уникальный номер"
          edit={isFieldEditable('unique_number')}
          onFieldSelect={() => selectField('unique_number')}
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.Radio
          ref={createFieldRef('priority')}
          control={form.control}
          path="priority"
          edit={isFieldEditable('priority')}
          onFieldSelect={() => selectField('priority')}
          name="Приоритет"
          options={CUSTOMERS_OPTIONS['priority']}
          defaultOption={
            response?.priority
              ? { value: response.priority, content: response.priority }
              : undefined
          }
          resetSelectedField={resetSelectedField}
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('comment')}
          control={form.control}
          path="comment"
          name="Комментарий"
          edit={isFieldEditable('comment')}
          onFieldSelect={() => selectField('comment')}
          wrapperProps={{ textAlign: 'start' }}
          textAreaProps={{ disabled }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('customer_level')}
          control={form.control}
          edit={isFieldEditable('customer_level')}
          name="Уровень"
          options={CUSTOMERS_OPTIONS['customer_level']}
          defaultOptions={
            response?.customer_level
              ? [{ value: response.customer_level, content: response.customer_level }]
              : undefined
          }
          path="customer_level"
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          onFieldSelect={() => selectField('customer_level')}
          selectProps={{ disabled }}
        />
        <FieldWithPreview.Select
          control={form.control}
          path="higher_agency_id"
          name="Вышестоящее ведомство"
          edit={isFieldEditable('higher_agency_id')}
          ref={createFieldRef('higher_agency_id')}
          onFieldSelect={() => selectField('higher_agency_id')}
          options={
            flattedAgencies?.map(agency => ({
              content: agency.name,
              value: agency.id,
            })) || []
          }
          defaultOptions={
            response?.higher_agency_id
              ? [
                  {
                    content: response.higher_agency_value,
                    value: response.higher_agency_id,
                  },
                ]
              : []
          }
          filter={filterAgencies}
          onFilterChange={filter => setFilterAgencies(filter)}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            loading:
              getHandbookAgenciesInfiniteQuery.isFetching ||
              getHandbookAgenciesInfiniteQuery.isFetchingNextPage ||
              getHandbookAgenciesInfiniteQuery.isLoading ||
              getHandbookAgenciesInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookAgenciesInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('responsible_id')}
          control={form.control}
          path="responsible_id"
          edit={isFieldEditable('responsible_id')}
          onFieldSelect={() => selectField('responsible_id')}
          name="Ответственный"
          options={
            flattedEmployees?.map(employee => ({
              content: employee?.name_initials,
              value: employee.id,
            })) || []
          }
          defaultOptions={
            response?.responsible_id
              ? [
                  {
                    content: response.responsible_value,
                    value: response.responsible_id,
                  },
                ]
              : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          filter={filterEmployees}
          onFilterChange={filter => setFilterEmployees(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookEmployeesInfiniteQuery.isFetching ||
              getHandbookEmployeesInfiniteQuery.isFetchingNextPage ||
              getHandbookEmployeesInfiniteQuery.isLoading ||
              getHandbookEmployeesInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookEmployeesInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('head_responsible_id')}
          control={form.control}
          path="head_responsible_id"
          edit={isFieldEditable('head_responsible_id')}
          onFieldSelect={() => selectField('head_responsible_id')}
          name="Руководитель ответственного"
          options={
            flattedEmployees?.map(employee => ({
              content: employee?.name_initials,
              value: employee.id,
            })) || []
          }
          defaultOptions={
            response?.head_responsible_id
              ? [
                  {
                    content: response.head_responsible_value,
                    value: response.head_responsible_id,
                  },
                ]
              : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          filter={filterEmployees}
          onFilterChange={filter => setFilterEmployees(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookEmployeesInfiniteQuery.isFetching ||
              getHandbookEmployeesInfiniteQuery.isFetchingNextPage ||
              getHandbookEmployeesInfiniteQuery.isLoading ||
              getHandbookEmployeesInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookEmployeesInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.MultiSelect
          ref={createFieldRef('contact_ids')}
          control={form.control}
          path="contact_ids"
          edit={isFieldEditable('contact_ids')}
          onFieldSelect={() => selectField('contact_ids')}
          name="Контакты"
          options={
            flattedContacts?.map(contact => ({
              content: contact?.full_name,
              value: contact.id,
            })) || []
          }
          defaultOptions={response?.contact_ids?.map((contact, i) => ({
            content: response?.contact_values?.[i],
          }))}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          filter={filterContacts}
          onFilterChange={filter => setFilterContacts(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookContactsInfiniteQuery.isFetching ||
              getHandbookContactsInfiniteQuery.isFetchingNextPage ||
              getHandbookContactsInfiniteQuery.isLoading ||
              getHandbookContactsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookContactsInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('address')}
          control={form.control}
          path="address"
          name="Адрес"
          edit={isFieldEditable('address')}
          onFieldSelect={() => selectField('address')}
          wrapperProps={{ textAlign: 'start' }}
          textAreaProps={{ disabled }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('payment_detail_id')}
          control={form.control}
          path="payment_detail_id"
          edit={isFieldEditable('payment_detail_id')}
          onFieldSelect={() => selectField('payment_detail_id')}
          name="Платежные реквизиты"
          options={
            flattedPaymentDetails?.map(paymentDetail => ({
              content: paymentDetail?.bank_name,
              value: paymentDetail.id,
            })) || []
          }
          defaultOptions={
            response?.payment_detail_id
              ? [
                  {
                    content: response.payment_detail_value,
                    value: response.payment_detail_id,
                  },
                ]
              : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          filter={filterPaymentDetails}
          onFilterChange={filter => setFilterPaymentDetails(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookPaymentDetailsInfiniteQuery.isFetching ||
              getHandbookPaymentDetailsInfiniteQuery.isFetchingNextPage ||
              getHandbookPaymentDetailsInfiniteQuery.isLoading ||
              getHandbookPaymentDetailsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookPaymentDetailsInfiniteQuery.fetchNextPage();
            },
          }}
        />
      </FieldWithPreview>
      {!hideBottomPanel && (
        <RightSidebar.BottomPanel>
          <Button
            size="xl"
            view="normal"
            width="max"
            onClick={onClickCancelButton}
            disabled={disabled}
          >
            {cancelButtonText}
          </Button>
          <Button
            size="xl"
            view="action"
            type="submit"
            width="max"
            onClick={form.handleSubmit(onValid, onInvalid)}
            disabled={disabled}
          >
            {submitButtonText}
          </Button>
        </RightSidebar.BottomPanel>
      )}
    </form>
  );
}
