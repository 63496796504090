import { ReactNode } from 'react';

import { RightSidebar } from '@shared/ui';

interface CustomerHandbookDrawerLayoutProps {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  title?: string;
}

export function CustomerHandbookDrawerLayout({
  open,
  title,
  onClose,
  children,
}: CustomerHandbookDrawerLayoutProps) {
  return (
    <RightSidebar
      open={open}
      onClose={onClose}
    >
      <RightSidebar.Header
        title={title}
        onClose={onClose}
      />
      {children}
    </RightSidebar>
  );
}
