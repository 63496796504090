import { dateTime } from '@gravity-ui/date-utils';

import {
  CRMTableFilters,
  DateFilterValueType,
  FilterBooleanType,
  FilterValueType,
  NumericFilterValueType,
  SearchFilterValueType,
  SelectFilterValueType,
} from '@features';
import { bodyRequestType, bodyResponseType } from '@shared/api';
import { DATE_FORMATS } from '@shared/consts';
import { WinnerType, WinnerValue } from '@shared/ui';

import { ColumnsTendersTable, frontKeyBackendValueFilters } from '../model';

type BooleanValue = FilterBooleanType['value'];

const checkNumeric = (min?: number, max?: number): NumericFilterValueType => {
  if (typeof min !== 'number' && typeof max !== 'number') {
    return;
  }
  //@ts-expect-error Неверная компиляция для условия
  return { from: min, to: max };
};

const checkDate = (min?: string, max?: string, isFull?: boolean): DateFilterValueType => {
  if (typeof min !== 'string' && typeof max !== 'string') {
    return;
  }
  const format = isFull ? DATE_FORMATS.fullDateWithTimeRounded : DATE_FORMATS.fullDate;
  if (min !== undefined) min = dateTime({ input: min }).format(format);
  if (max !== undefined) max = dateTime({ input: max }).format(format);

  //@ts-expect-error Неверная компиляция для условия
  return { from: min, to: max };
};

const checkBoolean = (bool?: string): BooleanValue => {
  if (bool) return bool as BooleanValue;
  return;
};

const displayBooleanFilterToBackendFilter = (filter: FilterValueType) => {
  if (filter.type !== 'boolean' || !filter.value) {
    return '';
  }
  return filter.value;
};

const displayDateToISOSString = (date?: string, withTime?: boolean) => {
  if (!date) return;
  if (!withTime) {
    const [day, month, year] = date.split('.');
    if (!day || !month || !year) {
      return;
    }
    return new Date(Number(year), Number(month) - 1, Number(day)).toISOString();
  }

  // const [datePart, timePart] = date.split(', ');
  // if (!datePart) {
  //   return;
  // }
  // const [day, month, year] = datePart.split('.');
  // const formattedDateStr = `${year}-${month}-${day}T${timePart}:00.000Z`;

  return new Date(date).toISOString();
};

// TODO: не хватает полей на бэке
export const parseFiltersFromBackend = (
  backendFilters: bodyResponseType<'get', '/tender/tabs/{name}'>['filters']
): CRMTableFilters<ColumnsTendersTable> | undefined => {
  if (!backendFilters) return;
  return {
    'Дата, номер': {
      type: 'date',
      value: checkDate(
        backendFilters[frontKeyBackendValueFilters['Дата, номер']['from']],
        backendFilters[frontKeyBackendValueFilters['Дата, номер']['to']]
      ),
    },
    Ответственный: {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['Ответственный']],
    },
    Наименование: {
      type: 'search',
      value: backendFilters[frontKeyBackendValueFilters['Наименование']],
    },
    'Статус заявки': {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['Статус заявки']],
    },
    Отклонение: {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['Отклонение']],
    },
    'Основание и причина отклонения': {
      type: 'search',
      value: backendFilters[frontKeyBackendValueFilters['Основание и причина отклонения']],
    },
    'Причина отказа': {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['Причина отказа']],
    },
    'Пояснение к причине отказа': {
      type: 'search',
      value: backendFilters[frontKeyBackendValueFilters['Пояснение к причине отказа']],
    },
    'Статус закупки': {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['Статус закупки']],
    },
    'Причина почему не состоялась?': {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['Причина почему не состоялась?']],
    },
    'Номер извещения': {
      type: 'search',
      value: backendFilters[frontKeyBackendValueFilters['Номер извещения']],
    },
    'Ссылка на процедуру': {
      type: 'boolean',
      value: checkBoolean(backendFilters[frontKeyBackendValueFilters['Ссылка на процедуру']]),
    },
    'Электронная торговая площадка': {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['Электронная торговая площадка']],
    },
    'В рамках ГОЗ по 275-ФЗ': {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['В рамках ГОЗ по 275-ФЗ']],
    },
    ФЗ: {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['ФЗ']],
    },
    'Закупка централизованная или совместная?': {
      type: 'boolean',
      value: checkBoolean(
        backendFilters[frontKeyBackendValueFilters['Закупка централизованная или совместная?']]
      ),
    },
    Заказчики: {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['Заказчики']],
    },
    'Приоритет заказчика': {
      type: 'priority',
      value: checkNumeric(
        backendFilters[frontKeyBackendValueFilters['Приоритет заказчика']['from']],
        backendFilters[frontKeyBackendValueFilters['Приоритет заказчика']['to']]
      ),
    },
    // TODO: Не существует поля на бэке
    // Организатор: {
    //   type: 'select',
    //   value: backendFilters[frontKeyBackendValueFilters['Организатор']],
    // },
    'Объект торгов': {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['Объект торгов']],
    },
    'Начальная цена': {
      type: 'currency',
      value: checkNumeric(
        backendFilters[frontKeyBackendValueFilters['Начальная цена']['from']],
        backendFilters[frontKeyBackendValueFilters['Начальная цена']['to']]
      ),
    },
    'Участвуем от': {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['Участвуем от']],
    },
    Комментарий: {
      type: 'search',
      value: backendFilters[frontKeyBackendValueFilters['Комментарий']],
    },
    'Способ проведения': {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['Способ проведения']],
    },
    'Ограничения, преимущества и доп. требования': {
      type: 'select',
      value:
        backendFilters[frontKeyBackendValueFilters['Ограничения, преимущества и доп. требования']],
    },
    'Обеспечение заявки': {
      type: 'currency',
      value: checkNumeric(
        backendFilters[frontKeyBackendValueFilters['Обеспечение заявки']['from']],
        backendFilters[frontKeyBackendValueFilters['Обеспечение заявки']['to']]
      ),
    },
    'Окончание подачи': {
      type: 'dateWithTime',
      value: checkDate(
        backendFilters[frontKeyBackendValueFilters['Окончание подачи']['from']],
        backendFilters[frontKeyBackendValueFilters['Окончание подачи']['to']],
        true
      ),
    },
    'Проведение процедуры': {
      type: 'dateWithTime',
      value: checkDate(
        backendFilters[frontKeyBackendValueFilters['Проведение процедуры']['from']],
        backendFilters[frontKeyBackendValueFilters['Проведение процедуры']['to']],
        true
      ),
    },
    'Подведение итогов': {
      type: 'date',
      value: checkDate(
        backendFilters[frontKeyBackendValueFilters['Подведение итогов']['from']],
        backendFilters[frontKeyBackendValueFilters['Подведение итогов']['to']]
      ),
    },
    Документация: {
      type: 'boolean',
      value: checkBoolean(backendFilters[frontKeyBackendValueFilters['Документация']]),
    },
    Разъяснения: {
      type: 'boolean',
      value: checkBoolean(backendFilters[frontKeyBackendValueFilters['Разъяснения']]),
    },
    Протоколы: {
      type: 'boolean',
      value: checkBoolean(backendFilters[frontKeyBackendValueFilters['Протоколы']]),
    },
    'Регион поставки': {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['Регион поставки']],
    },
    'Адрес поставки': {
      type: 'search',
      value: backendFilters[frontKeyBackendValueFilters['Адрес поставки']],
    },
    'Срок поставки': {
      type: 'date',
      value: checkDate(
        backendFilters[frontKeyBackendValueFilters['Срок поставки']['from']],
        backendFilters[frontKeyBackendValueFilters['Срок поставки']['to']]
      ),
    },
    Себестоимость: {
      type: 'currency',
      value: checkNumeric(
        backendFilters[frontKeyBackendValueFilters['Себестоимость']['from']],
        backendFilters[frontKeyBackendValueFilters['Себестоимость']['to']]
      ),
    },
    'Маржа начальная': {
      type: 'margin',
      value: checkNumeric(
        backendFilters[frontKeyBackendValueFilters['Маржа начальная']['from']],
        backendFilters[frontKeyBackendValueFilters['Маржа начальная']['to']]
      ),
    },
    'Файл расчета процедуры': {
      type: 'boolean',
      value: checkBoolean(backendFilters[frontKeyBackendValueFilters['Файл расчета процедуры']]),
    },
    'Файл заявки': {
      type: 'boolean',
      value: checkBoolean(backendFilters[frontKeyBackendValueFilters['Файл заявки']]),
    },
    'Согласовать минимальную цену с руководителем': {
      type: 'boolean',
      value: checkBoolean(
        backendFilters[frontKeyBackendValueFilters['Согласовать минимальную цену с руководителем']]
      ),
    },
    'Предлагаемая специалистом минимальная цена': {
      type: 'currency',
      value: checkNumeric(
        backendFilters[
          frontKeyBackendValueFilters['Предлагаемая специалистом минимальная цена']['from']
        ],
        backendFilters[
          frontKeyBackendValueFilters['Предлагаемая специалистом минимальная цена']['to']
        ]
      ),
    },
    'Обоснование предложения минимальной цены': {
      type: 'search',
      value:
        backendFilters[frontKeyBackendValueFilters['Обоснование предложения минимальной цены']],
    },
    'Комментарий согласования': {
      type: 'search',
      value: backendFilters[frontKeyBackendValueFilters['Комментарий согласования']],
    },
    'Минимальная цена': {
      type: 'currency',
      value: checkNumeric(
        backendFilters[frontKeyBackendValueFilters['Минимальная цена']['from']],
        backendFilters[frontKeyBackendValueFilters['Минимальная цена']['to']]
      ),
    },
    'Маржа минимальная': {
      type: 'margin',
      value: checkNumeric(
        backendFilters[frontKeyBackendValueFilters['Маржа минимальная']['from']],
        backendFilters[frontKeyBackendValueFilters['Маржа минимальная']['to']]
      ),
    },
    'Итоговая цена': {
      type: 'currency',
      value: checkNumeric(
        backendFilters[frontKeyBackendValueFilters['Итоговая цена']['from']],
        backendFilters[frontKeyBackendValueFilters['Итоговая цена']['to']]
      ),
    },
    'Процент снижения': {
      type: 'margin',
      value: checkNumeric(
        backendFilters[frontKeyBackendValueFilters['Процент снижения']['from']],
        backendFilters[frontKeyBackendValueFilters['Процент снижения']['to']]
      ),
    },
    'Маржа итоговая': {
      type: 'margin',
      value: checkNumeric(
        backendFilters[frontKeyBackendValueFilters['Маржа итоговая']['from']],
        backendFilters[frontKeyBackendValueFilters['Маржа итоговая']['to']]
      ),
    },
    // TODO:
    //@ts-expect-error Ошибка в енаме для фильтров победителя
    Победитель: {
      type: 'selectWinners',
      value:
        backendFilters[frontKeyBackendValueFilters['Победитель']]?.map(
          winner => `${winner.id}/${winner.type}`
        ) || [],
    },
    Участники: {
      type: 'select',
      // TODO: потом будет другого типа
      value: backendFilters[frontKeyBackendValueFilters['Участники']],
    },
    'Руководитель ответственного': {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['Руководитель ответственного']],
    },
  };
};

export const parseFiltersToBackend = (
  filters: CRMTableFilters<ColumnsTendersTable>
): bodyRequestType<'put', '/tender/tabs/{name}'>['filters'] => {
  console.log(filters);
  return {
    /** @description Ограничения, преимущества и доп. Требования */
    additional_information: filters['Ограничения, преимущества и доп. требования']
      .value as SelectFilterValueType,
    /** @description Комментарий согласования */
    approve_comment: filters['Комментарий согласования'].value as SearchFilterValueType,
    base_cost_price_max: (filters['Себестоимость'].value as NumericFilterValueType)?.to,
    /** @description Себестоимость */
    base_cost_price_min: (filters['Себестоимость'].value as NumericFilterValueType)?.from,
    /** @description Файлы расчета процедуры */
    calculation_files: displayBooleanFilterToBackendFilter(filters['Файл расчета процедуры']),
    /** @description Комментарий */
    comment: filters['Комментарий'].value as SearchFilterValueType,
    cost_price_max: (filters['Начальная цена'].value as NumericFilterValueType)?.to,
    /** @description Начальная цена */
    cost_price_min: (filters['Начальная цена'].value as NumericFilterValueType)?.from,
    /** @description Заказчик */
    customer_ids: filters['Заказчики'].value as SelectFilterValueType,
    customer_priority_max: (filters['Приоритет заказчика'].value as NumericFilterValueType)?.to,
    /** @description Приоритет заказчика */
    customer_priority_min: (filters['Приоритет заказчика'].value as NumericFilterValueType)?.from,
    decrease_percent_max: (filters['Процент снижения'].value as NumericFilterValueType)?.to,
    /** @description Процент снижения */
    decrease_percent_min: (filters['Процент снижения'].value as NumericFilterValueType)?.from,
    /** @description Адрес поставки */
    delivery_address: filters['Адрес поставки'].value as SearchFilterValueType,
    /** @description Регион поставки */
    delivery_region_ids: filters['Регион поставки'].value as SelectFilterValueType,
    /**
     * @description Ссылка на файл документации @gotags: enums:"true,false"
     * @enum {string}
     */
    documentation_files: displayBooleanFilterToBackendFilter(filters['Документация']) || undefined,
    final_cost_price_max: (filters['Итоговая цена'].value as NumericFilterValueType)?.to,
    /** @description Итоговая цена */
    final_cost_price_min: (filters['Итоговая цена'].value as NumericFilterValueType)?.from,
    final_margin_max: (filters['Маржа итоговая'].value as NumericFilterValueType)?.to,
    /** @description Маржа итоговая */
    final_margin_min: (filters['Маржа итоговая'].value as NumericFilterValueType)?.from,
    /** @description ФЗ */
    fzs: filters['ФЗ'].value as SelectFilterValueType,
    /** @description Способ проведения */
    implementation_methods: filters['Способ проведения'].value as SelectFilterValueType,
    /**
     * @description Закупка централизованная или совместная @gotags: enums:"true,false,"
     * @enum {string}
     */
    is_centralized: displayBooleanFilterToBackendFilter(
      filters['Закупка централизованная или совместная?']
    ),
    /** @description Победитель */
    is_under_approval: displayBooleanFilterToBackendFilter(
      filters['Согласовать минимальную цену с руководителем']
    ),
    margin_max: (filters['Маржа итоговая'].value as NumericFilterValueType)?.to,
    /** @description Маржа начальная */
    margin_min: (filters['Маржа итоговая'].value as NumericFilterValueType)?.from,
    /**
     * Format: date-time
     * @description Дата создания MAX @gotags: swaggertype:"string" format:"date-time"
     */
    max_created_at: displayDateToISOSString(
      (filters['Дата, номер'].value as DateFilterValueType | null)?.to
    ),
    /**
     * Format: date-time
     * @description Срок поставки MAX @gotags: swaggertype:"string" format:"date-time"
     */
    max_delivery_at: displayDateToISOSString(
      (filters['Срок поставки'].value as DateFilterValueType | null)?.to
    ),
    /**
     * Format: date-time
     * @description Проведение процедуры MAX @gotags: swaggertype:"string" format:"date-time"
     */
    max_procedure_at: displayDateToISOSString(
      (filters['Проведение процедуры'].value as DateFilterValueType | null)?.to
    ),
    /**
     * Format: date-time
     * @description Подведение итогов MAX @gotags: swaggertype:"string" format:"date-time"
     */
    max_results_at: displayDateToISOSString(
      (filters['Подведение итогов'].value as DateFilterValueType | null)?.to
    ),
    /**
     * Format: date-time
     * @description Окончание подачи MAX @gotags: swaggertype:"string" format:"date-time"
     */
    max_submission_deadline: displayDateToISOSString(
      (filters['Окончание подачи'].value as DateFilterValueType | null)?.to
    ),
    min_cost_price_max: (filters['Минимальная цена'].value as NumericFilterValueType)?.to,
    /** @description Минимальная цена */
    min_cost_price_min: (filters['Минимальная цена'].value as NumericFilterValueType)?.from,
    /** @description Обоснование предложения минимальной цены */
    min_cost_price_reason: filters['Обоснование предложения минимальной цены']
      .value as SearchFilterValueType,
    /**
     * Format: date-time
     * @description Дата создания MIN @gotags: swaggertype:"string" format:"date-time"
     */
    min_created_at: displayDateToISOSString(
      (filters['Дата, номер'].value as DateFilterValueType | null)?.from
    ),
    /**
     * Format: date-time
     * @description Срок поставки MIN @gotags: swaggertype:"string" format:"date-time"
     */
    min_delivery_at: displayDateToISOSString(
      (filters['Срок поставки'].value as DateFilterValueType | null)?.from
    ),
    min_margin_max: (filters['Маржа минимальная'].value as NumericFilterValueType)?.to,
    /** @description Маржа минимальная */
    min_margin_min: (filters['Маржа минимальная'].value as NumericFilterValueType)?.from,
    /**
     * Format: date-time
     * @description Проведение процедуры MIN @gotags: swaggertype:"string" format:"date-time"
     */
    min_procedure_at: displayDateToISOSString(
      (filters['Проведение процедуры'].value as DateFilterValueType | null)?.from
    ),
    /**
     * Format: date-time
     * @description Подведение итогов MIN @gotags: swaggertype:"string" format:"date-time"
     */
    min_results_at: displayDateToISOSString(
      (filters['Подведение итогов'].value as DateFilterValueType | null)?.from
    ),
    /**
     * Format: date-time
     * @description Окончание подачи MIN @gotags: swaggertype:"string" format:"date-time"
     */
    min_submission_deadline: displayDateToISOSString(
      (filters['Окончание подачи'].value as DateFilterValueType | null)?.from
    ),
    /** @description Номер извещения */
    notice_number: filters['Номер извещения'].value as SearchFilterValueType,
    /** @description Объект торгов @gotags: swaggertype:"string" */
    object_ids: filters['Объект торгов'].value as SelectFilterValueType,
    /** @description Участники @gotags: swaggertype:"array,string"' */
    participant_ids: filters['Участники'].value as SelectFilterValueType,
    /** @description Участвуем от @gotags: swaggertype:"string" */
    participate_as_ids: filters['Участвуем от'].value as SelectFilterValueType,
    /** @description Ссылка на процедуру */
    procedure_link: displayBooleanFilterToBackendFilter(filters['Ссылка на процедуру']),
    /**
     * @description Ссылка на файл протокола @gotags: enums:"true,false"
     * @enum {string}
     */
    // TODO: неверный тип
    // protocols_file: displayBooleanFilterToBackendFilter(filters['Протоколы']),
    /** @description Статус закупки */
    purchase_statuses: filters['Статус закупки'].value as SelectFilterValueType,
    /** @description Причина отказа */
    reject_reasons: filters['Причина отказа'].value as SelectFilterValueType,
    /** @description Файл заявки */
    request_file: displayBooleanFilterToBackendFilter(filters['Файл заявки']),
    /** @description Ответственный */
    responsible_ids: filters['Ответственный'].value as SelectFilterValueType,
    /** @description Руководитель ответственного */
    responsible_supervisor_ids: filters['Руководитель ответственного']
      .value as SelectFilterValueType,
    specialist_min_cost_price_max: (
      filters['Предлагаемая специалистом минимальная цена'].value as NumericFilterValueType
    )?.to,
    /** @description Предлагаемая специалистом минимальная цена */
    specialist_min_cost_price_min: (
      filters['Предлагаемая специалистом минимальная цена'].value as NumericFilterValueType
    )?.from,
    /** @description Статус торгов */
    statuses: filters['Статус заявки'].value as SelectFilterValueType,
    tender_support_max: (filters['Обеспечение заявки'].value as NumericFilterValueType)?.from,
    /** @description Обеспечение заявки */
    tender_support_min: (filters['Обеспечение заявки'].value as NumericFilterValueType)?.from,
    /** @description Название торгов */
    title: filters['Наименование'].value as SearchFilterValueType,
    /** @description Торговая площадка */
    trading_platform_ids: filters['Электронная торговая площадка'].value as SelectFilterValueType,
    /** @description Победители */
    winners: (filters['Победитель'].value as WinnerValue[] | undefined)?.map(str => {
      const [id, type] = str.split('/') as [string, WinnerType];
      return { id, type };
    }),
  };
};
