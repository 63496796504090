import { ColumnsFilters } from '@features';

import { ColumnsContactsHandbookTable } from '../model';

export const CONTACTS_TABLE_COLUMNS_WITH_FILTER: Record<
  ColumnsContactsHandbookTable[number],
  ColumnsFilters
> = {
  ФИО: 'search',
  'Тип контактного лица': 'select',
  'Статус контактного лица': 'select',
  Должность: 'search',
  Телефон: 'search',
  'Эл. адрес': 'search',
  Комментарий: 'search',
  Организация: 'select',
};
