export const OPTIONS_MOCK = {
  contact_ids: [
    { value: '1', content: 'Контакт 1' },
    { value: '2', content: 'Контакт 2' },
    { value: '3', content: 'Контакт 3' },
  ],
  payment_detail_id: [
    { value: '1', content: 'Контент 1' },
    { value: '2', content: 'Контент 2' },
    { value: '3', content: 'Контент 3' },
  ],
  purchase_object_id: [
    { value: '1', content: 'Контент 1' },
    { value: '2', content: 'Контент 2' },
    { value: '3', content: 'Контент 3' },
  ],
  region_id: [
    { value: '1', content: 'Контент 1' },
    { value: '2', content: 'Контент 2' },
    { value: '3', content: 'Контент 3' },
  ],
};
